import React from 'react';
import {Input, Form, Layout, Row, Col,theme, Alert, Spin} from 'antd';
import { Content } from "antd/es/layout/layout";
import CustomScaleCard from '../components/common/CustomScaleCard';
import { useNavigate ,useParams } from "react-router-dom";
import HeaderMobile from '../components/common/HeaderMobile';
import { GET } from '../frameworks/HttpClient';
import { URL_PALLET } from '../constants/urls';

export default function NotifyDetailPalletScreen(prop) {
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate ();
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [data, setData] = React.useState(null);
    
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  /** Fetch transaction data */
  const fetchTransaction = async (id) => {
    if (!id) {
      setErrorMessages("ไม่พบข้อมูลพาเลท!");
      return;
    }

    setErrorMessages(null);
    setLoading(true);
    try {
      const response = await GET(`${URL_PALLET.PALLET_USAGE}${id}`);
      setData(response.data);
      form.setFieldsValue(response.data);
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    if (id) 
      fetchTransaction(id);

  }, [id])
  

return(
  <Layout>
    <HeaderMobile 
      title={data ? data.pallet_code : "-"}
      back={true}
      onClick={() => navigate(-1)}/>
      <Spin tip="โหลดข้อมูล" spinning={loading}>
        <Content 
          style={{ 
            margin: "0px 24px 24px 24px", 
            textAlign: "left",  
            minHeight: 280,
            background: colorBgContainer 
          }}>
            
          <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            style={{marginTop: 24}}>
            {errorMessages && 
              <Alert
                message={errorMessages}
                type="error"
                showIcon
                style={{marginBottom: "16px", textAlign: "left"}}
              />
            }
            <Row gutter={24}>
              <Col md={12} sm={24} xs={24}>
                <Form.Item  
                  name={"product_name"}
                  label="สินค้า">
                  <Input autoComplete="off" disabled/>
                </Form.Item>
              </Col>

              <Col md={12} sm={24} xs={24}>
                <Form.Item  
                  name={"product_grade_name"}
                  label="คุณภาพ (Grade)">
                  <Input autoComplete="off" disabled/>
                </Form.Item>
              </Col>

              <Col md={12} sm={24} xs={24}>
                <Form.Item  
                  name={"pallet_weight"}
                  label="น้ำหนักแผง">
                  <Input addonAfter={`น้ำหนักปกติ: ${data ? data.base_pallet_weight : 0.00}`} disabled/>
                </Form.Item>
              </Col>

              <Col md={12} sm={24} xs={24}>
                <Form.Item  
                  name={"product_weight"}
                  label="น้ำหนักสุทธิ">
                  <Input autoComplete="off" disabled/>
                </Form.Item>
              </Col> 

              <Col md={24} sm={24} xs={24}>
                <Form.Item>
                  <CustomScaleCard
                    title="ชั่งพาเลท"
                    showExtraButton={false}
                    showImages={true}
                    initialData={(data && data.latest_weighting_ref) 
                    ? data.latest_weighting_ref : null}/>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Content>
      </Spin>
    </Layout>
  )
}