import { Col, Form, Modal, Row, Select, Spin, theme, Alert } from 'antd'
import React from 'react'
import _ from 'lodash'
import CustomScaleCard from '../common/CustomScaleCard'
import { URL_PALLET } from '../../constants/urls'
import { GET, POST } from '../../frameworks/HttpClient'
import { PALLET_STATUS } from '../../constants/strings'
import { currencyFormat } from '../../frameworks/Util'

export default function ShareInventory(props) {
  const { open, onClose, onUpdate } = props
  const scaleRef = React.useRef()
  const [form] = Form.useForm()
  const [errorMessages, setErrorMessages] = React.useState(null)
  const [fetching, setFetching] = React.useState(null)
  const [selectedSource, setSelectedSource] = React.useState(null)
  const [sources, setSources] = React.useState([])
  const [blankDestinations, setBlankDestinations] = React.useState([])
  const [usageDestinations, setUsageDestinations] = React.useState([])

  const [debouncedSource, setDebouncedSource] = React.useState('')
  const debounceSource = React.useCallback(
    _.debounce((_searchVal) => {
      setDebouncedSource(_searchVal)
    }, 800),
    []
  )

  const [debouncedDestination, setDebouncedDestination] = React.useState('')
  const debounceDestination = React.useCallback(
    _.debounce((_searchVal) => {
      setDebouncedDestination(_searchVal)
    }, 800),
    []
  )

  const {
    token: { colorInfo },
  } = theme.useToken()

  /* Handel transfer pallet */
  const handleTransfer = async () => {
    setErrorMessages(null);
    try {
      let data = await form.validateFields()
      data['weighting_ref'] = await scaleRef.current.getData()
      console.log(data)
      await POST(URL_PALLET.PALLET_USAGE_TRANSFER, data)
      onUpdate()
    } catch (err) {
      setErrorMessages(err.errorMessages);
    }
  }

  /* Fetch pallet (sources) */
  const fetchSources = async () => {
    setFetching(true);
    try {
      const response = await GET(`${URL_PALLET.PALLET_USAGE}`, {
        is_close: false,
        has_si: false,
        search: debouncedSource,
      })
      setSources(
        response.data.results.map((item) => {
          return { 
            ...item, 
            label: `${item.pallet_code} - ${item.product_grade_name} [${currencyFormat(item.net_weight)} กก.]`, 
            value: item.id 
          }
        })
      )
    } catch (error) {
      console.error(error.errorMessages)
    } finally {
      setFetching(false);
    }
  }

  /* Fetch blank pallet (destination) */
  const fetchBlankDestinations = async () => {
    try {
      const response = await GET(`${URL_PALLET.PALLET}`, {
        is_active: true,
        status: PALLET_STATUS.AVAILABLE,
        search: debouncedDestination,
      })
      setBlankDestinations(
        response.data.results.map((item) => ({ ...item, label: item.code, value: item.id }))
      )
    } catch (error) {
      console.error(error)
    }
  }

  /* Fetch usages pallet (destination) */
  const fetchUsagesDestinations = async () => {
    try {
      if (!selectedSource) return

      const response = await GET(`${URL_PALLET.PALLET_USAGE}`, {
        is_close: false,
        product_grade: selectedSource.product_grade,
        search: debouncedDestination,
        has_si: false,
      })
      setUsageDestinations(
        response.data.results
          .filter((item) => item.id !== selectedSource.id)
          .map((item) => ({ 
            ...item, 
            label: `${item.pallet_code} - ${item.product_grade_name} [${currencyFormat(item.net_weight)} กก.]`, 
            value: item.ref }))
      )
    } catch (error) {
      console.error(error)
    }
  }

  React.useEffect(() => {
    if (debouncedSource) fetchSources()
  }, [debouncedSource])

  React.useEffect(() => {
    if (debouncedDestination) {
      fetchBlankDestinations()
      fetchUsagesDestinations()
    }
  }, [debouncedDestination])

  React.useEffect(() => {
    if (open) {
      form.resetFields()
      scaleRef && scaleRef.current.resetFields()
      fetchSources()
      fetchBlankDestinations()
    } else {
      setSelectedSource(null)
      setSources([])
      setBlankDestinations([])
      setUsageDestinations([])
      setErrorMessages(null)
    }
  }, [open])

  React.useEffect(() => {
    fetchUsagesDestinations()
    form.setFieldValue('destination', null)
  }, [selectedSource])

  const filterOption = (input, option) => {
    return option.label.toLowerCase().includes(input.toLowerCase())
  }

  return (
    <Modal
      width={800}
      title={'แบ่งพาเลท'}
      okText={'แบ่ง'}
      maskClosable={false}
      okButtonProps={{ style: { background: colorInfo } }}
      cancelText={'ยกเลิก'}
      centered={false}
      open={open}
      onOk={handleTransfer}
      onCancel={onClose}>

      {errorMessages && 
        <Alert
          message={errorMessages}
          type="error"
          showIcon
          style={{marginBottom: 16, textAlign: "left"}}
        />
      }

      <Form form={form} layout='vertical' autoComplete='off'>
        <Row gutter={16}>
          <Col md={12} sm={24} xs={24}>
            <Form.Item name={'source'} label='แบ่งจาก (ต้นทาง)' rules={[{ required: true }]}>
              <Select
                showSearch
                autoComplete='off'
                filterOption={filterOption}
                notFoundContent={fetching ? <Spin size='small' /> : '-'}
                onSearch={debounceSource}
                options={sources}
                allowClear={'-'}
                onChange={(item, option) => {
                  setSelectedSource(option)
                }}
              />
            </Form.Item>
          </Col>

          <Col md={12} sm={24} xs={24}>
            <Form.Item name={'destination'} label='ไปยัง (ปลายทาง)' rules={[{ required: true }]}>
              <Select
                disabled={!selectedSource}
                showSearch
                autoComplete='off'
                filterOption={filterOption}
                notFoundContent={fetching ? <Spin size='small' /> : '-'}
                onSearch={debounceDestination}
                options={[
                  {
                    label: 'พาเลทเปล่า',
                    options: blankDestinations,
                  },
                  {
                    label: 'พาเลทสินค้า',
                    options: usageDestinations,
                  },
                ].filter((item) => !_.isEmpty(item.options))}
                allowClear={'-'}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <CustomScaleCard ref={scaleRef} title={'ชั่งพาเลท'} />
    </Modal>
  )
}
