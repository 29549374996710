import React from "react";
import propTypes from "prop-types";
import { Alert, Button, Col, Form, Image, Input, Modal, Row, theme } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import ImageEditorModal from "../common/ImageEditorModal";
import { URL_SUPPLIER } from "../../constants/urls";
import { PATCH, POST } from "../../frameworks/HttpClient";
import { useUserContext } from "../../hooks/UserSettingProvider";
import userDefault from "../../assets/user.png"
import _ from "lodash";

export default function OwnerDetailModal(props) {
  const [form] = Form.useForm();
  const { user } = useUserContext ();
  const [loading, setLoading] = React.useState(false);
  const [userImageData, setUserImageData] = React.useState(null);
  const [uploadUserFile, setUploadUserFile] = React.useState(null);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const uploadUserRef = React.useRef(null);

  const {
		token: { colorPrimary, colorWarning }
	} = theme.useToken();

  // Create or Update owner
  const handleSaveOwner = async() => {
    setLoading(true)
    let response = null;
    try {
      let data = await form.validateFields();
      if (user.factory)
        data['factory'] = user.factory
      
      if (userImageData)
        data['profile_picture'] = userImageData

      if(props.target){
        // Edit supplier
        response = await PATCH(`${URL_SUPPLIER.OWNER}${props.target.id}/`, data);
      } else {
        // Create supplier
        response = await POST(URL_SUPPLIER.OWNER, data);
      }

      if (response)
        props.onUpdate(response.data.id);
      
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    if (props.open) {
      if (props.target) {
        form.setFieldsValue(props.target);
      }
    } else {
      form.resetFields();
      setUserImageData(null);
      setErrorMessages(null);
    }
  },[props.open]);

  return(
    <Modal
      title={"เจ้าของกิจการ"}
      width={800}
      okText={props.target ? "แก้ไข" : "เพิ่ม"}
      okButtonProps={{ 
        style : {background : props.target ? colorWarning : colorPrimary}, 
        loading: loading}}
      cancelText={"ยกเลิก"}
      cancelButtonProps={{loading: loading}}
      open={props.open}
      onOk={handleSaveOwner}
      onCancel={props.onClose}>

      <Form
        form={form}
        layout="vertical"
        autoComplete="off">
        
        {errorMessages && 
          <Alert  
            style={{marginBottom: 16, textAlign: "left"}}
            message={errorMessages}
            type="error"
            showIcon/>
        }

        <Row gutter={16}>
          <Col md={8} sm={24} xs={24}>
            <div style={{ textAlign: "center",}} className='section-wrapper'>
              <Image
                src={userImageData 
                  ? userImageData 
                  : (props.target && props.target.profile_picture) 
                  ? props.target.profile_picture
                  : userDefault}
                height={170} 
                width={"auto"}/>
              <div>
                <Button 
                  style={{marginTop: 8}}
                  icon={<UploadOutlined />} 
                  onClick={() => uploadUserRef.current.click()}>
                    อัพโหลดรูป
                </Button>
              </div>
            </div>
          </Col>
          
          <Col md={16} sm={24} xs={24}>
            <Row gutter={16}> 
              <Col md={12} sm={24} xs={24}>
                <Form.Item  
                  name={"first_name"}
                  label="ชื่อ"
                  rules={[{ required: true }]}>
                  <Input autoComplete="off" />
                </Form.Item>
              </Col>

              <Col md={12} sm={24} xs={24}>
                <Form.Item  
                  name={"last_name"}
                  label="สกุล"
                  rules={[{ required: true }]}
                  >
                    <Input autoComplete="off" />
                </Form.Item>
              </Col>

              <Col md={12} sm={24} xs={24}>
                <Form.Item  
                  name={"nickname"}
                  label="ชื่อเล่น"
                  rules={[{ required: true }]}>
                    <Input autoComplete="off" />
                </Form.Item>
              </Col>

              <Col md={12} sm={24} xs={24}>
                <Form.Item  
                  name={"citizen_id"}
                  label="เลขบัตรประชาชน">
                    <Input autoComplete="off" />
                </Form.Item>
              </Col>

              <Col md={12} sm={24} xs={24}>
                <Form.Item  
                  name={"phone"}
                  label="เบอร์โทรศัพท์ 1">
                    <Input autoComplete="off" />
                </Form.Item>
              </Col>

              <Col md={12} sm={24} xs={24}>
                <Form.Item  
                  name={"phone2"}
                  label="เบอร์โทรศัพท์ 2">
                    <Input autoComplete="off" />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col md={8} sm={24} xs={24}>
            <Form.Item  
              name={"line_id"}
              label="Line ID">
                <Input autoComplete="off" />
            </Form.Item>
          </Col>

          <Col md={16} sm={24} xs={24}>
            <Form.Item  
              name={"document_url"}
              label="URL To Document">
                <Input autoComplete="off" />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <ImageEditorModal
        open={uploadUserFile ? true : false}
        image={uploadUserFile}
        onUpdated={(image) => {
          setUserImageData(image)
          setUploadUserFile(null)
        }}
        onClose={() => setUploadUserFile(null)}/>

      <ImgCrop aspect={16 / 9} aspectSlider>
        <input id="imgSelect"
          type="file"
          accept="image/*"
          ref={uploadUserRef}
          style={{ display: 'none' }}
          onChange={async (evt) => {
            if (evt.target.files.length > 0) {
              setUploadUserFile(URL.createObjectURL(evt.target.files[0]))
              evt.target.value = null  // clear reference to enable select same file}
          }
        }}/>
      </ImgCrop>
    </Modal>
  )
}

OwnerDetailModal.defaultProps = {
	open: false,
  target: null,
	onClose: () => {},
  onUpdate: () => {}
}

OwnerDetailModal.propTypes = {
	open: propTypes.bool,
  target: propTypes.object,
	onClose: propTypes.func,
  onUpdate: propTypes.func,
}