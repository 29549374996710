import React from "react";
import propTypes from "prop-types";
import { Row, Modal, Form, Col, Input, theme, Alert} from "antd";
import { DELETE, PATCH } from "../../frameworks/HttpClient";
import { URL_PALLET } from "../../constants/urls";

export default function PalletRemoveModal(props) {
  const {target, onClose, onUpdate, open} = props;
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessage] = React.useState();
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const {
    token: { colorError }
  } = theme.useToken();

  /** Delete pallet */
  const handleCancelSIPallet = async() => {
    try{
      const data = await form.validateFields()
      const id = props.palletIdField ? target[props.palletIdField] : target.id
      await PATCH(`${URL_PALLET.PALLET_USAGE}${id}/`, {...data, shipping_instruction : null })
      onUpdate();
    } catch (error) {
      console.log(error.errorMessages)
    }
  }

  React.useEffect(() => {
    if (open) {
      form.resetFields();
    }

  }, [open])

  return (
    <Modal
      title={`ลบพาเลท - ${props.target ? props.target.pallet_code : ""}`}
      open={open}
      onClose={onClose}
      okText={"ตกลง"}
      onOk={handleCancelSIPallet}
      okButtonProps={{style: {background: colorError}, loading: loading}}
      cancelText={"ยกเลิก"}
      maskClosable={false}
      onCancel={onClose}>
      <Form form={form} layout="vertical">
        {errorMessages && 
          <Alert
            message={errorMessages}
            type="error"
            showIcon
            style={{marginBottom: 16, textAlign: "left"}}
          />
        }

        <Row gutter={[48]}>
          <Col md={24} sm={24} xs={24}>
            <Form.Item
              label={"กรุณากรอกเหตุผลในการลบพาเลท"}
              name="note"
              rules={[{ required: true, message: "โปรดกรอกข้อความ" },]}>
              <TextArea />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

PalletRemoveModal.defaultProps = {
  open: false,
  target: null,
  palletIdField: null, // set default field to read pallet ID from target
  onClose: () => {},
  onUpdate: () => {},
}

PalletRemoveModal.prototype = {
  open: propTypes.bool,
  target: propTypes.object,
  palletIdField: propTypes.string,
  onClose: propTypes.func,
  onUpdate: propTypes.func
}
