import React from 'react'
import propTypes from 'prop-types'
import { Row, Modal, Form, Col, Input, theme, Alert } from 'antd'
import { POST } from '../../frameworks/HttpClient'
import { URL_PALLET } from '../../constants/urls'
import { PALLET_USAGE_ACTION } from '../../constants/strings'

export default function CancelPalletModal(props) {
  const [loading, setLoading] = React.useState(false)
  const [errorMessages, setErrorMessage] = React.useState()
  const [form] = Form.useForm()
  const {
    token: { colorError },
  } = theme.useToken()

  const handleDelete = async () => {
    if (!props.target) {
      setErrorMessage('ไม่พบข้อมูลพาเลท!')
      return
    }

    setErrorMessage(null)
    setLoading(true)
    try {
      const data = await form.validateFields()
      await POST(URL_PALLET.PALLET_USAGE_TRANSACTION, {
        pallet: props.palletIdField ? props.target[props.palletIdField] : props.target.id,
        action: props.action,
        ...data,
      })
      props.onUpdate()
    } catch (error) {
      setErrorMessage(error.errorMessages)
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    if (props.open) {
      form.resetFields()
    } else {
      setErrorMessage(null);
    }
  }, [props.open])

  return (
    <Modal
      title={((props.action === PALLET_USAGE_ACTION.CLOSE) ? 'ยกเลิกพาเลท' : ' รีเซ็ตพาเลท') + (props.target ? ` - ${props.target.pallet_code}` : '')}
      open={props.open}
      okText={'ยืนยัน'}
      okButtonProps={{ style: { background: colorError }, loading: loading }}
      onOk={handleDelete}
      cancelText={'ยกเลิก'}
      onCancel={props.onClose}
    >
      <Form form={form} layout='vertical'>
        {errorMessages && (
          <Alert
            message={errorMessages}
            type='error'
            showIcon
            style={{ marginBottom: 16, textAlign: 'left' }}
          />
        )}

        <Row gutter={[48]}>
          <Col md={24} sm={24} xs={24}>
            <Form.Item
              label={'เหตุผล'}
              name='note'
              rules={[{ required: true, message: 'กรุณากรอกเหตุผล' }]}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

CancelPalletModal.defaultProps = {
  open: false,
  target: null, // data of pallet
  action: PALLET_USAGE_ACTION.CLOSE,
  palletIdField: null, // set default field to read pallet ID from target
  onClose: () => {},
  onUpdate: () => {},
}

CancelPalletModal.prototype = {
  open: propTypes.bool,
  target: propTypes.object,
  action: propTypes.string,
  palletIdField: propTypes.string,
  onClose: propTypes.func,
  onUpdate: propTypes.func,
}
