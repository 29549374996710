import { Col, Form, Modal, Row, Flex, theme, Button } from 'antd'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { PALLET_USAGE_ACTION } from '../../constants/strings'
import ColorButton from '../common/ColorButton'
import TSTable from '../common/TSTable'
import CustomScaleCard from '../common/CustomScaleCard'
import { URL_PALLET } from '../../constants/urls'
import { GET } from '../../frameworks/HttpClient'
import { currencyFormat, toHumanDateTime } from '../../frameworks/Util'

const toDescription = (item) => {
  if (!(item && item.action)) return ''
  switch (item.action) {
    case PALLET_USAGE_ACTION.IMPORT:
      return 'รับเข้า' + (item.cause_type ? `: ${item.cause_type}` : '')
    case PALLET_USAGE_ACTION.EXPORT:
      return 'ส่งออก' + (item.cause_type || '')
    case PALLET_USAGE_ACTION.CLOSE:
      return 'ปิด'
    case PALLET_USAGE_ACTION.RESET:
      return 'รีเซ็ต'
    case PALLET_USAGE_ACTION.EDIT:
      return 'แก้ไข'
    case PALLET_USAGE_ACTION.TRANSFER:
      return (
        'แบ่งพาเลท ไปยัง ' +
        item.transfer_ref.destination_code +
        ` (${currencyFormat(item.transfer_ref.weight)} กก.)`
      )
    case PALLET_USAGE_ACTION.RECEIVE:
      return (
        'ได้รับ มาจาก ' +
        item.receive_ref.source_code +
        ` (${currencyFormat(item.receive_ref.weight)} กก.)`
      )
    default:
      return ''
  }
}

export default function ShareHistoryModal(props) {
  const { open, target, onClose } = props
  const [form] = Form.useForm()
  const [data, setData] = React.useState([])
  const [historyWeight, setHistoryWeight] = React.useState(null)

  const {
    token: { colorInfo },
  } = theme.useToken()

  const columns = [
    {
      title: 'วันที่',
      dataIndex: 'created',
      width: 170,
      render: (value) => toHumanDateTime(value),
    },
    {
      title: 'น้ำหนัก',
      dataIndex: 'net_weight',
      width: 140,
      render: (value) => <div style={{textAlign: "right"}}>{currencyFormat(value)}</div>
    },
    {
      title: 'รายละเอียด',
      dataIndex: 'description',
    },
    {
      title: 'แก้ไขโดย',
      dataIndex: 'created_by_name',
      width: 200,
    },
    {
      title: 'action',
      dataIndex: '',
      width: 80,
      render: (_, original) => {
        return(
          original && original.pallet_usage_weighting_reference &&
          <Flex
            align='center'
            justify='center'
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <ColorButton
              type='primary'
              override={colorInfo}
              icon={<FontAwesomeIcon icon={faEye} />}
              onClick={() => {
                setHistoryWeight(original.pallet_usage_weighting_reference)
              }}
            />
          </Flex>
        )
      },
    }
  ]

  const getData = async () => {
    if (!target) return
    const response = await GET(URL_PALLET.PALLET_USAGE_TRANSACTION, { pallet: target.id })
    setData(
      response.data.results.map((item) => ({
        ...item,
        weight: item.detail && item.detail.current_net_weight,
        description: toDescription(item),
      }))
    )
  }

  React.useEffect(() => {
    if (open) {
      getData()
    } else {
      setData([])
    }
  }, [open])

  return (
    <Modal
      title={`ประวัติน้ำหนักพาเลท - ${props.target ? props.target.pallet_code : ""}`}
      width={1200}
      centered={false}
      open={open}
      onCancel={onClose}
      footer={null}>
      <Form 
        form={form} 
        layout='vertical' 
        autoComplete='off'>
        <Row gutter={16}>
          <Col md={24} sm={24} xs={24}>
            <TSTable 
              columns={columns} 
              data={data} 
              rowKey={'id'} 
              size={'small'} 
              pageSize={10} />
          </Col>
        </Row>
      </Form>

      <Modal open={historyWeight} closeIcon={null}
        footer={[
          <Button type='primary' onClick={() => setHistoryWeight(null)}>
            Close
          </Button>, 
        ]}>
        <CustomScaleCard
          title={'ประวัติการชั่งพาเลท'}
          initialData={historyWeight}
          showImages={true}
          disabled={true}
        />
      </Modal>

    </Modal>
  )
}
