import { Button, ConfigProvider, theme } from 'antd';
import React from 'react';
import propTypes from "prop-types";
import { PRIMARY_COLOR } from '../../constants/color';


export default function ColorButton ({ children, override, ...props }) {
  const { token } = theme.useToken();
  const overrideColor = token[override] || override;
  const modifiedTheme = {
    token: {...token},
    components: {
      Button: { colorPrimary: overrideColor ? overrideColor : PRIMARY_COLOR, algorithm: true }
    }
  };

  return (
    <ConfigProvider theme={modifiedTheme}>
      { !children || (typeof(children) == 'function' && !children()) ? <Button {...props}/> : <Button {...props}>{children}</Button> }
    </ConfigProvider>
  )
}


ColorButton.defaultProps = {
  children: () => null,
  override: '',
}

ColorButton.prototype = {
  children: propTypes.func,
  override: propTypes.string,
}