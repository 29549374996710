import React from "react";
import { Form, theme, Input, Modal, Alert } from 'antd'
import { LogoutOutlined } from '@ant-design/icons';
import { DELETE } from "../../frameworks/HttpClient";
import { URL_SUPPLIER } from "../../constants/urls";
import { useNavigate, useParams } from "react-router-dom";
import { PATH } from "../../routes/CustomRoutes";

export default function DeleteSupplierModal(props) {
  const params = useParams();
  const [form] = Form.useForm();
  const {open, onClose } = props;
  const navigate = useNavigate();
  const [errorMessages, setErrorMessage] = React.useState(null);

  const {
		token: { colorError },
	} = theme.useToken();


  const handleDelete = async() => {
    try {
      let note = await form.validateFields();
      await DELETE(`${URL_SUPPLIER.SUPPLIER}${params.id}/`, note)
      navigate(PATH.SUPPLIERS);
    } catch (error) {
      setErrorMessage(error.errorMessages)
    }
  }

  return(
    <Modal
      title={"ลบลูกค้า"}
      okText={"ยืนยัน"}
      okButtonProps={{ style : {background : colorError}}}
      cancelText={"ยกเลิก"}
      centered={false}
      icon={<LogoutOutlined />}
      open={open}
      onOk={handleDelete}
      onCancel={onClose}>

      {errorMessages && 
        <Alert
          message={errorMessages}
          type="error"
          showIcon
          style={{marginBottom: 16, textAlign: "left"}}
        />
      }
      <Form
        form={form}
        layout="vertical"
        autoComplete="off">
          <Form.Item  
            name={"delete_note"}
            label="หมายเหตุ"
            rules={[{ required: true }]}>
              <Input.TextArea rows={3} autoComplete="off" />
          </Form.Item>
      </Form>
    </Modal>
  )
}