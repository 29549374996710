import React from 'react';
import * as _ from 'lodash';
import { POST } from '../frameworks/HttpClient';
import { THIN_CLIENT_BASE_URL, URL_THIN_CLIENT } from '../constants/urls';

export default function useThinClient() {
  const [loading, setLoading] = React.useState(false);

  const showThinClientMeasureWeight = async (params) => {
    try{
      setLoading(true)
      const response = await POST(`${THIN_CLIENT_BASE_URL}${URL_THIN_CLIENT.WEIGHT_MEASURE}`, params)
      return response.data
    } catch (error) { 
      console.log(error) 
      return null
    } finally {
      setLoading(false)
    }
  }

  return [showThinClientMeasureWeight, loading];
}