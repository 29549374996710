import React from "react";
import dayjs from "dayjs";
import { Row, Col, theme, Input, Button, DatePicker, Space, Form, Select, Popover  } from 'antd'
import { CloseCircleOutlined, MinusCircleOutlined, CheckCircleOutlined, SearchOutlined, ExclamationCircleOutlined, FilePdfOutlined , FileExcelOutlined} from '@ant-design/icons';
import TSTable from "../components/common/TSTable";
import moment from "moment";
import { DATE_FORMAT, HUMAN_DATETIME_FORMAT, HUMAN_DATE_FORMAT, TICKET_STATUS, TICKET_STATUS_OPTIONS, TICKET_STATUS_NAME, DATETIME_FORMAT } from "../constants/strings";
import { useNavigate } from "react-router-dom";
import { PATH } from "../routes/CustomRoutes";
import * as _ from 'lodash';
import { URL_WEIGHT, URL_PRODUCT } from "../constants/urls";
import useDropdownItem from "../hooks/useDropdownItem";
import { currencyFormat } from "../frameworks/Util";

export default function ImportProductListView() {
  const navigate = useNavigate();
  const [dateFilter, setDateFilter] = React.useState(null);
  const [statusFilter, setStatusFilter] = React.useState("unfinished");
  const [search, setSearch] = React.useState(null);
  const tableRef = React.useRef(null);
  const MINUTE_MS = 60000;
  const [productOptions, fetcthProductOptions] = useDropdownItem({
    url: URL_PRODUCT.PRODUCT,
    manual: true, pagination: true,
    key: 'name', value: 'name', label: 'name'
  });

  const {
		token: { colorError, colorSuccess, colorWarning },
	} = theme.useToken();

  const columns = [
    {
      title: "วันที่ - เวลา",
      dataIndex: "created",
      render: (value) => moment(value).format(HUMAN_DATETIME_FORMAT),
    },
    {
      title: "ตั๋วชั่ง",
      dataIndex: "code",
      searcher: true,
    },
    {
      title: "ทะเบียนรถ",
      dataIndex: "vehicle_license_plate",
      searcher: true,
    },
    {
      title: "ลูกค้า",
      dataIndex: "customer_name",
      searcher: true,
    },
    {
      title: "สินค้า",
      dataIndex: "product_name",
      searcher: true,
      searcherOptions: productOptions,
    },
    {
      title: "นน.สุทธิ(ตาชั่งใหญ่)",
      dataIndex: "snapshot_net_weight",
      render: (value) => {
        return currencyFormat(value)
      }
    },
    {
      title: "นน.สุทธิ(ตาชั่งเล็ก)",
      dataIndex: "snapshot_sum_product_weight",
      render: (value) => {
        return currencyFormat(value)
      }
    },
    {
      title: "ส่วนต่าง",
      dataIndex: "snapshot_product_diff",
      render: (value) => {
        return currencyFormat(value)
      }
    },
    {
      title: "ชั่งออก",
      dataIndex: "status", 
      render : (value) => {
        return (
          <div style={{ textAlign : 'center', fontSize : 20 }}>
            {renderIconStatus(value)}
          </div>)
      },
      renderReport: value => renderReportStatus(value)
    }]

  const renderIconStatus = (status)  => {
    return (
      <Popover content={TICKET_STATUS_NAME[status]}> 
      {
        status === TICKET_STATUS.APPROVED ? <CheckCircleOutlined style={{ color : colorSuccess}}/> :
        status === TICKET_STATUS.PROGRESSING ? <MinusCircleOutlined style={{ color : '#AAAAAA'}}/> :
        status === TICKET_STATUS.PENDING ? <ExclamationCircleOutlined style={{ color : colorWarning}}/> : 
        status === TICKET_STATUS.CHECKING ? <ExclamationCircleOutlined style={{ color : colorWarning}}/> :
        <CloseCircleOutlined style={{ color : colorError}}/> 
      }
      </Popover>
    )
  }

  const renderReportStatus = status => {
    return(
      status === TICKET_STATUS.APPROVED ? "Yes" :
      status === TICKET_STATUS.PROGRESSING ? "No" :
      status === TICKET_STATUS.PENDING ? "No" :
      status === TICKET_STATUS.CHECKING ? "No" :
      "Error" 
    )
  }

  /** Refresh table */
  const fetchData = () => {
    if (tableRef.current) {
      tableRef.current.fetch();
    }
  }

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (tableRef.current && tableRef.current.getCurrentPage() === 1) {
        fetchData();
      }
    }, MINUTE_MS);

    fetcthProductOptions()

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [])

  React.useEffect(() => {
    fetchData();
  }, [dateFilter, statusFilter])

  return (
    <div>
      <Form autoComplete="off">

        <Space style={{ float: 'right' }}>
          <Row gutter={8}>
            <Col>
              <Button
                icon={<FilePdfOutlined />}
                onClick={async () => {
                  await tableRef.current.exportPDF({
                    title: "รายการชั่งซื้อ (ตาชั่งเล็ก)",
                    subtitle: `ดึงข้อมูล ณ​ วันที่ ${dayjs().format(HUMAN_DATETIME_FORMAT)}`,
                    landscape: true
                  })
                }}>
                PDF
              </Button>
            </Col>
            <Col>
              <Button
                icon={<FileExcelOutlined />}
                onClick={async () => {
                  await tableRef.current.exportXlsx({
                    title: "รายการชั่งซื้อ (ตาชั่งเล็ก)",
                    subtitle: `ดึงข้อมูล ณ​ วันที่ ${dayjs().format(HUMAN_DATETIME_FORMAT)}`,
                    filename: `รายการชั่งซื้อ (ตาชั่งเล็ก) - ${dayjs().format(DATETIME_FORMAT)}.xlsx`
                  })
                }}>
                Excel
              </Button>
            </Col>
          </Row>
        </Space>

        <Row gutter={[16, 16]} wrap>
          <Col lg={6} md={12} xs={24}>
            <Form.Item label={'วันที่'}>
              <DatePicker format={HUMAN_DATE_FORMAT} onChange={(value) => setDateFilter(value)} placeholder='เลือกวันที่' style={{ width: '100%' }} />  
            </Form.Item>
          </Col>
          <Col lg={6} md={12} xs={24}>
            <Form.Item label={"สถานะ"}>
              <Select 
                onChange={value => setStatusFilter(value)}
                value={statusFilter}
                options={[
                  {key: "", value: "", label: "ทั้งหมด"},
                  ...TICKET_STATUS_OPTIONS,
                ]}/>
            </Form.Item>
          </Col>
          <Col xl={12} lg={6} md={12} xs={24}>
          </Col>
      </Row>

      <TSTable
        ref={tableRef}
        columns={columns}
        rowKey={"id"}
        url={`${URL_WEIGHT.WEIGHT_TICKET}`}
        size={"small"}
        pageSize={10}
        params={{
          need_subticket : true, 
          created_date: dateFilter && dateFilter.format(DATE_FORMAT), 
          status: statusFilter ? statusFilter : null,
          search }}
        onRow={(record, rowIndex) => ({
          onClick: _ => navigate(`${PATH.IMPORT_PRODUCTS_SCALE}/${record.id}/`)
        })}
        rowClassName={(rowData) => {
          if (rowData.status === TICKET_STATUS.CHECKING) {
            return 'table-row-red'
          }
          else if (rowData.status === TICKET_STATUS.PENDING) {
            return 'table-row-yellow'
          }
        }}
      />
      </Form>
    </div>
  )
}