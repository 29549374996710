import React from 'react'
import propTypes from 'prop-types'
import { Row, Modal, Form, Col, Input, theme, Alert } from 'antd'
import { POST } from '../../frameworks/HttpClient'
import { URL_WEIGHT } from '../../constants/urls'
import { TICKET_ACTION } from '../../constants/strings'

export default function RejectPurchaseModal(props) {
  const [loading, setLoading] = React.useState(false)
  const [errorMessages, setErrorMessage] = React.useState()
  const [form] = Form.useForm()
  const {
    token: { colorError },
  } = theme.useToken()

  const handleDelete = async () => {
    if (!props.targetId) {
      setErrorMessage(`ไม่พบข้อมูลคำสั่งปฏิเสธ!`)
      return
    }

    setErrorMessage(null);
    setLoading(true);
    try {
      const formData = await form.validateFields()
      const data = {
        ticket: props.targetId,
        action: TICKET_ACTION.REJECT,
        ...formData
      }
      await POST(URL_WEIGHT.TICKET_TRANSACTION, data)
      props.onRejected()
    } catch (error) {
      setErrorMessage(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    if (props.open) {
      form.resetFields()
    } else {
      setErrorMessage(null);
    }
  }, [props.open])

  return (
    <Modal
      title="ยืนยันปฏิเสธการรับสินค้า"
      open={props.open}
      okText={'ยืนยัน'}
      okButtonProps={{ style: { background: colorError }, loading: loading }}
      onOk={handleDelete}
      cancelText={'ยกเลิก'}
      onCancel={props.onClose}
    >
      <Form form={form} layout='vertical'>
        {errorMessages && (
          <Alert
            message={errorMessages}
            type='error'
            showIcon
            style={{ marginBottom: 16, textAlign: 'left' }}
          />
        )}

        <Row gutter={[48]}>
          <Col md={24} sm={24} xs={24}>
            <Form.Item
              label={'เหตุผล'}
              name='note'
              rules={[{ required: true, message: 'กรุณากรอกเหตุผล' }]}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

RejectPurchaseModal.defaultProps = {
  open: false,
  targetId: null,
  onClose: () => { },
  onRejected: () => { },
}

RejectPurchaseModal.prototype = {
  open: propTypes.bool,
  targetId: propTypes.object,
  onClose: propTypes.func,
  onRejected: propTypes.func,
}
