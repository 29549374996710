import React from 'react'
import propTypes from "prop-types";
import { Modal, theme ,Form ,Row, Col,Input ,Select ,Space, Spin, Popconfirm, Alert} from 'antd';
import ColorButton from '../common/ColorButton';
import { DELETE, GET, PATCH, POST } from '../../frameworks/HttpClient';
import { URL_COMMON, URL_WEIGHT } from '../../constants/urls';
import _ from "lodash";
import { useUserContext } from '../../hooks/UserSettingProvider';

export default function VehicleModal(props) {
  const [form] = Form.useForm();
  const [optionVehicle, setOptionVehicle] = React.useState(null)
  const [debouncedProvince, setDebouncedProvince] = React.useState('');
  const [provinces, setProvinces] = React.useState(null);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [fetching, setFetching] = React.useState(false);
  const { user } = useUserContext();
	const {
		token: { colorPrimary ,colorError, colorWarning},
	} = theme.useToken();
  
  const formItemLayout = { labelCol: { span: 8 } }

  const debounceProvince = React.useCallback(_.debounce((_searchVal) => { setDebouncedProvince(_searchVal) }, 800), []);

  /** Create or update Vehical */ 
  const handleVehical = async() => {
    try {
      // Prepare data
      let data = await form.validateFields();
      if (user) 
        data['factory'] = user.factory
      
      // Create or update Vehical
      let response = null;
      if(props.target) {
        // Update Vehical
        response = await PATCH(`${URL_WEIGHT.VEHICLE}${props.target.id}/`, data);
      } else {
        // Create Vehical
        response = await POST(URL_WEIGHT.VEHICLE, data);
      }

      if (response)
        props.onUpdate(response.data);

    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
  }

  /** Delete Vehical */
  const handleDelete = async() => {
    try {
      await DELETE(`${URL_WEIGHT.VEHICLE}${props.target.id}`)
      props.onUpdate();
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
  }

  /** Fetch Options: Vehical type */
  const fetchVehicleType = async() => {
    try {
      const response = await GET(URL_WEIGHT.VEHICLE_TYPE)
      setOptionVehicle(response.data.results.map(item => {
        return({
          label : item.name,
          value : item.id
        })}
      ));
    } catch (error) {
      
    }
  }

  /** Fetch Options: Province */
  const fetchProvince = async () => {
    setFetching(true);
    try {
      const response = await GET(`${URL_COMMON.PROVINCE}`, {search : debouncedProvince})
      setProvinces(response.data.map((item) => {
        return {
          ...item,
          label: item.name_th,
          value: item.id,
        }
      }))
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setFetching(false);
    }
  }

  React.useEffect(() => {
    fetchProvince();
  }, [debouncedProvince])

  React.useEffect(() => {
    if(props.target){
      form.setFieldsValue(props.target);
    }
  }, [props.target])

  React.useEffect(() => {
    if(props.open){
      fetchVehicleType();
    } else {
      form.resetFields();
      setErrorMessages(null);
    }
  }, [props.open])

	return (
		<Modal 
			title={"ยานพาหนะ"}
      open={props.open}
			onCancel={props.onClose}
      footer={[
        <>
          <div style={{float : 'left'}}>
            {props.target && 
              <ColorButton 
                type={"primary"} 
                override={colorError}
                onClick={() => {
                  Modal.confirm({
                    title: 'ลบยานพาหนะ',
                    content: `คุณต้องการลบยานพาหนะ ใช่หรือไม่ ?`,
                    okText: 'ยืนยัน',
                    okButtonProps: {style: { background : colorError}},
                    maskClosable: true,
                    onOk: () => {
                      handleDelete()
                    },
                    cancelText: 'ยกเลิก',
                  });
                }}
                >
                  ลบ
              </ColorButton>}
          </div>

        <ColorButton 
          onClick={props.onClose}>
            ยกเลิก
        </ColorButton>

        <ColorButton 
          type={"primary"} 
          override={props.target ? colorWarning : colorPrimary} 
          onClick={handleVehical}>
            {props.target ? "แก้ไข" : "เพิ่ม"}
        </ColorButton>
        </>
      ]}>
        
			<Form
        form={form}
        {...formItemLayout}
        layout="horizontal"
        autoComplete="off">

        {errorMessages && 
          <Alert
            style={{marginBottom: 16, textAlign: "left"}}
            message={errorMessages}
            type="error"
            showIcon/>
        }

        <Row gutter={16}>
          <Col md={24} sm={24} xs={24}>
            <Form.Item  
              name={"vehicle_type"}
              label="ประเภท"
              rules={[{ required: true }]}>
              <Select options={optionVehicle}/>
            </Form.Item>
          </Col>
          <Col md={24} sm={24} xs={24}>
            <Form.Item  
              name={"license_plate_number"}
              label="เลขทะเบียนรถ"
              rules={[{ required: true }]}>
              <Input autoComplete="off" />
            </Form.Item>
          </Col>
          <Col md={24} sm={24} xs={24}>
            <Form.Item  
              name={"license_plate_province"}
              label="ทะเบียนจังหวัด"
              rules={[{ required: true }]}>
              <Select 
                initialvalues={"-"}
                autoComplete="off" 
                showSearch
                filterOption={false}
                notFoundContent={fetching ? <Spin size="small" /> : "-"}
                onSearch={debounceProvince}
                options={provinces}
                allowClear={"-"}/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
		</Modal>
	)
}

VehicleModal.defaultProps = {
	open: false,
  target: null,
	onClose: () => {},
	onUpdate: (data) => {},
}

VehicleModal.propTypes = {
	open: propTypes.bool,
  target: propTypes.object,
	onClose: propTypes.func,
  onUpdate: propTypes.func,
}
