import React from "react";
import { Alert, Button, Col, DatePicker, Form, Modal, Row, Select, Spin, theme } from "antd";
import { useForm } from "antd/es/form/Form";
import { FileExcelOutlined, FilePdfOutlined } from "@ant-design/icons";
import { GET } from "../frameworks/HttpClient";
import _ from "lodash";
import moment from "moment";
import { DATE_FORMAT, REPORT_FILTER } from "../constants/strings";
import { URL_PRODUCT, URL_SUPPLIER } from "../constants/urls";
import useDropdownItem from "../hooks/useDropdownItem";

export default function ReportModal(props) {
  const [data, setData] = React.useState(null);
  const [form] = useForm();
  const [filters, setFilters] = React.useState({});
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [excelLoading, setExcelLoading] = React.useState(false);
  const [pdfLoading, setPDFLoading] = React.useState(false);

  const productWatch = Form.useWatch("product", form)
  
  // // Options
  const [productOptions, fetchPoductOptions] = useDropdownItem({ 
    url: URL_PRODUCT.PRODUCT, manual: true, pagination: true, params: { page_size: 999 } 
  });
  const [customerOptions, fetchCustomerOptions] = useDropdownItem({
    url: URL_SUPPLIER.SUPPLIER, manual: true, pagination: true, params: { page_size: 100 }
  });
  const [productGradeOptions, fetchPoductGradeOptions, customerFetching] = useDropdownItem({
    url: URL_PRODUCT.PRODUCT_GRADE, manual: true, pagination: true, params: { page_size: 999 }
  });

  // Search Debounce
  const [debouncedCustomer, setDebouncedCustomer] = React.useState("");

  const {
		token: { colorSuccessTextActive, colorErrorTextActive }
	} = theme.useToken();

  const debounceCustomers = React.useCallback(
    _.debounce((_searchVal) => { setDebouncedCustomer(_searchVal ? _searchVal : '') }, 800),
    []
  );

  const updateFilters = (myData) => {
		if (myData) {
			setFilters({
        showDate: myData.filter.find(filter => filter === REPORT_FILTER.DATE) ? true : false,
        showDateRange: myData.filter.find(filter => filter === REPORT_FILTER.DATE_RANGE) ? true : false,
				showProduct: myData.filter.find(filter => filter === REPORT_FILTER.PRODUCT) ? true : false,
				showProductGrade: myData.filter.find(filter => filter === REPORT_FILTER.PRODUCT_GRADE) ? true : false,
        showCustomer: myData.filter.find(filter => filter === REPORT_FILTER.CUSTOMER) ? true : false,
			})
		} else {
			setFilters({});
		}
	}

  const getParams = (values) => {
    let params = {...values}

    if (values[REPORT_FILTER.DATE]) {
      params["date"] = values[REPORT_FILTER.DATE].format(DATE_FORMAT);
    }

    if (values[REPORT_FILTER.DATE_RANGE]) {
      params["date_start"] = values[REPORT_FILTER.DATE_RANGE][0].format(DATE_FORMAT);
      params["date_end"] = values[REPORT_FILTER.DATE_RANGE][1].format(DATE_FORMAT);
      delete params[REPORT_FILTER.DATE_RANGE]
    }

    if (values[REPORT_FILTER.PRODUCT]) {
      params["product"] = values[REPORT_FILTER.PRODUCT].value;
    }

    if (values[REPORT_FILTER.PRODUCT_GRADE]) {
      params["product_grade"] = values[REPORT_FILTER.PRODUCT_GRADE].value;
    }

    if (values[REPORT_FILTER.CUSTOMER]) {
      params["customer"] = values[REPORT_FILTER.CUSTOMER].value;
    }

		return params;
	}

  /** Filter options */
  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  
  const handleSubmit = async (exportType) => {
		let url = data.url
		if (!url) {
			setErrorMessages("Report not found!");
			return;
		}

    props.onStartLoading(exportType)
    if (exportType === "pdf")
      setPDFLoading(true);
    else
      setExcelLoading(true);
    
	
		// Prepare data
		setErrorMessages(null);
    url = `${url}${exportType}/`
		try {
			const values = await form.validateFields();
			const params = getParams(values);
      
			// Goto export
			const response = await GET(url, params, false, true) // Download as blob
			
			if (exportType === "excel" && props.namePrefix != null) {
				const fileURL = URL.createObjectURL(response.data);
				const fileLink = document.createElement('a');
				fileLink.href = fileURL;
				fileLink.download = `${props.namePrefix.replace(/ /g, "_")}_${moment().unix() }.xlsx`;
				fileLink.click();
				setTimeout(() => {
					fileLink.remove();
				}, 100);
			} else {
        setTimeout(() => {
          const target = '_blank'
          window.open(URL.createObjectURL(response.data), target)
        })
      }
			props.onClose();
		} catch (error) {
			if (error.errorMessages) 
				setErrorMessages(error.errorMessages);
		}
		finally {
      props.onEndLoading()
      if (exportType === "pdf")
        setPDFLoading(false);
      else
        setExcelLoading(false);
	}
}

  React.useEffect(() => {
    if (debouncedCustomer) {
      fetchCustomerOptions({ search: debouncedCustomer });
    }
  }, [debouncedCustomer])

  React.useEffect(() => {
		if (filters && (Object.keys(filters).length !== 0)) {
      if (filters["showProduct"]) {
				fetchPoductOptions();
			}
      if (filters["showCustomer"]) {
        fetchCustomerOptions();
      }
		}
	}, [filters])

  React.useEffect(() => {
    if (!filters["showProductGrade"]) {
      return;
    }
    if (productWatch) {
      form.setFieldValue('product_grade', null)
      if (productWatch.value) {
        fetchPoductGradeOptions({ product: productWatch.value })
      }
    }
  }, [productWatch])

  React.useEffect(() => {
		setData(props.data);
		updateFilters(props.data);
	}, [props.data])

  React.useEffect(() => {
		if (props.open) {
			setErrorMessages(null);
			form.resetFields();
		} else {
      setDebouncedCustomer("");
    }
	}, [props.open])


  return(
    <Modal
      title={"รายงาน"}
      open={props.open}
      onOk={props.onClose}
      onCancel={props.onClose}
      footer={[
        <Button key={"1"} onClick={props.onClose}>{"Cancel"}</Button>,
        props.data && !props.data.hideExcel && (
          <Button
            key={"2"}
            type={"primary"}
            loading={excelLoading}
            icon={<FileExcelOutlined />}
            style={{ background: colorSuccessTextActive }}
            onClick={() => handleSubmit("excel")}
            >
            {"Excel"}
          </Button>
        ),
        props.data && !props.data.hidePDF && (
          <Button 
            key={"3"}
            type={"primary"}
            loading={pdfLoading}
            style={{background: colorErrorTextActive}}
            icon={<FilePdfOutlined />}
            onClick={() => handleSubmit("pdf")}>
            {"PDF"}
          </Button>
        )
      ]}>

        {errorMessages && 
          <Alert
            message={errorMessages}
            type="error"
            showIcon
            style={{marginTop: "12px", marginBottom: "12px", textAlign: "left"}}
          />
        }
      	<Form 
					form={form}
					layout={"vertical"}>
          <Row gutter={16} justify={"start"}>
          
            {data && filters["showDate"] && (
              <Col xl={12} sm={12} xs={24} key={"showDate"}>
                <Form.Item 
                  name={REPORT_FILTER.DATE} 
                  label={"วันที่"}
                  rules={[{ required: true }]}>
                  <DatePicker picker={"date"} style={{ width: '100%' }} inputReadOnly={true}/>
                </Form.Item>
              </Col>
            )}

            {data && filters["showDateRange"] && (
              <Col xl={24} sm={24} xs={24} key={"showDateRange"}>
                <Form.Item 
                  name={REPORT_FILTER.DATE_RANGE} 
                  label={"ช่วงวันที่"}
                  rules={[{ required: true }]}>
                    <DatePicker.RangePicker picker={"date"} style={{ width: '100%' }} inputReadOnly={true} />
                </Form.Item>
              </Col>
            )}

            {data && filters["showProduct"] && (
							<Col xl={24} sm={24} xs={24} key={"showProduct"}>
								<Form.Item 
									name={REPORT_FILTER.PRODUCT} 
									label={"สินค้า"}>
									<Select 
                    showSearch
                    initialvalues={""}
                    labelInValue 
                    allowClear={true} 
                    autoComplete="off" 
                    options={[{ value: '', label: "ทั้งหมด" }, ...productOptions]}
                    filterOption={filterOption}
                    />
								</Form.Item>
							</Col>
						)}

            {data && filters["showProductGrade"] && (
							<Col xl={24} sm={24} xs={24} key={"showProductGrade"}>
								<Form.Item 
									name={REPORT_FILTER.PRODUCT_GRADE} 
									label={"คุณภาพสินค้า"}>
									<Select 
                    showSearch
                    initialvalues={""}
                    labelInValue 
                    allowClear={true} 
                    autoComplete="off" 
                    options={productGradeOptions}
                    filterOption={filterOption}
                    />
								</Form.Item>
							</Col>
						)}
          
            {data && filters["showCustomer"] && (
							<Col xl={24} sm={24} xs={24} key={"showCustomer"}>
								<Form.Item 
									name={REPORT_FILTER.CUSTOMER} 
									label={"ลูกค้า"}>
									<Select 
                    initialvalues={""}
                    autoComplete="off" 
                    showSearch
                    labelInValue
                    filterOption={false}
                    allowClear={true}
                    options={[{ value: '', label: "ทั้งหมด" }, ...customerOptions]}
                    loading={customerFetching}
                    notFoundContent={customerFetching ? <Spin size="small" /> : "-"}
                    onSearch={debounceCustomers}/>
								</Form.Item>
							</Col>
						)}

          </Row>
        </Form>
    </Modal>
  )
}