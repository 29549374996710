import React from 'react'
import propTypes from "prop-types";
import { NavBar } from 'antd-mobile'
import { useNavigate } from 'react-router-dom';
import {  PRIMARY_LIGHT_COLOR } from '../../constants/color';
import "./../../styles.css"

export default function HeaderMobile(props) {
  const navigate = useNavigate();

    return (
      <NavBar
        style={{ 
          background: PRIMARY_LIGHT_COLOR,
          fontWeight: "bold" }}
        back={props.back}
        onBack={() => props.onClick ? props.onClick() : navigate(-1)}>
          {props.title}
      </NavBar>
    )
  }
  
  HeaderMobile.defaultProps = {
    title: null,
    back: null, //ปุ่มกลับ
    onClick: null
  }
  
  HeaderMobile.propTypes = {
    title: propTypes.string,
    back: propTypes.bool,
    onClick: propTypes.func,
  }
  