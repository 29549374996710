import React from "react";
import { SECONDARY_COLOR } from "../constants/color";
import { 
  PlusCircleOutlined, 
  CloseCircleOutlined, 
  MinusCircleOutlined, 
  CheckCircleOutlined, 
  SearchOutlined, 
  ExclamationCircleOutlined,
  FilePdfOutlined,
  FileExcelOutlined
} from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, Row, Select, Space, theme, Popover } from "antd";
import { DATE_FORMAT, HUMAN_DATE_FORMAT, TICKET_STATUS, HUMAN_DATETIME_FORMAT, TICKET_STATUS_OPTIONS, TICKET_DIRECTION_NAME, TICKET_STATUS_NAME, DATETIME_FORMAT } from "../constants/strings";
import { useNavigate } from "react-router-dom";
import { PATH } from "../routes/CustomRoutes";
import { URL_PRODUCT, URL_WEIGHT } from "../constants/urls";
import { currencyFormat } from "../frameworks/Util";
import TSTable from "../components/common/TSTable";
import ColorButton from "../components/common/ColorButton";
import dayjs from 'dayjs';
import moment from "moment";
import * as _ from 'lodash';
import useDropdownItem from "../hooks/useDropdownItem";

export default function TicketListView() {
  const navigate = useNavigate();
  const [dateFilter, setDateFilter] = React.useState(null);
  const [statusFilter, setStatusFilter] = React.useState(TICKET_STATUS.UN_FINISHED);
  const [search, setSearch] = React.useState(null);
  const tableRef = React.useRef(null);
  const MINUTE_MS = 60000;
  const [productOptions, fetcthProductOptions] = useDropdownItem({
    url: URL_PRODUCT.PRODUCT,
    manual: true, pagination: true,
    key: 'name', value: 'name', label: 'name'
  });

  const {
		token: { colorPrimary, colorError, colorSuccess, colorWarning },
	} = theme.useToken();

  /** Disable after date */
  const disabledDate = (current) => {
    // Can not select days after today
    return current && current > dayjs().endOf('day');
  };

  const renderIconStatus = (status)  => {
    return (
      <Popover content={TICKET_STATUS_NAME[status]}> 
      {
        status === TICKET_STATUS.APPROVED ? <CheckCircleOutlined style={{ color : colorSuccess}}/> :
        status === TICKET_STATUS.PROGRESSING ? <MinusCircleOutlined style={{ color : '#AAAAAA'}}/> :
        status === TICKET_STATUS.PENDING ? <ExclamationCircleOutlined style={{ color : colorWarning}}/> : 
        status === TICKET_STATUS.CHECKING ? <ExclamationCircleOutlined style={{ color : colorWarning}}/> :
        <CloseCircleOutlined style={{ color : colorError}}/> 
      }
      </Popover>
    )
  }

  const renderTextStatus = status => {
    return (
      status === TICKET_STATUS.APPROVED ? "Yes" :
        status === TICKET_STATUS.PROGRESSING ? "No" :
          status === TICKET_STATUS.PENDING ? "No" :
            status === TICKET_STATUS.CHECKING ? "No" :
            "Error"
    )
  }

  const columns = [
    {
      title: "วันที่ - เวลา",
      dataIndex: "created",
      searcher: true,
      dateSearcher: true,
      render: (value) => value ? moment(value).format(HUMAN_DATETIME_FORMAT) : "ไม่ระบุเวลา"
    },
    {
      title: "ตั๋วชั่ง",
      dataIndex: "code",
      searcher: true,
    },
    {
      title: "ทะเบียนรถ",
      dataIndex: "vehicle_license_plate",
      searcher: true,
    },
    {
      title: "ลูกค้า",
      dataIndex: "customer_name",
      searcher: true,
      width: 200,
    },
    {
      title: "สินค้า",
      dataIndex: "product_name",
      searcher: true,
      searcherOptions: productOptions,
    },
    {
      title: "ประเภท",
      dataIndex: "direction",
      searcher: true,
      searcherOptions: [
        { key: 'all', value: null, label: 'all' },
        { key: 'import', value: 'import', label: 'ชั่งซื้อ' },
        { key: 'export', value: 'export', label: 'ชั่งขาย' },
      ],
      render: (value) => (value ? TICKET_DIRECTION_NAME[value] : "")
    },
    {
      title: "น้ำหนักสุทธิ",
      dataIndex: "net_weight",
      render: (value) => (currencyFormat(value))
    },
    {
      title: "ชั่งออก",
      dataIndex: "status", 
      render : (value) => {
        return (
          <div style={{ textAlign : 'center', fontSize : 20 }}>
            {renderIconStatus(value)}
          </div>)
      },
      renderReport: value =>renderTextStatus(value)
    }
  ]

  /** Refresh table */
  const fetchData = () => {
    if (tableRef.current) {
      tableRef.current.fetch();
    }
  }

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (tableRef.current && tableRef.current.getCurrentPage() === 1) {      
        fetchData();
      }
    }, MINUTE_MS);

    fetcthProductOptions()
    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [])

  React.useEffect(() => {
    fetchData();
  }, [dateFilter, statusFilter]);

  return(
    <div>
      <Form
        autoComplete="off">
        <Space style={{ float: 'right' }}>
          <Row gutter={8}>
            <Col>
              <Button
                icon={<FilePdfOutlined />}
                onClick={async () => {
                  await tableRef.current.exportPDF({
                    title: "รายการบัตรชั่ง (ตาชั่งใหญ่)",
                    subtitle: `ดึงข้อมูล ณ​ วันที่ ${dayjs().format(HUMAN_DATETIME_FORMAT)}`,
                    landscape: true
                  })
                }}>
                PDF
              </Button>
            </Col>
            <Col>
              <Button
                icon={<FileExcelOutlined />}
                onClick={async () => {
                  await tableRef.current.exportXlsx({
                    title: "รายการบัตรชั่ง (ตาชั่งใหญ่)",
                    subtitle: `ดึงข้อมูล ณ​ วันที่ ${dayjs().format(HUMAN_DATETIME_FORMAT)}`,
                    filename: `รายการบัตรชั่ง (ตาชั่งใหญ่) - ${dayjs().format(DATETIME_FORMAT)}.xlsx`
                  })
                }}>
                Excel
              </Button>
            </Col>
            <Col>
              <ColorButton
                type={"primary"}
                override={SECONDARY_COLOR}
                icon={<PlusCircleOutlined />}
                style={{ float: "right", background: colorPrimary, marginBottom: 16 }}
                onClick={() => navigate({ pathname: `${PATH.SCALE}/add/` })}>
                เพิ่มตั๋วชั่ง
              </ColorButton>
            </Col>
          </Row>
        </Space>

        <Row gutter={[16, 16]} wrap>
          <Col lg={6} md={12} xs={24}>
            <Form.Item label={"วันที่"}>
              <DatePicker 
                format={HUMAN_DATE_FORMAT} 
                disabledDate={disabledDate}
                onChange={(value) => setDateFilter(value)} 
                placeholder='เลือกวันที่' 
                style={{ width: '100%' }} />  
            </Form.Item>
          </Col>
          <Col lg={6} md={6} xs={24}>
            <Form.Item label={"สถานะ"}>
              <Select 
                onChange={value => setStatusFilter(value)}
                value={statusFilter}
                options={[
                  {key: "", value: "", label: "ทั้งหมด"},
                  ...TICKET_STATUS_OPTIONS,
                ]}/>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <TSTable
        ref={tableRef}
        columns={columns}
        rowKey={"id"}
        size={"small"}
        autoFetch={false}
        url={URL_WEIGHT.WEIGHT_TICKET}
        pageSize={10}
        params={{ 
          created_date: dateFilter ? dateFilter.format(DATE_FORMAT) : "", 
          status: statusFilter ? statusFilter : null,
          search }}
        onRow={(record, rowIndex) => ({
          onClick: _ => navigate(`${PATH.SCALE}/${record.id}/`)
        })}
        rowClassName={(rowData) => {
          if (rowData.status === TICKET_STATUS.CHECKING) {
            return 'table-row-red'
          }
          else if (rowData.status === TICKET_STATUS.PENDING) {
            return 'table-row-yellow'
          }
        }}
      />
    </div>
  )
}