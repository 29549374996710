import React from "react";
import { Alert, Button, Col, Divider, Form, Input, Layout, message, Modal, Row, Select, Space, Spin, theme, notification } from "antd";
import {
  InfoCircleOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Content } from "antd/es/layout/layout";
import { useNavigate, useParams } from "react-router-dom";
import CustomScaleCard from "../components/common/CustomScaleCard";
import HeaderScreen from "../components/common/HeaderScreen";
import { PATH } from "../routes/CustomRoutes";
import ColorButton from "../components/common/ColorButton";
import { TICKET_DIRECTION, TICKET_DIRECTION_NAME, TICKET_STATUS, WEIGHT_UNIT } from "../constants/strings";
import { GET, PATCH, POST } from "../frameworks/HttpClient";
import { URL_PRODUCT, URL_REPORT, URL_SUPPLIER, URL_WEIGHT, THIN_CLIENT_BASE_URL, URL_THIN_CLIENT } from "../constants/urls";
import _ from "lodash";
import VehicleModal from "../components/vehicle/VehicleModal";
import { useUserContext } from "../hooks/UserSettingProvider";
import { PRIMARY_LIGHT_COLOR } from "../constants/color";
import { currencyFormat } from "../frameworks/Util";
import TextArea from "antd/es/input/TextArea";

export default function ScaleScreen () {
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const { id } = useParams();
  const [form] = Form.useForm();
  const { user } = useUserContext();
  const params = useParams();
  const isCreate = id === 'add';
  const inWeightRef = React.useRef();
  const outWeightRef = React.useRef();
  const [loading, setLoading] = React.useState(null); 
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [fetching, setFetching] = React.useState(null);
  const [openWarningModal, setOpenWarningModal] = React.useState(false)
  const [disableSI, setDisableSI] = React.useState(false);
  const [openPrintConfirmModal, setOpenPrintConfirmModal] = React.useState(false)

  // Options
  const [products, setProducts] = React.useState([]);
  const [vehicles, setVehicles] = React.useState([]);
  const [suppliers, setSuppliers] = React.useState([]);
  const [dataSI, setDataSI] = React.useState([]);
  const [debouncedVehicle, setDebouncedVehicle] = React.useState('');
  const [debouncedSupplier, setDebouncedSupplier] = React.useState('');
  const [ticketData, setTicketdata] = React.useState([]);
  const [openVehicleModal, setOpenVehicleModal] = React.useState(false);
  const [isHaveInWeight, setIsHaveInWeight] = React.useState(false);
  const [isDisable, setIsDisable ] = React.useState(false);
  const [disableWeight, setDisableWeight] = React.useState(false);
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const debounceVehicle = React.useCallback(_.debounce((_searchVal) => { setDebouncedVehicle(_searchVal) }, 800), []);
  const debounceSupplier = React.useCallback(_.debounce((_searchVal) => { setDebouncedSupplier(_searchVal) }, 800), []);

  const {
		token: { colorBgContainer, colorInfo, colorSuccess, colorWarning, colorError },
	} = theme.useToken();

  /** Fetch ticket detail */
  const fetchTicketData = async() => {
    setLoading(true)
    try {
      const response = await GET(`${URL_WEIGHT.WEIGHT_TICKET}${id}/`)
      if(response.data.si_ref){
        setIsDisable(true);
      }
      setTicketdata(response.data)
      form.setFieldsValue({...response.data, 
        si_ref : {label : response.data.si_code, value : response.data.si_ref},
        vehicle : {label : response.data.vehicle_license_plate, value : response.data.vehicle},
        customer: {label: response.data.customer_name, value: response.data.customer}
      })
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setLoading(false)
    }
  }

  const handleRegister = async() => {
    setErrorMessages(null);
    setLoading(true);
    try {
      let data = await form.validateFields();
      data['factory'] = user.factory
      let ticketId  = null; 
      let inWeight = null;
      let outWeight = null;

      if(inWeightRef){
        inWeight = await inWeightRef.current.getData();
        inWeight['direction'] = "in_weight"
      }

      if(outWeightRef){
        outWeight = await outWeightRef.current.getData();
        outWeight['direction'] = "out_weight"
      }

      if(data.si_ref){
        data['si_ref'] = data.si_ref.value
      } else {
        data['si_ref'] = data.si_ref
      }
      
      if(isCreate){
        if(inWeight.weight){
          const response = await POST(URL_WEIGHT.WEIGHT_TICKET, data);
          ticketId = response.data.id
          
          if(inWeight.weight){
            await handleScaleWeight(inWeight, ticketId)
          }
          navigate(PATH.TICKETS)
        } else {
          setErrorMessages("กรุณาชั่งน้ำหนัก")
        }
      } else {
        // Prepare data
        if (data.vehicle.value) {
          data['vehicle'] = data.vehicle.value
        }
        if (data.customer.value) {
          data["customer"] = data.customer.value
        }

        const response = await PATCH(`${URL_WEIGHT.WEIGHT_TICKET}${id}/`, data);
        ticketId = response.data.id
          if(inWeight.hasEdit){
            await handleScaleWeight(inWeight)
          }
          if(outWeight.hasEdit){
            await handleScaleWeight(outWeight);
          } 
          fetchTicketData();
          message.success('บันทึกข้อมูลสำเร็จแล้ว')
        }
      } catch (error) {
        setErrorMessages(error.errorMessages)
      } finally {
        setLoading(false);
        fetchDataSI()
      }
      
  };

  const handleScaleWeight = async(data, ticketId) => {
    if (errorMessages) 
      setErrorMessages(null);

    try {
      data = {...data, ticket : ticketId ? ticketId : id, unit : WEIGHT_UNIT.KILOGRAM}
      await POST(URL_WEIGHT.TICKET_WEIGHTING, data)
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
  }

  /** Fetch Options: vehicle */
  const fetchVehicle = async(vehicleId) => {
    setFetching(true);
    if (errorMessages)
      setErrorMessages(null);

    try {
      const params = {}

      if(debouncedVehicle) {
        params['search'] = debouncedVehicle
      }
      if(vehicleId) {
        params['id'] = vehicleId
      }
      const response = await GET(`${URL_WEIGHT.VEHICLE}`, params)
      setVehicles(response.data.results.map(item => {
        return({...item, label : item.license_plate, value : item.id})
      }))
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setFetching(false);
    }  
  }

  /** Fetch Options: product */
  const fetchProductOptions = async() => {
    if (errorMessages)
      setErrorMessages(null);

    try {
      const response = await GET(URL_PRODUCT.PRODUCT, {page_size: 999})
      setProducts(response.data.results.map(item => {
        return (
          {...item,
            label : item.name,
            value : item.id
          }
        )}))
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
  }

  /** Fetch Options: supplier */
  const fetchSupplier = async(customerId) => {
    setFetching(true);
    if (errorMessages)
      setErrorMessages(null);

    try {
      const params = {}

      if (debouncedSupplier) {
        params['search'] = debouncedSupplier
      }
      if (customerId) {
        params['id'] = customerId
      }

      const response = await GET(`${URL_SUPPLIER.SUPPLIER}`, params)
      setSuppliers(response.data.results.map(item => {
        return(
          {...item,
            label : item.name,
            value : item.id
          }
        )}))
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setFetching(false);
    }
  }

  const fetchDataSI = async() => {
    try {
      const response = await GET(URL_WEIGHT.SHIPPING_INSTRUCTION, { has_ticket : false, page_size : 999, product : selectedProduct ? selectedProduct : ''})
      setDataSI(response.data.results.map(item => {
        return(
          {...item,
            label : item.code,
            value : item.id
          }
        )}))
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setFetching(false);
    }
  }

  const handlePrint = async(isPdf) => {
    setLoading(true)
    try {
      // Fetch the PDF file
      const response = await GET(`${URL_REPORT.WEIGHT_TICKET_REPORT}${params.id}/`, {}, false, true);      
      if (isPdf) {  // Open new windows for PDF
        window.open(URL.createObjectURL(response.data), '_blank');
      }
      else {  // Print to printer with thinclient
        const data = {
          'files': response.data
        }
        try {
          const url = `${THIN_CLIENT_BASE_URL}${URL_THIN_CLIENT.PRINT}` 
          await POST(url, data, true);
        } catch(thinClientError) {
          console.log(thinClientError)
          api.error({
            message: 'ThinClient communication error',
            description: 'เกิดข้อผิดพลาดในการเชื่อมต่อกับ ThinClient',
            placement: "bottomRight"
          })
        }
      }
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setLoading(false)
    }
};

  /** Filter options */
  const filterOption = (input, option) => {
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  }

  React.useEffect(() => {
    if (debouncedSupplier)
      fetchSupplier();
  }, [debouncedSupplier])

  React.useEffect(() => {
    if (debouncedVehicle)
      fetchVehicle();
  }, [debouncedVehicle])

  React.useEffect(() => {
    if(ticketData){
      if(ticketData.status === TICKET_STATUS.PENDING){
        setOpenWarningModal(true);
        setDisableWeight(true);
        setIsDisable(true);
      }

      if(ticketData.in_weight > 0){
        setIsHaveInWeight(true);
      }

      if(ticketData.direction === TICKET_DIRECTION.IMPORT){
        setDisableSI(true);
      }
      
      if(ticketData.status === TICKET_STATUS.APPROVED || ticketData.status === TICKET_STATUS.REJECTED){
        setDisableSI(true);
        setIsDisable(true);
      }

      if (ticketData.status === TICKET_STATUS.REJECTED) {
        setErrorMessages("ปฎิเสธการรับสินค้า")
      }
    } 
  },[ticketData])

  React.useEffect(() => {
    if (!isCreate) {
      fetchTicketData();
    }

    fetchProductOptions();
    fetchVehicle();
    fetchSupplier();
    fetchDataSI();
  },[])

  React.useEffect(() => {
    fetchDataSI();
  },[selectedProduct])

  return (
    <Layout style={{ minHeight: "100vh", backgroundColor: PRIMARY_LIGHT_COLOR }}>
      <HeaderScreen 
        onClick={() => navigate(PATH.TICKETS)} 
        title={(isCreate) ? "สร้างตั๋วชั่ง" : `ตั๋วชั่ง [${ticketData.code ? ticketData.code : ticketData.customer_name}]`} />
      <Spin spinning={loading}>
      <Content 
        className="main-content"
        style={{ 
        margin: "0px 24px 24px 24px", 
        padding: "16px 24px",
        textAlign: "left",  
        minHeight: 280,
        background: colorBgContainer }}>

      {errorMessages && 
        <Alert
          message={errorMessages}
          type="error"
          showIcon
          style={{textAlign: "left"}}/>
      }

      {ticketData && ticketData.status === TICKET_STATUS.CHECKING &&
      <Alert
        message="ตรวจสอบน้ำหนัก"
        icon={<InfoCircleOutlined />}
        description={ticketData.latest_transaction_note}
        type="warning"
        showIcon
      />}

      <Form
        form={form}
        layout="vertical"
        autoComplete="off">
        <Row gutter={[16]} style={{ marginTop: 16 }}>

          <Col md={10} sm={24} xs={24}>
            <Form.Item  
              name={"product"}
              label="สินค้า"
              rules={[{ required: true }]}>
              <Select 
                placeholder={"เลือกสินค้า"}
                showSearch
                autoComplete="off" 
                filterOption={filterOption}
                onSelect={(value,data) => {
                  setSelectedProduct(value);
                }}
                onClear={() => {
                  setSelectedProduct(null);
                }}
                options={products}
                allowClear={"-"}
                disabled={isDisable}/>
            </Form.Item>
          </Col>

          <Col md={5} sm={24} xs={24}>
            <Form.Item  
              name={"si_ref"}
              label="SI">
                <Select 
                  labelInValue
                  showSearch
                  placeholder={"เลือก SI"}
                  style={{width: "100%"}}
                  autoComplete="off" 
                  onSelect={(_,data) => {
                    form.setFieldsValue(data)
                    setSelectedProduct(data.product)
                    fetchVehicle(data.vehicle)
                    fetchSupplier(data.customer)
                    setIsDisable(true)
                  }}
                  onClear={() => {
                    setIsDisable(false)
                    form.resetFields();
                    fetchDataSI()
                  }}
                  disabled={(disableSI )}
                  filterOption={filterOption}
                  options={dataSI}
                  allowClear={"-"}
                />
            </Form.Item>
          </Col>

          <Col md={9} sm={24} xs={24}>
            <Form.Item label="ทะเบียนรถ">
              <Space.Compact 
                style={{ width: '100%' }}
                >
                <Form.Item 
                  style={{width: "55%"}}
                  name={'vehicle'}
                  rules={[{ required: true }]}>
                  <Select
                    disabled={isDisable}
                    showSearch
                    style={{width: "100%"}}
                    placeholder={"เลือกทะเบียนรถ"}
                    autoComplete="off" 
                    filterOption={false}
                    notFoundContent={fetching ? <Spin size="small" /> : "-"}
                    onSearch={debounceVehicle}
                    onSelect={(_,data) => form.setFieldValue('vehicle_type',data.vehicle_type_name)}
                    allowClear={"-"}
                    options={vehicles}
                    onClear={() => {
                      form.setFieldValue('vehicle_type', null)
                    }}/>
                </Form.Item>

                <Form.Item name={'vehicle_type'} style={{width: "35%"}}>
                  <Input style={{width: "100%"}} readOnly placeholder="ประเภทรถ" disabled={isDisable}/>
                </Form.Item>

                <Button 
                  style={{width: "10%"}}
                  type={"primary"}
                  icon={<PlusOutlined/>} 
                  onClick={() => setOpenVehicleModal(true)}
                  disabled={isDisable}>
                </Button>
              </Space.Compact>
            </Form.Item>
          </Col>

          <Col md={10} sm={24} xs={24}>
            <Form.Item  
              name={"customer"}
              label="ชื่อลูกค้า"
              rules={[{ required: true }]}>
              <Select 
                showSearch
                placeholder={"เลือกลูกค้า"}
                autoComplete="off" 
                filterOption={false}
                notFoundContent={fetching ? <Spin size="small" /> : "-"}
                onSearch={debounceSupplier}
                onSelect={(_,data) => form.setFieldValue('customer_address',`${data.address} ${data.sub_district_detail}`)}
                options={suppliers}
                onClear={() => form.setFieldValue('customer_address', null)}
                allowClear={"-"}
                disabled={isDisable}/>
            </Form.Item>
          </Col>

          <Col md={14} sm={24} xs={24}>
            <Form.Item  
              name={"customer_address"}
              label="ที่อยู่">
                <Input autoComplete="off" readOnly disabled={isDisable} />
            </Form.Item>
          </Col>
          <Col md={24} sm={24} xs={24}>
            <Form.Item  
              name={"note"}
              label="หมายเหตุ">
                <TextArea autoComplete="off" disabled={disableWeight} />
            </Form.Item>
          </Col>

          <Col md={12} sm={24} xs={24}>
            <Form.Item>
              <CustomScaleCard
                title="ชั่งเข้า"
                ref={inWeightRef}
                showImages={true}
                showExtraButton={(isCreate || (ticketData.status !== TICKET_STATUS.APPROVED && ticketData.status !== TICKET_STATUS.REJECTED))}
                initialData={(ticketData && ticketData.latest_in_weighting_ref) ? ticketData.latest_in_weighting_ref : null}
                disabled={disableWeight}
              />
            </Form.Item>
          </Col>

          <Col md={12} sm={24} xs={24}>
            <Form.Item>
              <CustomScaleCard
                title="ชั่งออก"
                ref={outWeightRef}
                showImages={true}
                showExtraButton={isHaveInWeight && (isCreate || (ticketData.status !== TICKET_STATUS.APPROVED && ticketData.status !== TICKET_STATUS.REJECTED))}
                initialData={(ticketData && ticketData.latest_out_weighting_ref) ? ticketData.latest_out_weighting_ref : null}
                disabled={disableWeight}
              />
            </Form.Item>
          </Col>
        </Row>

        {!isCreate && ticketData.status !== TICKET_STATUS.PROGRESSING && (
          <div>
            <Divider>{`สรุปการชั่ง (${TICKET_DIRECTION_NAME[ticketData.direction]})`}</Divider>
            <Form.Item style={{ textAlign: "center"}}>
              <Input 
                readOnly
                style={{ width: "auto", textAlign: "right"}} 
                addonBefore="น้ำหนักสุทธิ" 
                addonAfter="กก."
                value={currencyFormat(ticketData ? ticketData.net_weight : 0)} />
            </Form.Item>
            {ticketData.total_price > 0 && 
                  <Form.Item style={{ textAlign: "center" }}>
                    <Input
                      readOnly
                      style={{ width: "auto", textAlign: "right" }}
                      addonBefore="ราคาสุทธิ"
                      addonAfter="บาท"
                      value={currencyFormat(ticketData ? ticketData.total_price : 0)} />
                  </Form.Item>
            }
          </div>
        )}
        
        <div className="button-bottom-page">
          <Row gutter={[16]}>
            {!isCreate && ticketData.status !== TICKET_STATUS.PROGRESSING &&
              <Col>
                <ColorButton
                  loading={loading}           
                  type={"primary"} 
                  override={colorInfo}
                  style={{ width: '100px' }}
                  icon={null}
                  onClick={() => setOpenPrintConfirmModal(true)}>
                    {"พิมพ์"}
                </ColorButton>
              </Col>
            }

          {(isCreate || (ticketData.status !== TICKET_STATUS.APPROVED && ticketData.status !== TICKET_STATUS.REJECTED)) &&
            <Col>
              <ColorButton
                loading={loading}           
                type={"primary"} 
                override={colorSuccess}
                style={{ width: '100px' }}
                icon={null}
                disabled={disableWeight}
                onClick={() => handleRegister()}>
                  {"บันทึก"}
              </ColorButton>
            </Col>
          }
          </Row>
        </div>
      </Form>
    </Content>
    </Spin>

    <Modal
      open={openPrintConfirmModal}
      title='พิมพ์ตั๋วชั่ง'
      icon={<InfoCircleOutlined style={{ color: colorInfo }} />}
      maskClosable={true}
      onCancel={() => setOpenPrintConfirmModal(false)}
      footer={[
        <Button key="cancel" onClick={() => setOpenPrintConfirmModal(false)}>
          ยกเลิก
        </Button>,
        <Button danger type="primary" loading={loading} onClick={() => handlePrint(true)}>
          PDF
        </Button>,
        // <Button color={colorInfo} type="primary" loading={loading} onClick={() => handlePrint(false)}>
        //   Printer
        // </Button>
      ]}
    >
        {contextHolder}
        คุณต้องการพิมพ์ตั๋วชั่งใช่หรือไม่ ?
    </Modal>

      <Modal
        title={<div><ExclamationCircleOutlined style={{ color : colorWarning, marginRight: 8}}/>{"แจ้งเตือน"}</div>}
        open={openWarningModal}
        closeIcon={false}
        maskClosable={false}
        okButtonProps={{style: {background : colorInfo}}}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={() => setOpenWarningModal(false)}
        okText={'รับทราบ'}>
          <div>
          <div>{`ตั๋วชั่ง ${ticketData.code} กำลังรอตรวจสอบจาก Manager`}</div>
            <ul> 
              {((ticketData.snapshot_product_diff != 0) && (ticketData.snapshot_product_diff > 0)) && (
                <li>
                  {`น้ำหนักตาชั่งใหญ่ และ ตาชั่งเล็กต่างกัน ${ticketData.product_diff} กิโลกรัม `}
                  <span style={{color: colorError, fontWeight: "bold"}}>{`(${ticketData.snapshot_product_diff_percent}%)`}</span>
                </li>
              )}
              {((ticketData.goal_diff != 0) && (ticketData.goal_diff > 0)) && (
                <li>
                  {`น้ำหนักตาชั่งใหญ่ และ SI ต่างกัน ${ticketData.goal_diff} กิโลกรัม `}
                  <span style={{color: colorError, fontWeight: "bold"}}>{`(${ticketData.goal_diff_percent}%)`}</span>
                </li>
              )}
              {((ticketData.pallet_diff != 0) && (ticketData.pallet_diff > 0)) && (
                <li>
                  {`น้ำหนักชั่งพาเลทรวมต่างกัน ${ticketData.pallet_diff} กิโลกรัม `}
                  <span style={{color: colorError, fontWeight: "bold"}}>{`(${ticketData.pallet_diff_percent}%)`}</span>
                </li>
              )}
            </ul>
          </div>
      </Modal>

      <VehicleModal
        open={openVehicleModal}
        onClose={() => setOpenVehicleModal(false)}
        onUpdate={(data) => {
          setOpenVehicleModal(false)
          if (data) {
            setVehicles([{...data, value: data.id, label: data.license_plate}])
            form.setFieldValue('vehicle', data.id)
            form.setFieldValue('vehicle_type', data.vehicle_type_name)
          }
        }}/>
    </Layout>
  )
}