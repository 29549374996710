import React from 'react'
import propTypes from 'prop-types'
import { Row, Modal, Form, theme, Col, Input, Alert } from 'antd'
import CustomScaleCard from '../common/CustomScaleCard'
import { POST } from '../../frameworks/HttpClient'
import { URL_PALLET } from '../../constants/urls'
import { useUserContext } from '../../hooks/UserSettingProvider'
import { WEIGHT_UNIT } from '../../constants/strings'

export default function PalletCreateModal(props) {
  const [loading, setLoading] = React.useState(false)
  const [errorMessages, setErrorMessages] = React.useState(null)
  const scaleRef = React.useRef()
  const [form] = Form.useForm()
  const { user } = useUserContext()

  const {
    token: { colorWarning, colorPrimary },
  } = theme.useToken()

  /** Create or Update pallet */
  const handleOK = async () => {
    setErrorMessages(null)
    setLoading(true)
    let weightData = null;

    try {
      let data = await form.validateFields()
      if (user) data['factory'] = user.factory

      if (scaleRef) {
        weightData = await scaleRef.current.getData();
        weightData["unit"] = WEIGHT_UNIT.KILOGRAM
      }
      
      if (weightData && weightData.hasEdit) {
        if (props.target) {
          weightData['pallet'] = props.target.id
        } else {
          // Create new pallet
          const response = await POST(URL_PALLET.PALLET, data)
          weightData['pallet'] = response.data.id
        }
        // Add weighting of pallet (new transaction)
        await POST(URL_PALLET.PALLET_WEIGHTING, weightData)
        props.onUpdate()
      } else {
        setErrorMessages('กรุณาชั่งน้ำหนัก')
      }
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    if (props.open) {
      if (props.target) {
        form.setFieldsValue(props.target)
      }
    } else {
      form.resetFields();
      scaleRef && scaleRef.current && scaleRef.current.resetFields();
      setErrorMessages(null);
    }
  }, [props.open])

  return (
    <Modal
      title={props.target ? 'ชั่งน้ำหนักพาเลทเปล่า' : 'เพิ่มพาเลท'}
      open={props.open}
      maskClosable={false}
      onClose={props.onClose}
      okText={props.target ? 'แก้ไข' : 'เพิ่ม'}
      onOk={handleOK}
      okButtonProps={{
        style: { background: props.target ? colorWarning : colorPrimary },
        loading: loading,
      }}
      cancelText={'ยกเลิก'}
      onCancel={props.onClose}
      width={600}
    >
      {errorMessages && (
        <Alert
          message={errorMessages}
          type='error'
          showIcon
          style={{ marginBottom: '12px', textAlign: 'left' }}
        />
      )}
      <Form layout='vertical' form={form}>
        <Row gutter={[16, 16]} wrap>
          <Col md={12} sm={24} xs={24}>
            <Form.Item
              label={'ชื่อพาเลท'}
              name='code'
              rules={[{ required: true, message: 'โปรดกรอกข้อความ' }]}
            >
              <Input disabled={props.target} />
            </Form.Item>
          </Col>

          <Col md={12} sm={24} xs={24}>
            <Form.Item label='น้ำหนักพาเลท (Kgs)' name='base_weight'>
              <Input disabled={true} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <CustomScaleCard
        ref={scaleRef}
        initialData={(props.target && props.target.latest_weighting_ref) 
          ? props.target.latest_weighting_ref : null}
        title={'ชั่งพาเลท'}
        onClickScaleButton={(e) => form.setFieldValue('base_weight', e)}
        showImages={true}
        scalePallet={true}
      />
    </Modal>
  )
}

PalletCreateModal.defaultProps = {
  open: false,
  target: null,
  onClose: () => {},
  onUpdate: () => {},
}

PalletCreateModal.prototype = {
  open: propTypes.bool,
  target: propTypes.object,
  onClose: propTypes.func,
  onUpdate: propTypes.func,
}
