import React from "react";
import { Form, Row, Col, theme, Input, Card, Button, Image, message, Layout, Divider, Alert, Select, Spin, Space } from 'antd'
import { MapContainer, TileLayer, FeatureGroup, Marker, LayersControl, LayerGroup } from "react-leaflet";
import { useNavigate } from "react-router-dom";
import { EditControl } from 'react-leaflet-draw';
import { UploadOutlined, SearchOutlined, PlusOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { PATH } from "../routes/CustomRoutes";
import HeaderScreen from "../components/common/HeaderScreen";
import { useRef } from 'react';
import _ from "lodash";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css'
import 'leaflet-geosearch/dist/geosearch.css';
import ImgCrop from 'antd-img-crop';
import ImageEditorModal from "../components/common/ImageEditorModal";
import { PRIMARY_LIGHT_COLOR } from "../constants/color";
import UploadFile from "../components/common/UploadFile";
import ColorButton from "../components/common/ColorButton";
import { Content } from "antd/es/layout/layout";
import DeleteSupplierModal from "../components/supplier/DeleteSupplierModal";
import OwnerDetailModal from "../components/supplier/OwnerDetailModal";
import { DELETE, GET, PATCH, POST } from "../frameworks/HttpClient";
import { URL_COMMON, URL_SUPPLIER } from "../constants/urls";
import { useUserContext } from "../hooks/UserSettingProvider";
import { DOCUMENT_TYPE, USER_GROUP } from "../constants/strings";
import imgDefault from "../assets/gallery.png";
import userDefault from "../assets/user.png";
import OwnerSearchModal from "../components/supplier/OwnerSearchModal";

delete L.Icon.Default.prototype._getIconUrl;

export default function SupplierRegisterScreen(props) {
  const { user } = useUserContext();
  const [form] = Form.useForm();
  const [formOwner] = Form.useForm();
  const params = useParams();
  const [readOnly, setReadOnly] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [loadTileMap, setLoadTileMap] = React.useState(false);
  const [mapKey, setMapKey] = React.useState(Date.now());
  const [center, setCenter] = React.useState([9.095027670375663, 98.95762924382421]);
  const [deleteMode, setDeleteMode] =  React.useState(false);
  const [marker, setMarker] = React.useState(null);
  const [userImageData, setUserImageData] = React.useState(null);
  const [supplierImageData, setSupplierImageData] = React.useState(null);
  const [positions, setPositions] = React.useState(null);
  const [uploadCompanyFile, setUploadCompanyFile] = React.useState(null);
  const [uploadUserFile, setUploadUserFile] = React.useState(null);
  const [inputLat, setInputLat] = React.useState('');
  const [inputLng, setInputLng] = React.useState('');
  const [debouncedCode, setDebouncedCode] = React.useState(null);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const navigate = useNavigate();
  const mapRef = useRef();
  const uploadUserRef = React.useRef(null);
  const uploadCompanyRef = React.useRef(null);
  const [deleteTarget, setDeleteTarget] = React.useState(null);
  // Handel Owner
  const [openOwnerSearch, setOwnerSearch] = React.useState(false);
  const [openOwnerDetail, setOwnerDetail] = React.useState(false);
  const [ownerTarget, setOwnerTarget] = React.useState(null);
  const [owner, setOwner] = React.useState(null);
  // Handle Options
  const [typeOptions, setTypeOptions] = React.useState([]);
  const [provinceOptions, setProvinceOptions] = React.useState([]);
  const [districtOptions, setDistrictOptions] = React.useState([]);
  const [subdistrictOptions, setSubdistrictOptions] = React.useState([]);
  
  const [fetching, setFetching] = React.useState(false);
  const [codeSubdistricts ,setCodeSubdistricts] = React.useState([])
  
  const [selectedCode, setSelectedCode] = React.useState(null);
  const [supplier, setSupplier] = React.useState(null);
  const [supplierImage, setSupplierImage] = React.useState(null);
  const [tradeFile, setTradeFile] = React.useState([]);
  const [factoryFile, setFactoryFile] = React.useState([]);
  const [ecoFile, setEcoFile] = React.useState([]);
  const [licenseFile, setLicenseFile] = React.useState([]);
  const [bookbankFile, setBookBankFile] = React.useState([]);
  const [deletedFiles, setDeletedFiles] = React.useState([]);
  const tradeRef = React.useRef(null);
  const factoryRef = React.useRef(null);
  const ecoRef = React.useRef(null);
  const licenseRef = React.useRef(null);
  const bookbankRef = React.useRef(null);

  // filter sub_district
  const [selectedProvince, setSelectedProvince] = React.useState(null);
  const [selectedDistrict, setSelectedDistrict] = React.useState(null);
  const [selectedSubdistrict, setSelectedSubdistrict] = React.useState(null);
  const isCreate = (params.id === 'add');

  const debounceCode = React.useCallback(_.debounce((_searchVal) => { setDebouncedCode(_searchVal) }, 800), []);

  const {
		token: { colorInfo, colorBgContainer, colorPrimary, colorError, colorWarning },
	} = theme.useToken();

  const iconMarker = new L.Icon({
    iconUrl: require('../assets/marker-1.png'),
    iconRetinaUrl: require('../assets/marker-1.png'),
    iconAnchor: [13, 40],
    iconSize: [25, 40], 
  });

  const prepareMarker = (e) => {
    let data = { latitude : e.lat, longitude : e.lng }
    setMarker(data)
    setPositions(e)
  }

  const handleDeleteButton = () => {
    if(deleteMode === false){
      setDeleteMode(true)
    }else {
      setDeleteMode(false)
    }
  }

  const handleFlyTo = () => {
    const lat = parseFloat(inputLat);
    const lng = parseFloat(inputLng);

    if (!isNaN(lat) && !isNaN(lng)) {
      mapRef.current.flyTo([lat, lng], 17);
    } else {
      message.error('กรุณากรอก Latitude, Longitude');
    }
  };

  const handleLatLngChange = (e) => {
    const { name, value } = e.target;
    if (name === 'lat') {
      setInputLat(value);
    } else if (name === 'lng') {
      setInputLng(value);
    }
  };

  const handleOK = async() => {
    try {
      let totalFile = [];
      let data = await form.validateFields();
    
      if(user)
        data['factories'] = [user.factory];
      if(owner)
        data['owner'] = owner.id;
      
      if(supplierImageData){
        data['profile_picture'] = supplierImageData
      }
      if(data.sub_district){
        data['sub_district'] = data.sub_district.value
      }
      data['point'] = marker;
      let supplierId = null;

      console.log(data)

      if(isCreate){
        const response = await POST(URL_SUPPLIER.SUPPLIER, data)
        supplierId = response.data.id
        message.success('สร้างสถานประกอบการสำเร็จ')
      } else {
        const response = await PATCH(`${URL_SUPPLIER.SUPPLIER}${params.id}/`, data)
        supplierId = response.data.id
        message.success('แก้ไขข้อมูลสำเร็จ')
      }
      

      if(tradeRef.current){
        let file = tradeRef.current.getData();
        file.map(item => totalFile = [...totalFile, { supplier : supplierId, document_type : DOCUMENT_TYPE.TRADE, document : item.originFileObj }])
      }

      if(factoryRef.current){
        let file = factoryRef.current.getData();
        file.map(item => totalFile = [...totalFile, { supplier : supplierId, document_type : DOCUMENT_TYPE.FACTORY, document : item.originFileObj }])
      }

      if(ecoRef.current){
        let file = ecoRef.current.getData();
        file.map(item => totalFile = [...totalFile, { supplier : supplierId, document_type : DOCUMENT_TYPE.ECO, document : item.originFileObj }])
      }

      if(licenseRef.current){
        let file = licenseRef.current.getData();
        file.map(item => totalFile = [...totalFile, { supplier : supplierId, document_type : DOCUMENT_TYPE.LICENSE, document : item.originFileObj }])
      }

      if(bookbankRef.current){
        let file = bookbankRef.current.getData();
        file.map(item => totalFile = [...totalFile, { supplier : supplierId, document_type : DOCUMENT_TYPE.BOOK_BANK, document : item.originFileObj }])
      }
      handleFile(totalFile)
      if(deletedFiles.length > 0){
        deleteFile();
      }
      
      navigate(`${PATH.SUPPLIERS}`)  

    } catch (error) {
      console.log(error)
      setErrorMessages((error && error.errorMessages) ? error.errorMessages : "Field Error!");
      window.scrollTo(0,0)
    } 
  }
  
  const deleteFile = async() => {
    try {
      deletedFiles.map(id => {
        DELETE(`${URL_SUPPLIER.SUPPLIER_DOCUMENT}${id}/`)
      })
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
  }

  const handleFile = async(files) => {
    try {
      files.map(async(file) => {
        await POST(URL_SUPPLIER.SUPPLIER_DOCUMENT, file, true)
      })
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
  }

  const fetchOwner = async(id) => {
    try {
      const response = await GET(`${URL_SUPPLIER.OWNER}${id}/`)
      setOwner(response.data);
    } catch (error) {      
      setErrorMessages(error.errorMessages);
    }
  }

  /** Filter options */
  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  /** Options type */
  const fetchSupplierType = async () => {
		try {
			const type = await GET(URL_SUPPLIER.SUPPLIER_TYPE, { page_size: 100 });
      const supplierType = type.data.results
			let optionsType = (supplierType.map(item => ({...item, value: item.id, label: item.name })))
      setTypeOptions([...optionsType])
		} catch (error) {
			setErrorMessages(error.errorMessages);
		}
	}

  const fetchCodeSubdistrict = async (id) => {
    setFetching(true);
    try {
      if (id) {
        const response = await GET(`${URL_COMMON.LOCATION_SEARCH}${id}/`)
      } else {
        const response = await GET(`${URL_COMMON.LOCATION_SEARCH}${debouncedCode}`)
        setCodeSubdistricts(response.data.results.map((item) => {
          return {
            ...item,
            label: item.display_name,
            value: item.subdistrict_id,
          }
        }))
    }
    } catch (error) {
      console.log(error.errorMessages)
    } finally {
      setFetching(false);
    }
  }

  const fetchProvinceOptions = async () => {
    try {
      const response = await GET(URL_COMMON.PROVINCE, {page_size: 100})
      setProvinceOptions(response.data.map((item) => {
        return {
          ...item,
          label: item.name_th,
          value: item.id,
        }
      }))
    } catch (error) {
      console.log(error.errorMessages)
    }
  }

  const fetchDistrictOptions = async () => {
    try {
      const response = await GET(`${URL_COMMON.DISTRICT}`, {province : selectedProvince , page_size : 500 })
      setDistrictOptions(response.data.results.map((item) => {
        return {
          ...item,
          label: item.name_th,
          value: item.id,
        }
      }))
    } catch (error) {
      console.log(error.errorMessages)
    }
  }

  const fetchSubdistrictOptions = async () => {
    try {
      const response = await GET(
        URL_COMMON.SUB_DISTRICT, 
        { province : selectedProvince , district: selectedDistrict , page_size : 500 })
      setSubdistrictOptions(response.data.results.map((item) => {
        return {
          ...item,
          label: item.name_th,
          value: item.id,
        }
      }))
    } catch (error) {
      console.log(error.errorMessages)
    } 
  }

  /** Fetch suppier detail */
  const fetchSupplier = async () => {
    try {
      const response = await GET(`${URL_SUPPLIER.SUPPLIER}${params.id}/`)
      let data = response.data
      // Initial Options
      if (data.district) 
        setDistrictOptions([{label: data.district_name, value: data.district}])
      if (data.sub_district) {
        setCodeSubdistricts([{label: data.zipcode, value: data.sub_district}])
        setSubdistrictOptions([{label: data.sub_district_name, value: data.sub_district}])
        form.setFieldValue("subdistrict", data.sub_district)
      }
      
      // Initial Data
      form.setFieldsValue(data);
      setSupplier(data);
      setSupplierImage(data.profile_picture)
      if (data.location) {
        const targetPoint = [data.location.latitude, data.location.longitude]
        setPositions(targetPoint)
        setCenter(targetPoint)
        setMarker(data.location)
      }
      if(data.owner && data.owner_detail){
        setOwner(data.owner_detail)
      }
    } catch (error) {
      setErrorMessages(error.errorMessages);
    }
  }

  const fetchDocument = async () => {
    try {
      const trade = await GET(`${URL_SUPPLIER.SUPPLIER_DOCUMENT}`, {document_type : DOCUMENT_TYPE.TRADE, supplier : params.id})
      setTradeFile(trade.data.results)

      const factory = await GET(`${URL_SUPPLIER.SUPPLIER_DOCUMENT}`, {document_type : DOCUMENT_TYPE.FACTORY, supplier : params.id})
      setFactoryFile(factory.data.results)

      const eco = await GET(`${URL_SUPPLIER.SUPPLIER_DOCUMENT}`, {document_type : DOCUMENT_TYPE.ECO, supplier : params.id})
      setEcoFile(eco.data.results)

      const license = await GET(`${URL_SUPPLIER.SUPPLIER_DOCUMENT}`, {document_type : DOCUMENT_TYPE.LICENSE, supplier : params.id})
      setLicenseFile(license.data.results)

      const bookbank = await GET(`${URL_SUPPLIER.SUPPLIER_DOCUMENT}`, {document_type : DOCUMENT_TYPE.BOOK_BANK, supplier : params.id})
      setBookBankFile(bookbank.data.results)
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
  }

  // Handle close ownerDetail modal
  const closeOwnerDetail = () => {
    ownerTarget ? setOwnerTarget(null) : setOwnerDetail(false);
  }

  React.useEffect(() => {
      if (!isCreate) {
        fetchSupplier();
        fetchDocument();
      }

      fetchSupplierType();
      fetchProvinceOptions();
    }, [])

  React.useEffect(() => {
    if (user) {
      // Set permission to edit or read only
      setReadOnly(user.groups.find(group => group === USER_GROUP.SUPPLIER_MANAGER) ? false : true)
    }
  }, [user])

  React.useEffect(() => {
    console.log(owner)
    if (owner) {
      formOwner.setFieldsValue(owner);
      setUserImageData(owner.profile_picture)
    }
  }, [owner])
    
  React.useEffect(() => {
    if (debouncedCode)
      fetchCodeSubdistrict();
  }, [debouncedCode])

  React.useEffect(() => {
      setMapKey(Date.now());
      setLoadTileMap(true);
  }, [center])

  React.useEffect(() => {
    if(selectedCode){
      let data = { 
        province : selectedCode.province_name, 
        district : selectedCode.district_name, 
        subdistrict : {label : selectedCode.subdistrict_name, value : selectedCode.subdistrict_id}}
      form.setFieldsValue(data);
    } else {
      setSelectedDistrict(null);
      setSelectedProvince(null);
      form.setFieldValue('district', null)
      form.setFieldValue('subdistrict', null)
      form.setFieldValue('province', null)
    }
  },[selectedCode])

  React.useEffect(() => {
    if (selectedProvince) {
      fetchDistrictOptions();
      form.setFieldValue('district', null)
      form.setFieldValue('subdistrict', null)
      setSelectedDistrict(null);
    }
  },[selectedProvince])

  React.useEffect(() => {
    if (selectedDistrict) {
      fetchSubdistrictOptions();
      form.setFieldValue('subdistrict', null)
    }
  },[selectedDistrict])

  React.useEffect(() => {
    if (selectedSubdistrict) {
      form.setFieldValue('sub_district', { label : selectedSubdistrict.zip_code, value : selectedSubdistrict.id })
    }
  },[selectedSubdistrict])

  // Open Owner detail modal
  React.useEffect(() => {
    setOwnerDetail(ownerTarget ? true : false);
  }, [ownerTarget])

  return (
    <Layout style={{ minHeight: "100vh", background: PRIMARY_LIGHT_COLOR }}>
      <HeaderScreen 
        onClick={() => navigate(PATH.SUPPLIERS)} 
        title={isCreate ? "ลงทะเบียนลูกค้า" : `${supplier && supplier.name}`} />

      <Content 
        className="main-content"
        style={{ 
          margin: "0px 24px 24px 24px", 
          padding: "16px 24px",
          textAlign: "left",  
          minHeight: 280,
          background: colorBgContainer }}>
        {errorMessages && 
          <Alert  
            style={{textAlign: "left"}}
            message={errorMessages}
            type="error"
            showIcon/>
        }
      
        {/* Form Owner */}
        <Divider orientation="left" style={{marginBottom: 0}}>เจ้าของกิจการ</Divider>
        <Form
          form={formOwner}
          layout="vertical"
          autoComplete="off">

          {/* Owner Button */}
          <Row gutter={16}> 
            <Col md={24} sm={24} xs={24} style={{textAlign: "right", marginBottom: 16}}>
              {!readOnly && (
                <Space >
                  <Button
                    type={"dashed"}
                    style={{color: colorInfo, borderColor: colorInfo}}
                    icon={<SearchOutlined />}
                    onClick={() => setOwnerSearch(true)}>
                      ค้นหาเจ้าของ
                  </Button>
                  <Button 
                    type={'dashed'}
                    style={{color: colorPrimary, borderColor: colorPrimary}}
                    icon={<PlusOutlined />}
                    onClick={() => setOwnerDetail(true)}>
                      เพิ่มเจ้าของ
                  </Button>
                  {owner && 
                    <ColorButton 
                      type={'primary'} 
                      override={colorWarning} 
                      onClick={() => setOwnerTarget(owner)}>
                        แก้ไขข้อมูล
                    </ColorButton>}
                  </Space>
                )}
            </Col>

            <Col md={8} sm={24} xs={24}>
              <div style={{ textAlign: "center" }} className='section-wrapper'>
                <Image
                  src={userImageData ? userImageData : (props.target && props.target.card) ? props.target.card : userDefault}
                  height={210} 
                  width={"auto"} />
              </div>
            </Col>

            <Col md={16} sm={24} xs={24}>
              <Row gutter={16}>
                <Col md={9} sm={24} xs={24}>
                  <Form.Item  
                    name={"first_name"}
                    label="ชื่อ"
                    rules={[{ required: true }]}>
                      <Input autoComplete="off" disabled/>
                  </Form.Item>
                </Col>

                <Col md={9} sm={24} xs={24}>
                  <Form.Item  
                    name={"last_name"}
                    label="สกุล"
                    rules={[{ required: true }]}>
                      <Input autoComplete="off" disabled/>
                  </Form.Item>
                </Col>

                <Col md={6} sm={24} xs={24}>
                  <Form.Item  
                    name={"nickname"}
                    label="ชื่อเล่น"
                    rules={[{ required: true }]}>
                      <Input autoComplete="off" disabled/>
                  </Form.Item>
                </Col>

                <Col md={8} sm={24} xs={24}>
                  <Form.Item  
                    name={"citizen_id"}
                    label="เลขบัตรประชาชน">
                      <Input autoComplete="off" disabled/>
                  </Form.Item>
                </Col>

                <Col md={8} sm={24} xs={24}>
                  <Form.Item  
                    name={"phone"}
                    label="เบอร์โทรศัพท์ 1">
                      <Input autoComplete="off" disabled/>
                  </Form.Item>
                </Col>

                <Col md={8} sm={24} xs={24}>
                  <Form.Item  
                    name={"phone2"}
                    label="เบอร์โทรศัพท์ 2">
                      <Input autoComplete="off" disabled/>
                  </Form.Item>
                </Col>

                <Col md={8} sm={24} xs={24}>
                  <Form.Item  
                    name={"line_id"}
                    label="Line ID">
                      <Input autoComplete="off" disabled/>
                  </Form.Item>
                </Col>

                <Col md={16} sm={24} xs={24}>
                  <Form.Item  
                    name={"document_url"}
                    label="URL To Document">
                      <Input autoComplete="off" disabled/>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>

        {/* Form Supplier */}
        <Divider orientation="left">สถานประกอบการ / สหกรณ์ / บุคคล</Divider>
        <Form
          style={{marginTop: 16}}
          form={form}
          layout="vertical"
          autoComplete="off">
      
          <Row gutter={16}>
            <Col md={8} sm={24} xs={24}>
              <div style={{ textAlign: "center" }} className='section-wrapper'>
                <Image
                  src={supplierImageData ? supplierImageData : supplierImage ? supplierImage : null}
                  height={180} 
                  width={"auto"} />
                <div>
                  <Button 
                    style={{marginTop: 8}}
                    icon={<UploadOutlined />} 
                    onClick={() => uploadCompanyRef.current.click()}>
                      อัพโหลดรูป
                  </Button>
                </div>
              </div>
            </Col>

            <Col md={16} sm={24} xs={24}>
              <Row gutter={16}>
                <Col md={8} sm={24} xs={24}>
                  <Form.Item  
                    name={"code"}
                    label="รหัสลูกค้า">
                      <Input autoComplete="off" />
                  </Form.Item>
                </Col>

                <Col md={16} sm={24} xs={24}>
                  <Form.Item  
                    name={"name"}
                    label="ชื่อสถานประกอบการ / สหกรณ์ / ชื่อบุคคล">
                      <Input autoComplete="off" />
                  </Form.Item>
                </Col>

                <Col md={8} sm={24} xs={24}>
                  <Form.Item  
                    name={"supplier_type"}
                    label="ชนิดสถานประกอบการ">
                    <Select
                      placeholder={"เลือก..."}
                      autoComplete="off" 
                      showSearch
                      filterOption={filterOption}
                      options={typeOptions}/>
                  </Form.Item>
                </Col>

                <Col md={8} sm={24} xs={24}>
                  <Form.Item  
                    name={"supplier_code"}
                    label="รหัสสถานประกอบการ / code">
                      <Input autoComplete="off" />
                  </Form.Item>
                </Col>

                <Col md={8} sm={24} xs={24}>
                  <Form.Item  
                    name={"invoice_id"}
                    label="เลขที่ผู้เสียภาษี"
                    rules={[{ required: true }]}>
                      <Input autoComplete="off" />
                  </Form.Item>
                </Col>

                <Col md={6} sm={24} xs={24}>
                  <Form.Item  
                    name={"phone"}
                    label="เบอร์โทรศัพท์ 1">
                      <Input autoComplete="off" />
                  </Form.Item>
                </Col>

                <Col md={6} sm={24} xs={24}>
                  <Form.Item  
                    name={"phone2"}
                    label="เบอร์โทรศัพท์ 2">
                      <Input autoComplete="off" />
                  </Form.Item>
                </Col>

                <Col md={12} sm={24} xs={24}>
                  <Form.Item  
                    name={"document_url"}
                    label="URL To Document">
                      <Input autoComplete="off" />
                  </Form.Item>
                </Col>

              </Row>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col md={8} sm={24} xs={24}>
              <Form.Item  
                name={"license_trade_number"}
                label="ใบอนุญาตค้ายาง">
                  <Input autoComplete="off" />
              </Form.Item>
              <UploadFile 
                btnText={'Upload ใบอนุญาตค้ายาง'} 
                style={{marginBottom : 16}} 
                ref={tradeRef} 
                dataList={tradeFile} 
                onRemove={(e) => setDeletedFiles([...deletedFiles,e.id])}/>
            </Col>

            <Col md={8} sm={24} xs={24}>
              <Form.Item  
                name={"factory_number"}
                label="ใบโรงงาน">
                  <Input autoComplete="off" />
              </Form.Item>
              <UploadFile 
                btnText={'Upload ใบโรงงาน'} 
                style={{marginBottom : 16}} 
                ref={factoryRef}
                dataList={factoryFile} 
                onRemove={(e) => setDeletedFiles([...deletedFiles,e.id])}/>
            </Col>

            <Col md={8} sm={24} xs={24}>
              <Form.Item  
                name={"eco_number"}
                label="ใบสิ่งแวดล้อม">
                  <Input autoComplete="off" />
              </Form.Item>
              <UploadFile 
                btnText={'Upload ใบสิ่งแวดล้อม'} 
                style={{marginBottom : 16}} 
                ref={ecoRef}
                dataList={ecoFile} 
                onRemove={(e) => setDeletedFiles([...deletedFiles,e.id])}/>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col md={8} sm={24} xs={24}>
              <Form.Item  
                name={"license_number"}
                label="ใบประกอบกิจการยาง">
                  <Input autoComplete="off" />
              </Form.Item>
              <UploadFile 
                btnText={'Upload ใบประกอบกิจการยาง'} 
                style={{marginBottom : 16}} 
                ref={licenseRef}
                dataList={licenseFile} 
                onRemove={(e) => setDeletedFiles([...deletedFiles,e.id])}/>
            </Col>

            <Col md={8} sm={24} xs={24}>
              <Form.Item  
                name={"book_bank_number"}
                label="Book Bank">
                  <Input autoComplete="off" />
              </Form.Item>
              <UploadFile 
                btnText={'Upload Book Bank'} 
                style={{marginBottom : 16}} 
                ref={bookbankRef}
                dataList={bookbankFile} 
                onRemove={(e) => setDeletedFiles([...deletedFiles,e.id])}/>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col md={16} sm={24} xs={24}>
              <Form.Item  
                name={"address"}
                label="ที่อยู่"
                rules={[{ required: true }]}>
                  <Input autoComplete="off" />
              </Form.Item>
            </Col>

            <Col md={8} sm={24} xs={24}>
              <Form.Item  
                name={"sub_district"}
                label="รหัสไปรษณีย์">
              <Select 
                placeholder={"เลือก..."}
                autoComplete="off" 
                showSearch
                labelInValue
                filterOption={false}
                notFoundContent={fetching ? <Spin size="small" /> : "-"}
                onSearch={debounceCode}
                options={codeSubdistricts}
                onSelect={(e, original) => setSelectedCode(original)}
                onClear={() => setSelectedCode(null)}
                allowClear={"-"}/>
              </Form.Item>
            </Col>

            <Col md={8} sm={24} xs={24}>
              <Form.Item  
                name={"province"}
                label="จังหวัด">
                <Select 
                  placeholder={"เลือก..."}
                  autoComplete="off" 
                  showSearch
                  labelInValue
                  filterOption={filterOption}
                  options={provinceOptions}
                  onSelect={(e) => setSelectedProvince(e.value)}
                  allowClear={"-"}
                  disabled={selectedCode ? true : false}/>
              </Form.Item>
            </Col>

            <Col md={8} sm={24} xs={24}>
              <Form.Item  
                name={"district"}
                label="อำเภอ">
                <Select 
                  placeholder={"เลือก..."}
                  autoComplete="off" 
                  showSearch
                  labelInValue
                  filterOption={filterOption}
                  options={districtOptions}
                  onSelect={(e) => setSelectedDistrict(e.value)}
                  allowClear={"-"}
                  disabled={(selectedCode || !selectedProvince) ? true : false}/>
              </Form.Item>
            </Col>

            <Col md={8} sm={24} xs={24}>
              <Form.Item  
                name={"subdistrict"}
                label="ตำบล">
                <Select 
                  placeholder={"เลือก..."}
                  autoComplete="off" 
                  showSearch
                  labelInValue
                  filterOption={filterOption}
                  options={subdistrictOptions}
                  onSelect={(e,original) => setSelectedSubdistrict(original)}
                  allowClear={"-"}              
                  disabled={(selectedCode || !selectedDistrict) ? true : false}/>
              </Form.Item>
            </Col>
          </Row>

          {/* Leaflet Map */}
          <Col md={24} sm={24} xs={24}>
            <Card style={{ marginTop : 16}} title={'ตำแหน่ง'}>
              <Row style={{ marginBottom : 16 }} gutter={10}>
                <Col md={8} sm={24} xs={24} >
                  <Input
                    type="text"
                    placeholder="latitude"
                    name="lat"
                    value={inputLat}
                    onChange={handleLatLngChange}
                    addonBefore="lat"
                  />
                </Col>
                <Col md={8} sm={24} xs={24} >
                  <Input
                    type="text"
                    placeholder="longitude"
                    name="lng"
                    value={inputLng}
                    onChange={handleLatLngChange}
                    addonBefore="lng"
                  />
                </Col>
                <Button 
                  onClick={handleFlyTo}
                   style={{marginRight : 5}}>ค้นหา</Button>
                
                {!readOnly && (
                  <Button danger 
                    type={deleteMode ? "dashed" :  "primary" } 
                    onClick={() => handleDeleteButton()}>{deleteMode ? "ยกเลิก" : "ลบหมุด"}</Button>
                )}

              </Row>

              <div style={{height: 500}}>
                <MapContainer zoom={10} maxZoom={20} center={center} ref={mapRef} key={mapKey} attributionControl={false}>
                  <LayersControl>
                    <LayersControl.BaseLayer checked name="Google Map">
                      <TileLayer
                        maxZoom={20}
                        attribution="Google Maps"
                        url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
                      />
                    </LayersControl.BaseLayer>

                    <LayersControl.BaseLayer name="Google Map Satellite">
                      <LayerGroup>
                        <TileLayer
                          maxZoom={20}
                          attribution="Google Maps Satellite"
                          url="https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}"
                        />
                        <TileLayer url="https://www.google.cn/maps/vt?lyrs=y@189&gl=cn&x={x}&y={y}&z={z}"/>
                      </LayerGroup>
                    </LayersControl.BaseLayer>
                  </LayersControl>
          
                  {/* {positions && <Marker position={positions} />} */}
                  <FeatureGroup>
                    <EditControl
                      onCreated = {(e) => {
                        prepareMarker(e.layer._latlng)
                        mapRef.current.removeLayer(e.layer)
                      }}
                      draw={{
                        polyline: false,
                        rectangle: false,
                        circle: false,
                        circlemarker:false,
                        polygon: false,
                        marker : {icon : iconMarker}
                      }}
                      edit = {{ edit: false , remove : false}} />
                  </FeatureGroup>

                  {positions && 
                    <Marker 
                      position={ positions }
                      icon={ iconMarker }
                      eventHandlers={{
                        click: (e) => {
                          if(deleteMode === true ){
                            mapRef.current.removeLayer(e.target);
                            setPositions(null);
                            setMarker(null);
                            setDeleteMode(false);
                          }
                        }
                      }}
                  />}
                </MapContainer>
              </div>

              <Col md={24} sm={24} xs={24} style={{ marginTop : 16}}>
                <Form.Item  
                  name={"note"}
                  label="หมายเหตุ">
                    <Input.TextArea autoComplete="off" />
                </Form.Item>
              </Col>

              <Col md={24} sm={24} xs={24}>
                <Form.Item  
                  name={"note2"}
                  label="หมายเหตุ">
                    <Input.TextArea autoComplete="off" />
                </Form.Item>
              </Col>
            </Card>
          </Col>

          {/* Footer button */}
          {!isCreate && !readOnly &&
            <ColorButton 
              type='primary' 
              style={{float : 'left', marginTop : 16 }} 
              override={colorError} 
              onClick={() => setDeleteTarget(params.id)}>
                {"ลบลูกค้า"}
            </ColorButton>}

          {!readOnly && (
            <ColorButton
              key={"2"}
              type={"primary"}
              style={{float : 'right', marginLeft : 10, marginTop : 16 }}
              loading={isLoading}
              override={isCreate ? colorPrimary : colorWarning}
              onClick={handleOK}>
              {isCreate ? "เพิ่มลูกค้า" : "แก้ไขลูกค้า"}
            </ColorButton>
          )}
        </Form>

        <ImgCrop aspect={16 / 9} aspectSlider>
          <input id="imgSelect"
            type="file"
            accept="image/*"
            ref={uploadUserRef}
            style={{ display: 'none' }}
            onChange={async (evt) => {
              if (evt.target.files.length > 0) {
                setUploadUserFile(URL.createObjectURL(evt.target.files[0]))
                evt.target.value = null  // clear reference to enable select same file}
            }
          }}/>
        </ImgCrop>

        <ImageEditorModal
          open={uploadUserFile ? true : false}
          image={uploadUserFile}
          onUpdated={(image) => {
            setUserImageData(image)
            setUploadUserFile(null)
          }}
          onClose={() => setUploadUserFile(null)}/>

        <ImgCrop aspect={16 / 9} aspectSlider>
          <input id="imgSelect"
            type="file"
            accept="image/*"
            ref={uploadCompanyRef}
            style={{ display: 'none' }}
            onChange={async (evt) => {
              if (evt.target.files.length > 0) {
                setUploadCompanyFile(URL.createObjectURL(evt.target.files[0]))
                evt.target.value = null  // clear reference to enable select same file}
            }
          }}/>
        </ImgCrop>

        <ImageEditorModal
          open={uploadCompanyFile ? true : false}
          image={uploadCompanyFile}
          onUpdated={(image) => {
            setSupplierImageData(image)
            setUploadCompanyFile(null)
          }}
          onClose={() => setUploadCompanyFile(null)}/>
      </Content>

      {/* Confirm Delete */}
      <DeleteSupplierModal
        open={deleteTarget ? true : false}
        onClose={() => setDeleteTarget(null)}/>

      {/* For owner */}
      <OwnerDetailModal
        open={openOwnerDetail}
        onClose={() => closeOwnerDetail()}
        target={ownerTarget}
        onUpdate={(id) => {
          fetchOwner(id);
          closeOwnerDetail();
        }}/>

      <OwnerSearchModal 
        open={openOwnerSearch}
        onClose={() => setOwnerSearch(false)}
        onSelected={(id) => {
          fetchOwner(id);
          setOwnerSearch(false)
        }}/>
    </Layout>
  )
}