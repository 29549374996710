import React from "react";
import propTypes from "prop-types";
import { Modal, Select, Spin, theme } from "antd";
import { URL_SUPPLIER } from "../../constants/urls";
import { GET } from "../../frameworks/HttpClient";
import _ from "lodash";

export default function OwnerSearchModal(props) {
  const [seleted, setSelected] = React.useState(null);
  const [ownerOptions, setOwnerOptions] = React.useState([]);
  const [debouncedOwner, setDebouncedOwner] = React.useState("");
  const [fetching, setFetching] = React.useState(false);

  const {
		token: { colorPrimary }
	} = theme.useToken();

  const handleOK = async () => {
    props.onSelected(seleted);
  }

  const fetchOwnerOption = async() => {
    setFetching(true);
    try {
      const response = await GET(`${URL_SUPPLIER.OWNER}`, {search : debouncedOwner})
      setOwnerOptions(response.data.results.map((item) => {
				return {
					...item,
					label: item.full_name,
					value: item.id
				}
			}))
    } catch (error) {
      console.log(error.errorMessages)
    } finally {
      setFetching(false);
    }
  }

  const debounceOwner = React.useCallback(
    _.debounce((_searchVal) => { setDebouncedOwner(_searchVal) }, 800),
    []
  );

  React.useEffect(() => {
    if (props.open) {
      fetchOwnerOption()
    }
    if (!props.open) {
      setSelected(null);
      setOwnerOptions([]);
    }
  }, [props.open]);

  React.useEffect(()=> {
    fetchOwnerOption();
  }, [debouncedOwner])

  return (
    <Modal 
      title={"ค้นหาเจ้าของกิจการ"}
      okText={"เลือก"}
      okButtonProps={{ style: {background : colorPrimary}, disabled: false}}
      cancelText={"ยกเลิก"}
      open={props.open}
      onOk={handleOK}
      onCancel={props.onClose}>

      <Select
        style={{width: "100%"}}
        showSearch={true}
        filterOption={false}
        onSearch={debounceOwner}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        options={ownerOptions}
        placeholder={"ค้นหา..."}
        onChange={(value) =>  setSelected(value)}
        value={seleted}
      />
    </Modal>
  )
}

OwnerSearchModal.defaultProps = {
	open: false,
	onClose: () => {},
  onSelected: (id) => {}
}

OwnerSearchModal.propTypes = {
	open: propTypes.bool,
	onClose: propTypes.func,
  onSelected: propTypes.func,
}