import React from "react";
import ColorButton from "../common/ColorButton";
import { SECONDARY_COLOR } from "../../constants/color";
import { PlusCircleOutlined, DeleteOutlined, EditOutlined, FilePdfOutlined, FileExcelOutlined } from '@ant-design/icons';
import { Col, Select, Row, Space, theme, Form, Button } from "antd";
import TSTable from "../common/TSTable";
import {  DATETIME_FORMAT, HUMAN_DATETIME_FORMAT, PALLET_STATUS, PALLET_STATUS_TRANSLATION } from "../../constants/strings";
import * as _ from 'lodash';
import PalletCreateModal from "./PalletCreateModal";
import { URL_PALLET } from "../../constants/urls";
import { Content } from "antd/es/layout/layout";
import PalletDeleteModal from "./PalletDeleteModal";
import dayjs from "dayjs";

export default function PalletManegerView() {
  const [openPalletModal, setPalletModal] = React.useState(false);
  const [palletTarget, setPalletTarget] = React.useState(null)
  const [palletRemove , setPalletRemove] = React.useState(null)
  const [filter, setFilter] = React.useState(`?status=${PALLET_STATUS.AVAILABLE}&status=${PALLET_STATUS.IN_USE}`);
  const tableRef = React.useRef(null);

  const {
    token: { colorPrimary, colorError, colorWarning },
  } = theme.useToken();

  const columns = [
    {
      title: "ชื่อพาเลท",
      dataIndex: "code",
      searcher: true,
      sorter: true,
    },
    {
      title: "น้ำหนักพาเลท (Kgs)",
      dataIndex: "base_weight",
      render:(value ,original) =>{
        return(
          <Space>
            {value}
            {original.is_empty && original.is_active && (
              <ColorButton 
                type='link'
                style={{color : colorWarning}}
                icon={<EditOutlined/>} 
                onClick={()=> setPalletTarget(original)}/>
            )}
          </Space>
        )
      },
      renderReport: (value, original) => value
    },
    {
      title: "สถานะ",
      dataIndex: "status",
      render : (value) => (<div>{PALLET_STATUS_TRANSLATION[`${value}`]}</div>),
      renderReport: (value, original) => PALLET_STATUS_TRANSLATION[`${value}`],
      sorter: true,
      searcher: true,
      searcherOptions: [
        { key: PALLET_STATUS.AVAILABLE, value: PALLET_STATUS.AVAILABLE, label: 'ว่าง' },
        { key: PALLET_STATUS.IN_USE, value: PALLET_STATUS.IN_USE, label: 'มีสินค้า' },
        { key: PALLET_STATUS.CANCELED, value: PALLET_STATUS.CANCELED, label: 'ปิดการใช้งาน' },
      ],
    },
    {
      title: "Action",
      dataIndex: "id",
      skipReport: true,
      render: (_, original) => {
        return (
          <div style={{textAlign:'center'}}>
            {original.is_empty && original.is_active && (
              <ColorButton 
                type='link'
                style={{color :colorError}}
                icon={<DeleteOutlined/>}
                onClick={()=>setPalletRemove(original)} 
                />
            )}
          </div>
      )}
    }
  ]

  const refreshData = () => {
    if (tableRef.current) {
      tableRef.current.fetch();
    }
  }

  React.useEffect(() => {
    refreshData();
  },[filter])

  return(
    <Content>
      <Form autoComplete="off">
        <Space style={{ float: 'right' }}>
          <Row gutter={8} wrap>
            <Col lg={8} md={12} xs={24}>
            </Col>
            <Col xl={16} lg={6} md={12} xs={24}>
              <Space style={{ float: 'right' }}>
                <div style={{textAlign:'center'}}>
                  <ColorButton
                    type={"primary"} 
                    override={SECONDARY_COLOR}
                    icon={<PlusCircleOutlined />} 
                    style={{float: 'right', background: colorPrimary, marginBottom: 16}}
                    onClick={() => setPalletModal(true)}>
                      พาเลท
                  </ColorButton>
                </div>
              </Space>
            </Col>
          </Row>
        </Space>

        <Row gutter={8}>
          <Col>
          </Col>
          <Col>
            <Button
              icon={<FileExcelOutlined />}
              onClick={async () => {
                await tableRef.current.exportXlsx({
                  title: "รายการพาเลทใช้งาน",
                  subtitle: `ดึงข้อมูล ณ​ วันที่ ${dayjs().format(HUMAN_DATETIME_FORMAT)}`,
                  filename: `รายการพาเลทใช้งาน - ${dayjs().format(DATETIME_FORMAT)}.xlsx`,
                  fetchPageSize: 1000,
                })
              }}>
              Excel
            </Button>
          </Col>
        </Row>


      <TSTable
        ref={tableRef}
        columns={columns}
        url={`${URL_PALLET.PALLET}?is_active=true`}
        rowKey={"id"}
        size={"small"}
        pageSize={10}/>

        <PalletCreateModal
          open={(openPalletModal || palletTarget) ? true : false}  
          target={palletTarget}
          onClose={() => palletTarget ? setPalletTarget(null) : setPalletModal(false)}
          onUpdate={() => {
            palletTarget ? setPalletTarget(null) : setPalletModal(false)
            refreshData();
          }}/>

        <PalletDeleteModal
          open={palletRemove ? true : false}
          target={palletRemove}
          onClose={()=> setPalletRemove(null)}
          onUpdate={() => {
            setPalletRemove(null)
            refreshData();
          }}/>
      </Form>
    </Content>
  )
}