import React from 'react'
import { Col, Flex, Form, Input, Row, Select, Space, theme, Popover, Button } from 'antd'
import { 
  PlusCircleOutlined, 
  CheckCircleOutlined, 
  HomeOutlined,
  MinusCircleOutlined ,
  ExclamationCircleOutlined,
  FilePdfOutlined,
  FileExcelOutlined
} from '@ant-design/icons'
import dayjs from 'dayjs'
import ColorButton from '../components/common/ColorButton'
import * as _ from 'lodash'
import { PDF_COLOR, SECONDARY_COLOR, XLSX_COLOR } from '../constants/color'
import TSTable from '../components/common/TSTable'
import { DATETIME_FORMAT, HUMAN_DATETIME_FORMAT, HUMAN_DATE_FORMAT } from '../constants/strings'
import moment from 'moment'
import ExportInventoryModal from '../components/inventory/ExportInventoryModal'
import ImportInventoryModal from '../components/inventory/ImportInventoryModal'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import ShareInventoryModal from '../components/inventory/ShareInventoryModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ShareHistoryModal from '../components/inventory/ShareHistoryModal'
import useDropdownItem from '../hooks/useDropdownItem'
import { URL_PALLET, URL_PRODUCT } from '../constants/urls'
import { currencyFormat } from '../frameworks/Util'

export default function InventoryListView() {
  const tableRef = React.useRef()
  const [target, setTarget] = React.useState(null)
  const [historyTarget, setHistoryTarget] = React.useState(null)
  const [form] = Form.useForm()
  const [openExportModal, setOpenExportModal] = React.useState(false)
  const [openShareModal, setOpenShareModal] = React.useState(false)
  const [searchVal, setSearchVal] = React.useState(null)
  const [productOptions, fetcthProductOptions ] = useDropdownItem({
    url: URL_PRODUCT.PRODUCT,
    manual: true, pagination: true,
    key: 'name', value: 'name', label: 'name'
  });

  const {
    token: { colorPrimary, colorInfo, colorWarning, colorSuccess },
  } = theme.useToken()

  const columns = [
    {
      title: 'วันที่เริ่มใช้งาน',
      dataIndex: 'created',
      width: 170,
      render: (value) => moment(value).format(HUMAN_DATETIME_FORMAT),
    },
    {
      title: 'ชื่อพาเลท',
      dataIndex: 'pallet_code',
      searcher: true,
    },
    {
      title: 'สินค้า',
      dataIndex: 'product_name',
      searcher: true,
      searcherOptions: productOptions,
    },
    {
      title: 'คุณภาพ(Grade)',
      dataIndex: 'product_grade_name',
      searcher: true,
    },
    {
      title: 'สถานะ',
      dataIndex: 'is_store_editable',
      width: 50,
      render: (value, original) => (
        <div style={{ textAlign: 'center', fontSize: 20 }}>
          { value ? 
            <Popover content={`อยู่ในคลังสินค้า`}>
              <HomeOutlined style={{color:colorInfo}}/>
            </Popover>
            :
            <Popover content={original.has_si ? `อยู่ระหว่างกระบวนการขาย (${original.si_code})` : `อยู่ระหว่างกระบวนการซื้อ`}>
              <ExclamationCircleOutlined style={{color: colorWarning }}/> 
            </Popover> 
          }
        </div>
      ),
      renderReport: (value, original) => (
        value ? "อยู่ในคลัง" : "อยู่ในการชั่ง"
      ),
      searcher: true,
      searcherOptions: [
        { key: 'ready', value: 'ready', label: 'อยู่ในคลังสินค้า' },
        { key: 'buy', value: 'buy', label: 'ระหว่างการซื้อ' },
        { key: 'sell', value: 'sell', label: 'ระหว่างการขาย' },
      ],
    },
    {
      title: 'น้ำหนักรวม',
      dataIndex: 'net_weight',
      searcher: true,
      render: (value) => <div style={{textAlign: "right"}}>{currencyFormat(value)}</div> ,
      renderReport: value => currencyFormat(value),
    },
    {
      title: 'น้ำหนักแผง',
      dataIndex: 'pallet_weight',
      searcher: true,
      render: (value) => <div style={{textAlign: "right"}}>{currencyFormat(value)}</div> ,
      renderReport: value => currencyFormat(value)
    },
    {
      title: 'น้ำหนักสุทธิ',
      dataIndex: 'product_weight',
      searcher: true,
      render: (value) => <div style={{textAlign: "right"}}>{currencyFormat(value)}</div>,
      renderReport: value => currencyFormat(value)
    },
    {
      title: 'Action',
      dataIndex: '',
      skipReport: true,
      width: 80,
      render: (_, original) => {
        return (
          <Flex
            align='center'
            justify='center'
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <ColorButton
              type='primary'
              override={colorInfo}
              icon={<FontAwesomeIcon icon={faEye} />}
              onClick={() => {
                setHistoryTarget(original)
              }}
            />
          </Flex>
        )
      },
    },
  ]

  const refreshData = () => {
    if (tableRef.current) {
      tableRef.current.fetch()
    }
  }

  React.useEffect(() => {
    refreshData();
  },[searchVal])

  React.useEffect(() => {
    fetcthProductOptions();
  }, [])

  return (
    <div>
      <Form form={form} autoComplete='off'>
        
        <Space style={{ float: 'right' }}>
          <Row gutter={8}>
            <Col>
              <ColorButton
                type={'primary'}
                override={SECONDARY_COLOR}
                style={{
                  float: 'right',
                  background: colorInfo,
                  marginBottom: 16,
                  width: 100,
                }}
                onClick={() => setOpenShareModal(true)}>
                แบ่งพาเลท
              </ColorButton>
            </Col>
            <Col>
              <ColorButton
                type={'primary'}
                override={SECONDARY_COLOR}
                style={{
                  float: 'right',
                  background: colorPrimary,
                  marginBottom: 16,
                  width: 100,
                }}
                onClick={() => setTarget('add')}>
                รับเข้า
              </ColorButton>
            </Col>

            <Col>
              <ColorButton
                type={'primary'}
                override={SECONDARY_COLOR}
                style={{
                  float: 'right',
                  background: colorWarning,
                  marginBottom: 16,
                  width: 100,
                }}
                onClick={() => setOpenExportModal(true)}>
                ส่งออก
              </ColorButton>
            </Col>
          </Row>
        </Space>

        <Row gutter={8}>
          <Col>
            <Button 
              icon={<FilePdfOutlined />} 
              onClick={async () => {
                await tableRef.current.exportPDF({
                  title:"รายการสินค้าคงคลัง",
                  subtitle: `ดึงข้อมูล ณ​ วันที่ ${dayjs().format(HUMAN_DATETIME_FORMAT)}`,
                  landscape: true
                })
              }}>
              PDF
            </Button>
          </Col>
          <Col>
            <Button 
              icon={<FileExcelOutlined />} 
              onClick={async () => {
                await tableRef.current.exportXlsx({
                  title: "รายการสินค้าคงคลัง",
                  subtitle: `ดึงข้อมูล ณ​ วันที่ ${dayjs().format(HUMAN_DATETIME_FORMAT)}`,
                  filename: `รายการสินค้าคงคลัง - ${dayjs().format(DATETIME_FORMAT)}.xlsx`
                })
              }}>
              Excel
            </Button>
          </Col>
        </Row>

        <TSTable
          ref={tableRef}
          columns={columns}
          url={URL_PALLET.PALLET_USAGE}
          rowKey={'id'}
          params={{ is_active: true, is_close: false, search: searchVal}}
          size={'small'}
          pageSize={10}
          onRow={(record, rowIndex) => ({
            onClick: (_) => setTarget(record),
          })}
        />

        <ImportInventoryModal
          open={target ? true : false}
          readOnly={(target && (target !== "add") && !target.is_store_editable) ? true : false}
          onClose={() => setTarget(null)}
          target={target}
          onUpdate={() => {
            setTarget(null)
            refreshData()
          }}
        />
      </Form>

      <ExportInventoryModal
        open={openExportModal}
        onClose={() => setOpenExportModal(false)}
        onUpdate={() => {
          setOpenExportModal(false)
          refreshData()
        }}
      />

      <ShareInventoryModal
        open={openShareModal}
        onClose={() => setOpenShareModal(false)}
        onUpdate={() => {
          setOpenShareModal(false)
          refreshData()
        }}
      />

      <ShareHistoryModal
        open={historyTarget}
        target={historyTarget}
        onClose={() => setHistoryTarget(null)}
      />
    </div>
  )
}
