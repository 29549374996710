import { Space, Row, Col, Button } from "antd";
import { FileExcelOutlined } from '@ant-design/icons';
import React from "react";
import { DATETIME_FORMAT, HUMAN_DATETIME_FORMAT, PALLET_STATUS, PALLET_STATUS_TRANSLATION } from "../../constants/strings";
import { URL_PALLET } from "../../constants/urls";
import TSTable from "../common/TSTable";
import dayjs from "dayjs";

export default function PalletRemovedView() {
  const tableRef = React.useRef(null);

  const columns = [
    {
      title: "ชื่อพาเลท",
      dataIndex: "code",
      searcher: true,
    },
    {
      title: "น้ำหนักพาเลท (Kgs)",
      dataIndex: "base_weight",
    },
    {
      title: "เหตุผลการยกเลิกพาเลท",
      dataIndex: "delete_note",
    },
  ]

  return(
    <>
    <Row gutter={8}>
      <Col>
      </Col>
      <Col>
        <Button
          icon={<FileExcelOutlined />}
          onClick={async () => {
            await tableRef.current.exportXlsx({
              title: "รายการพาเลทยกเลิก",
              subtitle: `ดึงข้อมูล ณ​ วันที่ ${dayjs().format(HUMAN_DATETIME_FORMAT)}`,
              filename: `รายการพาเลทยกเลิก - ${dayjs().format(DATETIME_FORMAT)}.xlsx`,
              fetchPageSize: 1000,
            })
          }}>
          Excel
        </Button>
      </Col>
    </Row>


    <TSTable
      style={{marginTop: 16}}
      ref={tableRef}
      columns={columns}
      url={`${URL_PALLET.PALLET}?status=${PALLET_STATUS.CANCELED}`}
      rowKey={"id"}
      size={"small"}
      pageSize={10}/>

    </>
  )
}