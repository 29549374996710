import React from "react";
import propTypes from "prop-types";
import { Col, Form, Input, Modal, Row, Select, Space, Spin, theme, Button, InputNumber, Alert, Popover } from "antd";
import { DELETE, GET, PATCH, POST } from "../../frameworks/HttpClient";
import { URL_PRODUCT, URL_SUPPLIER, URL_WEIGHT } from "../../constants/urls";
import { PlusOutlined } from '@ant-design/icons';
import VehicleModal from "../vehicle/VehicleModal";
import { useUserContext } from "../../hooks/UserSettingProvider";
import { useParams } from "react-router-dom";
import _ from "lodash";

export default function SIDetailModal(props) {
  const { target } = props;
  const {user} = useUserContext();
  const params = useParams();
  const [openVehicleModal, setOpenVehicleModal] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null)
  const [products, setProducts] = React.useState([]);
  const [customers, setCustomers] = React.useState([]);
  const [fetching, setFetching ] = React.useState(null);
  const [productGrades, setProductGrades] = React.useState([])
  const [debouncedSupplier, setDebouncedSupplier] = React.useState('');
  const [debouncedProduct, setDebouncedProduct] = React.useState('')
  const [vehicles, setVehicles] = React.useState([]);
  const [form] = Form.useForm();

  const {
		token: { colorWarning, colorError, colorPrimary },
	} = theme.useToken();

  const debounceSupplier = React.useCallback(_.debounce((_searchVal) => { setDebouncedSupplier(_searchVal) }, 800), []);
  const debounceProduct = React.useCallback(
    _.debounce((_searchVal) => {
      setDebouncedProduct(_searchVal)
    }, 800),
    []
  )

  /* Create or update SI */
	const handleOK = async() => {
    try {
      const data = await form.validateFields();
      setLoading(true);
      data['factory'] = user.factory
      if (data.vehicle && data.vehicle.value) {
        data['vehicle'] = data.vehicle.value;
      } else {
        data["vehicle"] = null
      }

      if (props.target) {
        await PATCH(`${URL_WEIGHT.SHIPPING_INSTRUCTION}${props.target.id}/`, data)
      } else {
        await POST(URL_WEIGHT.SHIPPING_INSTRUCTION, data);
      }
      props.onUpdate();
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setLoading(false);
    }
	}

  /* Handle delete SI */
  const handleDelete = async() => {
    if (!props.target) {
      setErrorMessages("ไม่พบ SI ที่ต้องการลบ!")
      return;
    }
    try {
      await DELETE(`${URL_WEIGHT.SHIPPING_INSTRUCTION}${props.target.id}`);
      props.onDeleted();
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setLoading(false);
    }
	}

  const fetchCustomer = async() => {
    setFetching(true);
    try {
      const response = await GET(URL_SUPPLIER.SUPPLIER, {page_size: 100, search : debouncedSupplier})
      setCustomers(response.data.results.map(item => {
        return(
          {...item,
            label : item.name,
            value : item.id
          }
        )}))
    } catch (error) {
      console.log(error.errorMessages)
    } finally {
      setFetching(false);
    }
  }

  const fetchProductOptions = async() => {
    try {
      const response = await GET(URL_PRODUCT.PRODUCT, {page_size: 999})
      setProducts(response.data.results.map(item => {
        return (
          {...item,
            label : item.name,
            value : item.id
          }
        )}))
    } catch (error) {
      console.log(error.errorMessages)
    }
  }

  const fetchVehicle = async() => {
    try {
      const response = await GET(`${URL_WEIGHT.VEHICLE}`,  {page_size: 999})
      setVehicles(response.data.results.map(item => {
        return({...item,
          label : item.license_plate,
          value : item.id
        }
        )
      }))
    } catch (error) {
      console.log(error.errorMessages)
    }    
  }

  const handleClose = () => {
    setOpenDelete(false);
    props.onClose();
  }

  /** Filter options */
  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  React.useEffect(() => {
    if (debouncedSupplier)
      fetchCustomer();
  }, [debouncedSupplier])
  

  React.useEffect(() => {
    if (props.target) {
      form.setFieldsValue(target);
      form.setFieldValue('vehicle', {label : target.vehicle_license_plate, value : target.vehicle})
    }
  }, [props.target]);

  React.useEffect(() => {
    if (props.open) {
      fetchCustomer();
      fetchVehicle();
      fetchProductOptions();
    } else {
      setOpenDelete(false);
      form.resetFields();
      setErrorMessages(null);
    }
  },[props.open]);

  return (
		<Modal 
			title={props.target ? "ข้อมูล SI" : "เพิ่ม SI"}
      open={props.open}
      width={640}
			onCancel={props.onClose}
      maskClosable={false}
      footer={
        <div>
          {props.target && (
            <Popover 
            title="ลบ SI" 
            trigger="click"
            open={openDelete}
            onOpenChange={(newOpen) => setOpenDelete(newOpen)}
            content={
              <div>
                <div>{"ยืนยันการลบ SI ใช่หรือไม่ ?"}</div>
              
                <div style={{textAlign: "right", marginTop: 16}}>
                  <Button onClick={() => setOpenDelete(false)}>ยกเลิก</Button>
                  <Button 
                    type={"primary"}
                    style={{marginLeft: 8, background: colorError}}
                    onClick={() => {
                      setOpenDelete(false);
                      handleDelete();
                    }}>
                      ยืนยัน
                  </Button>
                </div>
              </div>
            }>
              <Button
                style={{background: colorError, float: 'left'}}
                loading={loading}           
                type={"primary"}
                onClick={() => setOpenDelete(true)}>
                  ลบ
              </Button>
            </Popover>
          )}

          <Button 
            loading={loading}
            onClick={props.onClose}>
              ยกเลิก
          </Button>
          
          <Button
            loading={loading}           
            type={"primary"} 
            style={{marginLeft: 8, background: props.target ? colorWarning : colorPrimary}}
            onClick={() => handleOK()}>
              {props.target ? "แก้ไข" : "เพิ่ม"}
          </Button>
          
        </div>
      }>
      <Form
        form={form}
        layout="vertical"
        autoComplete="off">

        {errorMessages && 
          <Alert  
            style={{marginBottom: 16, textAlign: "left"}}
            message={errorMessages}
            type="error"
            showIcon/>
        }

        <Row gutter={[16]}>
          <Col md={8} sm={24} xs={24}>
            <Form.Item  
              name={"code"}
              label="หมายเลข SI"
              rules={[{ required: true }]}>
                <Input autoComplete="off" />
            </Form.Item>
          </Col>
          <Col md={8} sm={24} xs={24}>
            <Form.Item
              name={"product"}
              label="สินค้า"
              rules={[{ required: true }]}>
              <Select
                showSearch
                autoComplete='off'
                filterOption={false}
                notFoundContent={fetching ? <Spin size='small' /> : '-'}
                onSearch={debounceProduct}
                options={products}
              />
            </Form.Item>
          </Col>

          <Col md={8} sm={24} xs={24} >
            <Form.Item  
              name={"goal_weight"}
              label="น้ำหนักขาย (ประมาณ)"
              rules={[{ required: true }]}>
                <InputNumber
                  addonAfter="กก."
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  style={{ width : '100%'}}
                onFocus={evt => evt.target.select()}/>
            </Form.Item>
          </Col>

          <Col md={16} sm={24} xs={24} >
            <Form.Item label="ทะเบียนรถ">
              <Space.Compact
                style={{ width: '100%', margin: 0, padding: 0 }}>
                <Form.Item
                  style={{ width: "55%", marginBottom: 0 }}
                  name={'vehicle'}>
                  <Select
                    showSearch
                    style={{width: "100%"}}
                    autoComplete="off" 
                    filterOption={filterOption}
                    onSelect={(_,data) => {
                      form.setFieldValue('vehicle', data)
                      form.setFieldValue('vehicle_type',data.vehicle_type_name)}
                    }
                    allowClear={"-"}
                    options={vehicles}
                    onClear={() => {
                      form.setFieldValue('vehicle_type', null)
                    }} />
                </Form.Item>

                <Form.Item name={'vehicle_type'} style={{ width: "35%", marginBottom: 0 }}>
                  <Input readOnly placeholder="ประเภทรถ" />
                </Form.Item>

                <Button
                  style={{ width: "10%" }}
                  type={"primary"}
                  icon={<PlusOutlined />}
                  onClick={() => setOpenVehicleModal(true)}>
                </Button>
              </Space.Compact>
            </Form.Item>
          </Col>

          <Col md={24} sm={24} xs={24}>
            <Form.Item  
              name={"customer"}
              label="ชื่อลูกค้า">
              <Select 
                showSearch
                initialvalues={"-"}
                autoComplete="off" 
                // filterOption={filterOption}
                filterOption={false}
                notFoundContent={fetching ? <Spin size="small" /> : "-"}
                onSearch={debounceSupplier}
                options={customers}
                allowClear={"-"}/>
            </Form.Item>
          </Col>

          <Col md={24} sm={24} xs={24} >
            <Form.Item  
              name={"note"}
              label="หมายเหตุ">
                <Input.TextArea autoComplete="off" rows={3}/>
            </Form.Item>
          </Col>

        </Row>
      </Form>
      
      <VehicleModal
        open={openVehicleModal}
        onClose={() => setOpenVehicleModal(false)}
        onUpdate={(data) => {
          setOpenVehicleModal(false)
          fetchVehicle();
          if (data) {
            form.setFieldValue('vehicle', data.id)
            form.setFieldValue('vehicle_type', data.vehicle_type_name)
          }
        }}/>
    </Modal>
  )
}

SIDetailModal.defaultProps = {
  open: false,
  target: null,
  onClose: () => {},
  onUpdate: () => {},
  onDeleted: () => {}
}

SIDetailModal.prototype = {
  open: propTypes.string,
  target: propTypes.object,
  onClose: propTypes.func,
  onUpdate: propTypes.func,
  onDeleted: propTypes.func,
}
