import React from "react";
import propTypes from 'prop-types'
import { Col, Form, Input, Modal, Row, Select, theme, Alert, Spin} from "antd";
import CustomScaleCard from "../common/CustomScaleCard";
import ColorButton from "../common/ColorButton";
import PalletRemoveModal from "./PalletRemoveModal";
import _ from 'lodash'
import { GET, PATCH, POST } from "../../frameworks/HttpClient";
import { URL_PALLET } from "../../constants/urls";
import { PRIMARY_COLOR } from "../../constants/color"
import { EXPORT_STATUS, WEIGHT_UNIT } from "../../constants/strings";


export default function ExportPalletModal(props) {
  const { target, status } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [fetching, setFetching] = React.useState(null)
  const [pallets, setPallets] = React.useState(null);
  const [removePallet, setRemovePallet] = React.useState(null);
  const [deleteSuccess, setDeleteSuccess] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const scaleCardRef = React.useRef();
  // Data for filter pallet usage
  const [debouncedPallet, setDebouncedPallet] = React.useState('')
  const debouncePallet = React.useCallback(
    _.debounce((_searchVal) => {
      setDebouncedPallet(_searchVal)
    }, 800),
    []
  )

	const {
		token: { colorInfo, colorWarning, colorError },
	} = theme.useToken();

  const isCreate = target === 'add'

  /** Fetch pallet usage */
  const fetchPallet = async() => {
    setFetching(true);
    try {
      const response = await GET(URL_PALLET.PALLET_USAGE, { 
        shipping_instruction: 'none', 
        product: props.product,
        is_close : false,
        search: debouncedPallet,
      })
      setPallets(response.data.results.map(item => {
        return(
          {...item,
            label : item.pallet_code,
            value : item.id,
            usage : item.id,
          }
        )}))
    } catch (error) {
      console.log(error.errorMessages)
    } finally {
      setFetching(false);
    }
  }

  /**
   * 1. Validate form
   * 2. Get Weight
   * 3. Update weight for pallet usage
   * 4. Add pallet into SI
   */
  const handleExportPalletSI = async() => {
    setErrorMessages(null)
    let weightData = null;
    let palletID = null;

    try {
      // Check SI
      if (!props.si) {
        setErrorMessages("ไม่พบข้อมูล SI!");
        return
      }

      // Validate data
      const data = await form.validateFields();
      if (data) 
        palletID = data.pallet_name;

      if (scaleCardRef) {
        // Get weight
        weightData = await scaleCardRef.current.getData();
      }

      setLoading(true);
      if (weightData && weightData.weight) {

        if (weightData.hasEdit) {
          // Prepare weight data
          weightData['direction'] = "pallet"
          weightData["unit"] = WEIGHT_UNIT.KILOGRAM
          weightData["usage"] = palletID
          // Update weight
          const result = await handleScaleWeight(weightData);
          if (!result) return;
        }
      } else {
        setErrorMessages('กรุณาชั่งน้ำหนัก!')
        return;
      }

      if (isCreate && palletID) {
        // Add pallet into SI
        await PATCH(`${URL_PALLET.PALLET_USAGE}${palletID}/`, {shipping_instruction: props.si})
      }
      props.onUpdate();
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setLoading(false);
    }
  }

  /* Update pallet usage weight */
  const handleScaleWeight = async(data) => {
    let result = true;
    setErrorMessages(null)
    try {
      await POST(URL_PALLET.PALLET_USAGE_WEIGHTING, data)
    } catch (error) {
      setErrorMessages(error.errorMessages)
      result = false;
    }

    return result;
  }

  React.useEffect(() => {
    if (props.open) {
      form.resetFields();
      if(!isCreate){
        form.setFieldsValue({...target, pallet_name: target.pallet});
        setPallets([{label: target.pallet_code, value: target.pallet}])
      } else {
        if (props.product) {
          fetchPallet();
        }
      }
      
      if(scaleCardRef){
        scaleCardRef.current.resetFields();
      }
    } else {
      setErrorMessages(null);
      setDebouncedPallet("");
    }
  },[props.open])

  React.useEffect(() => {
    if(deleteSuccess){
      props.onUpdate();
      setDeleteSuccess(null)
    }
  },[deleteSuccess])

  React.useEffect(() => {
    if (props.product && props.open)
      fetchPallet();
  }, [debouncedPallet, props.product, props.open])

	return (
		<Modal 
			title={"พาเลท"}
      width={800}
			okButtonProps={{style: {background : colorInfo}}}
			open={props.open}
			onCancel={props.onClose}
      maskClosable={false}
      footer={!props.disabled ? (
        <div>
          {!isCreate && (status === EXPORT_STATUS.PROGRESSING || status === EXPORT_STATUS.CHECKING) && 
          <ColorButton
            loading={loading}           
            type={"primary"} 
            override={colorError}
            style={{ float : 'left' , width: 'auto' }}
            onClick={() => {
              setRemovePallet(target)
            }}>
              ลบพาเลท
          </ColorButton>
          }

          <ColorButton 
            loading={loading}           
            style={{ width: 'auto' }}
            icon={null}
            onClick={props.onClose}>
              ยกเลิก
          </ColorButton>

          {(status === EXPORT_STATUS.PROGRESSING || status === EXPORT_STATUS.CHECKING) && 
          <ColorButton
            loading={loading}           
            type={"primary"} 
            override={!isCreate ? colorWarning : PRIMARY_COLOR }
            style={{ width: 'auto',  marginLeft : 8}}
            icon={null}
            onClick={() => handleExportPalletSI()}>
              {!isCreate ? "แก้ไขข้อมูล" : "เพิ่ม"}
          </ColorButton>
          }
        </div>
      ) : null}>
        {errorMessages && (
          <Alert
            message={errorMessages}
            type='error'
            showIcon
            style={{ marginBottom: '12px', textAlign: 'left' }}
          />
        )}
        <Form
          form={form}
          layout="vertical"
          autoComplete="off">
        <Row gutter={16}>
          <Col md={24} sm={24} xs={24} >
            <Form.Item  
              name={"pallet_name"}
              label="ชื่อพาเลท"
              rules={[{ required: true }]}>
                <Select
                  showSearch
                  style={{width: "100%"}}
                  autoComplete="off" 
                  filterOption={false}
                  notFoundContent={fetching ? <Spin size='small' /> : '-'}
                  onSearch={debouncePallet}
                  options={pallets}
                  allowClear={'-'}
                  onSelect={(value,record) => {
                    form.setFieldsValue(record)
                  }}
                  disabled={isCreate ? false : true}
                />
            </Form.Item>
          </Col>

          <Col md={!isCreate ? 8 : 12} sm={24} xs={24} >
            <Form.Item  
              name={"product_name"}
              label="สินค้า">
                <Input autoComplete="off" disabled/>
            </Form.Item>
          </Col> 

          <Col md={!isCreate ? 8 : 12} sm={24} xs={24} >
            <Form.Item  
              name={"product_grade_name"}
              label="คุณภาพ (Grade)">
                <Input autoComplete="off" disabled/>
            </Form.Item>
          </Col>

          {isCreate && <Col md={8} sm={24} xs={24} >
            <Form.Item  
              name={"pallet_weight"}
              label="น้ำหนักพาเลท(ก่อนขาย)">
                <Input autoComplete="off" disabled/>
            </Form.Item>
          </Col>}

          {isCreate && <Col md={8} sm={24} xs={24} >
            <Form.Item  
              name={"product_weight"}
              label="น้ำหนักยาง(ก่อนขาย)">
                <Input autoComplete="off" disabled/>
            </Form.Item>
          </Col>}

          <Col md={8} sm={24} xs={24} >
            <Form.Item  
              name={"net_weight"}
              label="น้ำหนักรวม(ก่อนขาย)">
                <Input autoComplete="off" disabled/>
            </Form.Item>
          </Col>

        </Row>

        </Form>

        <CustomScaleCard
          ref={scaleCardRef}
          title={'ชั่งพาเลท'}
          demoMode={false}
          showImages={true}
          disabled={props.disabled}
          initialData={(target && target.latest_weighting_ref) ? target.latest_weighting_ref : null}
          showExtraButton={(status && (status === EXPORT_STATUS.PROGRESSING || status === EXPORT_STATUS.CHECKING))}
        />

        <PalletRemoveModal
          open={removePallet ? true : false}
          target={removePallet}
          palletIdField="pallet"
          onClose={() => setRemovePallet(null)}
          onUpdate={() => {
            setRemovePallet(null);
            setDeleteSuccess(true);
          }}
        />
		</Modal>
	)
}

ExportPalletModal.defaultProps = {
  open: false, 
  si: null, // shipping instruction
  target: null,
  status: "",
  product: null,
  onClose: () => {},
  onUpdate: () => {},
  disabled: false,
}

ExportPalletModal.propTypes = {
  open: propTypes.bool,
  si: propTypes.string,
  target: propTypes.oneOfType([
		propTypes.string,
		propTypes.object
	]),
  status: propTypes.string,
  product: propTypes.string,
  onClose: propTypes.func,
  onUpdate: propTypes.func,
  disabled: propTypes.bool,
}