
import React from "react";
import { Routes, Route } from "react-router-dom";
import LoginScreen from "../screens/LoginScreen";
import MenuScreen from "../screens/MenuScreen";
import NotFoundScreen from "../screens/NotFoundScreen";
import PrivateOutlet from "./PrivateOutlet";
import ScaleScreen from "../screens/ScaleScreen";
import ImportProductScreen from "../screens/ImportProductScreen";
import NotifyDetailScreen from "../screens/NotifyDetailScreen";
import NotifyDetailPalletScreen from "../screens/NotifyDetailPalletScreen";
import SupplierRegisterScreen from "../screens/SupplierRegisterScreen"
import ExportProductScreen from "../screens/ExportProductScreen";
import LoadProductSceen from "../screens/LoadProductScreen";

export const PATH = {
  LOGIN: "/login",
  MAIN: "/",
  /** Truck scale */
  TICKETS: "/tickets",
  SCALE: "/scale",
  SCALE_SETTINGS: "/scale-settings",
  VEHICLE: "/vehicles",
  /** Supplier */
  SUPPLIERS: "/suppliers",
  SUPPLIER_DETAIL: "/supplier_detail",
  /** Pallet scale */
  IMPORT_PRODUCTS: "/import-product",
  IMPORT_PRODUCTS_SCALE: '/import-product-scale',
  EXPORT_PRODUCTS: "/export-product",
  EXPORT_PRODUCTS_SCALE: '/export-product-scale',
  /**Notify Scale **/
  NOTIFY_DETAIL: "/notify-detail",
  NOTIFY_DETAIL_PALLERT:"/notify-detail-pallert",
  /** Store */
  INVENTORY: "/inventory",
  PALLET_MANAGEMENT: "/pallet-management",
  /** Loader */
  LOAD_PRODUCT: "/load-product",
  LOAD_PRODUCT_DETAIL: "/load-product-detail",
  /** Reporter */
  REPORT: "/report",
}

export default function CustomRoutes() {
  return (
    <Routes>
      <Route exact path={PATH.LOGIN} element={<LoginScreen/>} />

      <Route path={PATH.MAIN} element={<PrivateOutlet/>}>
        <Route index element={<MenuScreen />} />
        <Route path={`${PATH.SCALE}/:id`} element={<ScaleScreen />}/>
        <Route path={`${PATH.IMPORT_PRODUCTS_SCALE}/:id`} element={<ImportProductScreen />}/>
        <Route path={`${PATH.EXPORT_PRODUCTS_SCALE}/:id`} element={<ExportProductScreen />}/>
        <Route path={`${PATH.SUPPLIER_DETAIL}/:id`} element={<SupplierRegisterScreen />}/>
        <Route path={`${PATH.LOAD_PRODUCT_DETAIL}/:id`} element={<LoadProductSceen />}/>
        <Route path={`${PATH.NOTIFY_DETAIL}/:id`} element={<NotifyDetailScreen />} />
        <Route path={`${PATH.NOTIFY_DETAIL_PALLERT}/:id`} element={<NotifyDetailPalletScreen />} />
        <Route path="/:menu/*" element={<MenuScreen />} />
      </Route>
      
      <Route path="*" element={<NotFoundScreen />} />
    </Routes>
  )
}