import React from 'react'
import _ from 'lodash'
import { Col, Form, Input, Modal, Row, Select, Spin, theme, Alert } from 'antd'
import { GET, POST } from '../../frameworks/HttpClient'
import { URL_PALLET } from '../../constants/urls'
import { PALLET_USAGE_ACTION } from '../../constants/strings'

export default function ExportInventoryModal({ open, onClose, onUpdate }) {
  const [form] = Form.useForm()
  const [errorMessages, setErrorMessages] = React.useState(null)
  const [pallets, setPallets] = React.useState(null)
  const [fetching, setFetching] = React.useState(null)
  const [causeTypes, setCauseTypes] = React.useState(null)

  const [debouncedPallet, setDebouncedPallet] = React.useState('')
  const debouncePallet = React.useCallback(
    _.debounce((_searchVal) => {
      setDebouncedPallet(_searchVal)
    }, 800),
    []
  )

  const [debouncedCauseType, setDebouncedCauseType] = React.useState('')
  const debounceCauseType = React.useCallback(
    _.debounce((_searchVal) => {
      setDebouncedCauseType(_searchVal)
    }, 800),
    []
  )

  const {
    token: { colorWarning },
  } = theme.useToken()

  const handleExport = async () => {
    try {
      const data = await form.validateFields()
      data['action'] = PALLET_USAGE_ACTION.EXPORT
      await POST(URL_PALLET.PALLET_USAGE_TRANSACTION, data)
      onUpdate()
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
  }

  /* Fetch pallet list (in stock) */
  const fetchPallet = async () => {
    try {
      const response = await GET(`${URL_PALLET.PALLET_USAGE}`, {
        is_close: false,
        is_store_editable: "ready",
        search: debouncedPallet,
      })
      setPallets(
        response.data.results.map((item) => {
          return { ...item, label: item.pallet_code, value: item.id }
        })
      )
    } catch (error) {
      console.error(error.errorMessages)
    }
  }

  /* Fetch export cause */
  const fetchCauseType = async () => {
    try {
      const response = await GET(`${URL_PALLET.CAUSE_TYPE}`, {
        action: 'export',
        search: debouncedCauseType,
      })
      setCauseTypes(
        response.data.results.map((item) => {
          return { ...item, label: item.reason, value: item.id }
        })
      )
    } catch (error) {
      console.error(error.errorMessages)
    }
  }

  React.useEffect(() => {
    if (debouncedPallet) fetchPallet()
  }, [debouncedPallet])

  React.useEffect(() => {
    if (debouncedCauseType) fetchCauseType()
  }, [debouncedCauseType])

  React.useEffect(() => {
    if (open) {
      form.resetFields()
      fetchPallet()
      fetchCauseType()
    }
  }, [open])

  return (
    <Modal
      title={'ส่งออกสินค้า'}
      maskClosable={false}
      width={800}
      okText={"ส่งออก"}
      okButtonProps={{ style: { background: colorWarning } }}
      cancelText={"ยกเลิก"}
      centered={false}
      open={open}
      onOk={handleExport}
      onCancel={onClose}>

      {errorMessages && 
        <Alert
          message={errorMessages}
          type="error"
          showIcon
          style={{marginBottom: 16, textAlign: "left"}}
        />
      }

      <Form form={form} layout='vertical' autoComplete='off'>
        <Row gutter={16}>
          <Col md={12} sm={24} xs={24}>
            <Form.Item name={'pallet'} label='ชื่อพาเลท' rules={[{ required: true }]}>
              <Select
                showSearch
                initialvalues={'-'}
                autoComplete='off'
                filterOption={false}
                notFoundContent={fetching ? <Spin size='small' /> : '-'}
                onSearch={debouncePallet}
                onSelect={(_, { note, ...data }) => {
                  form.setFieldsValue(data)
                }}
                options={pallets}
                allowClear={'-'}
              />
            </Form.Item>
          </Col>

          <Col md={12} sm={24} xs={24}>
            <Form.Item name={'cause'} label='ประเภทการส่งออก' rules={[{ required: true }]}>
              <Select
                showSearch
                initialvalues={'-'}
                autoComplete='off'
                filterOption={false}
                notFoundContent={fetching ? <Spin size='small' /> : '-'}
                onSearch={debounceCauseType}
                options={causeTypes}
                allowClear={'-'}
              />
            </Form.Item>
          </Col>

          <Col md={8} sm={24} xs={24}>
            <Form.Item name={'product_name'} label='สินค้า'>
              <Input autoComplete='off' disabled />
            </Form.Item>
          </Col>

          <Col md={8} sm={24} xs={24}>
            <Form.Item name={'product_grade_name'} label='คุณภาพ(grade)'>
              <Input autoComplete='off' disabled />
            </Form.Item>
          </Col>

          <Col md={8} sm={24} xs={24}>
            <Form.Item name={'net_weight'} label='น้ำหนักรวม'>
              <Input autoComplete='off' disabled />
            </Form.Item>
          </Col>

          <Col md={24} sm={24} xs={24}>
            <Form.Item name={'note'} label='หมายเหตุ'>
              <Input.TextArea rows={3} autoComplete='off' />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
