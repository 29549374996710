import React from 'react';
import { Select, theme, Row, Col,Form, Card, List, Space, Typography, Alert, Spin} from 'antd';
import { RightOutlined } from '@ant-design/icons';
// import * as _ from 'lodash';
import { useNavigate, useParams } from "react-router-dom";
import { PATH } from '../routes/CustomRoutes';
import { URL_WEIGHT } from '../constants/urls';
import { GET } from '../frameworks/HttpClient';
import { currencyFormat, dateToHumanString } from '../frameworks/Util';
import { EXPORT_STATUS, EXPORT_STATUS_TH, LOADER_STATUS_OPTIONS } from '../constants/strings';

export default function LoadProductListView() {
  const DEFAULT_FILTER_STATE = 'unfinished_loader'
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { Text } = Typography;
  const [siList, setSIList] = React.useState([]);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [filterStatus, setFilterStatus] = React.useState(DEFAULT_FILTER_STATE);
  const [loading, setLoading] = React.useState(false)

  const {
    token: { colorWarning, colorSuccess },
  } = theme.useToken();

  const fetchSI = async() => {
    setLoading(true)
    try {
      if(filterStatus === 'all'){
        const response = await GET(`${URL_WEIGHT.SHIPPING_INSTRUCTION}?status=${EXPORT_STATUS.PENDING}&status=${EXPORT_STATUS.LOADED}&status=${EXPORT_STATUS.LOADING}`)
        setSIList([...response.data.results])
      } else if (filterStatus) {
        const response = await GET(URL_WEIGHT.SHIPPING_INSTRUCTION, { status : filterStatus })
        setSIList(response.data.results)
      }
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setLoading(false)
    }
  }

  const renderStatusColorText = (status) => {
    if ([EXPORT_STATUS.PENDING, EXPORT_STATUS.LOADING, EXPORT_STATUS.CHECKING].includes(status)) {
      return colorWarning
    }
    else {
      return colorSuccess
    }
  }

  React.useEffect(() => {
    fetchSI();
  },[])

  React.useEffect(() => {
    if(filterStatus){
      fetchSI();
    }
  },[filterStatus])

  // Form layout
  const formItemLayout = {
    labelCol: {
      xs: { span: 5 },
      md: { span: 5 },
      lg: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 19 },
      md: { span: 19 },
      lg: { span: 19 },
    },
  };

  return (
    <div>
      <Spin spinning={loading}>
        <Form
          form={form}
          autoComplete="off"
          {...formItemLayout}>
          {errorMessages && 
            <Alert
              message={errorMessages}
              type="error"
              showIcon
              style={{marginBottom: "12px", textAlign: "left"}}
            />
          }
        
          <Row gutter={[16, 16]} wrap>
            <Col lg={8} md={12} xs={24}>
              <Form.Item
                name={'filterStatus'}
                label={'สถานะ'}
                style={{textAlign: "left"}}>
                <Select 
                  autoComplete="off" 
                  style={{ width : '100%'}} 
                  placeholder="ประเภท" 
                  onSelect={(e) => setFilterStatus(e)} 
                  options={LOADER_STATUS_OPTIONS}
                  defaultValue={DEFAULT_FILTER_STATE}/>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <List
          grid={{
            xxl : 4,
            xl : 3,
            lg : 2,
            md : 1,
            xs : 1,
            sm : 1,
          }}
          size="small"
          dataSource={siList}
          renderItem={(item) => {
            return(
              <Row>
                <List.Item 
                  key={item.id}
                  style={{paddingLeft: 0, paddingRight: 0}}>
                  <Card 
                    size="small" 
                    bordered={true} 
                    title={`${item.code} (${dateToHumanString(item.created)})`} 
                    extra={<RightOutlined />} style={{ width: '100%'}}
                    onClick={() => navigate(`${PATH.LOAD_PRODUCT_DETAIL}/${item.id}/`)}>
                    <Space direction="vertical">
                      <Text>สินค้า : {item.product_name}</Text>
                      <Text>เกรด : {item.product_grade_name}</Text>
                      <Text>ลูกค้า : {item.customer_name}</Text>
                      <Text>ทะเบียนรถ : {item.vehicle_license_plate}</Text>
                      <Text>น้ำหนักสุทธิ : {currencyFormat(item.sum_product_weight)} </Text>
                      <Text>จำนวนแผงที่โหลด : {`${item.number_of_loaded_pallet} of ${item.number_of_pallet}`}</Text>
                      <Text style={{ color: renderStatusColorText(item.status), fontWeight :'bold' }}>สถานะ : {EXPORT_STATUS_TH[item.status]}</Text>
                    </Space>
                  </Card>
                </List.Item>
              </Row>
            )
          }
        }
        />
      </Spin>
    </div>
  );
}
