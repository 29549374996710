import React from "react";
import { Button, Col, Modal, Row, theme } from "antd";
import Lottie from "lottie-react";
import lottieReport from "../assets/lottie_report.json";
import { FileExcelFilled, FilePdfFilled } from '@ant-design/icons';
import { URL_REPORT } from "../constants/urls";
import { REPORT_FILTER } from "../constants/strings";
import ReportModal from "./ReportModal";

export default function ReportsViews() {
  const [targetReport, setTargetReport] = React.useState(null);
  const [loading, setLoading] = React.useState(null);

  const Reports = [
    {
      id: 1, 
      label: "1. รายงานเปรียบเทียบ", 
      detail: "ายงานเปรียบเทียบ",
      filter: [REPORT_FILTER.DATE],
      required: true,
      url: URL_REPORT.TICKET_COMPARE_REPORT,
    },
    {
      id: 2, 
      label: "2. รายงานยอดรับเข้า", 
      detail: "รายงานยอดรับเข้า",
      filter: [REPORT_FILTER.DATE_RANGE, REPORT_FILTER.PRODUCT],
      required: true,
      url: URL_REPORT.PALLETUSAGE_GETIN_REPORT
    },
    {
      id: 3, 
      label: "3. รายงานชั่งยางแผ่น", 
      detail: "รายงานชั่งยางแผ่น",
      filter: [REPORT_FILTER.DATE_RANGE, REPORT_FILTER.PRODUCT, REPORT_FILTER.PRODUCT_GRADE, REPORT_FILTER.CUSTOMER],
      required: true,
      url: URL_REPORT.RUBBERSHEET_REPORT,
    },
  ]

  const {
		token: {colorInfoText}
	} = theme.useToken();

  return (
    <div>
      {targetReport && loading != null &&
				<Modal
					title={
					<>
						Generating - {targetReport.label} { loading == 'pdf' ? 'PDF' : 'Excel' } { loading == 'pdf' ? <FilePdfFilled /> : <FileExcelFilled />}
					</>}
					open={loading != null}
					style={{
						top: 20,
					}}
					closable={false}
					footer={[]}
				>
					<Lottie animationData={lottieReport} />
				</Modal>
			}

      <Row 
        gutter={[16, 16]}
        style={{marginTop: 16, marginBottom: 16}}>
        {Reports.map(item => (
          <Col key={item.id} xl={8} sm={12} xs={24}>
            <Button
              style={{width: "100%", textAlign: "left", borderColor: colorInfoText}}
              disabled={item.url ? false : true}
              onClick={() => setTargetReport(item)}>
                {item.label}
            </Button>
          </Col>
        ))}
      </Row>

      {/* Modal */}
			<ReportModal
				namePrefix={targetReport ? targetReport.label : null}
				open={targetReport ? true : false}
				data={targetReport}
				onStartLoading={(exportType) => setLoading(exportType)}
				onEndLoading={() => setLoading(null)}
				onClose={() => setTargetReport(null)}/>
    </div> 
  )
}
