import React from 'react';
import { Modal ,Tabs } from 'antd-mobile';
import { Input, Layout , theme, Row, Col,Form, Alert, Spin, Result, Button} from 'antd';
import { ORANGE_COLOR,PRIMARY_COLOR } from '../constants/color';
import ColorButton from '../components/common/ColorButton'; 
import { Content } from 'antd/es/layout/layout';
import NotifyDetailTruckScaleView from '../views/NotifyDetailTruckScaleView';
import NotifyDetailPalletScaleView from '../views/NotifyDetailPalletScaleView';
import HeaderMobile from '../components/common/HeaderMobile';
import { useParams } from "react-router-dom";
import { URL_WEIGHT } from '../constants/urls';
import { GET, POST } from '../frameworks/HttpClient';
import { TICKET_ACTION, TICKET_DIRECTION, TICKET_STATUS } from '../constants/strings';
import NotifyDetailSIView from '../views/NotifyDetailSIView';

let currentTab = "1";
export default function NotifyDetailScreen() {
  const params = useParams();
  const [form] = Form.useForm();
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  // Handle Modal
  const [openModalApprove ,setOpenModalApprove] = React.useState(false)
  const [openModalCheck ,setOpenModalCheck] = React.useState(false)
  const [ticketData, setTicketData] = React.useState(null);
  const [activeKey, setActiveKey] = React.useState(currentTab);
  const { TextArea } = Input;
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const handleCheckingButtonClick = async () => {
    try {
      const data = await form.validateFields();
      handleTicketState(TICKET_ACTION.RECHECK, data);
    } catch (error) {}
  };

  /** Change ticket state (approve/recheck) */
  const handleTicketState = async (state, params) => {
    if (!state) {
      setErrorMessages("ไม่พบข้อมูลสถานะที่ต้องการเปลี่ยนแปลง!")
      return;
    }
    if (!ticketData) {
      setErrorMessages("ไม่พบข้อมูลตั๋วชั่ง!")
      return;
    }

    setErrorMessages(null);
    setButtonLoading(true);
    const data = {
      ticket: ticketData.id,
      action: state,
      ...params
    }
    try {
      await POST(URL_WEIGHT.TICKET_TRANSACTION, data);
      // Refresh ticket
      fetchTicketData(ticketData.id)
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setButtonLoading(false);
      if (openModalApprove) 
        setOpenModalApprove(false);
      if (openModalCheck) 
        setOpenModalCheck(false)
    }
  }

  /** Fetch ticket data */
  const fetchTicketData = async (id) => {
    if (!id) {
      setErrorMessages("ไม่พบข้อมูลตั๋วชั่ง!")
      return;
    }

    setErrorMessages(null);
    setLoading(true);
    try {
      const response = await GET(`${URL_WEIGHT.WEIGHT_TICKET}${id}/`);
      setTicketData(response.data);
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }
  
  React.useEffect(() => {
    if (params.id) {
      fetchTicketData(params.id)
    }
  },[params.id])

  const handleTabChange = (key) => {
    setActiveKey(key);
    currentTab = key
  }

  const renderActionButtons = () => {
    return (
      <Row justify="center" gutter={16} style={{ marginTop: 16 }}>
        <>
          <Col md={6} sm={12} xs={12}>
            <ColorButton
              type='primary'
              style={{ width: '100%', height: 40 }}
              onClick={() => setOpenModalApprove(true)}>
              APPROVE
            </ColorButton>
          </Col>

          <Col md={6} sm={12} xs={12}>
            <ColorButton
              type='primary'
              override={ORANGE_COLOR}
              style={{ width: '100%', height: 40 }}
              onClick={() => setOpenModalCheck(true)}>
              CHECKING
            </ColorButton>
          </Col>
        </>
      </Row>
    )
  }

  const renderTabMenus = () => {
    return(
      <Tabs
        style={{
          marginTop: 16,
          "--active-title-color": PRIMARY_COLOR,
          "--active-line-color": PRIMARY_COLOR
        }}
        size='large'
        activeKey={activeKey}
        onChange={handleTabChange}>

        <Tabs.Tab title='ตาชั่งใหญ่' key={'1'}>
          <NotifyDetailTruckScaleView data={ticketData} />
        </Tabs.Tab>

        <Tabs.Tab title='ตาชั่งเล็ก' key='2'>
          <NotifyDetailPalletScaleView
            data={ticketData}
            direction={ticketData ? ticketData.direction : ""}
            onError={(msg) => setErrorMessages(msg)} />
        </Tabs.Tab>

        {ticketData.direction === TICKET_DIRECTION.EXPORT && (
          <Tabs.Tab title='SI' key={'3'}>
            <NotifyDetailSIView
              data={ticketData}
              onError={(msg) => setErrorMessages(msg)} />
          </Tabs.Tab>)
        }
      </Tabs>
    )
  }

  const renderStatusMessage = () => {
    return(
      <Result
      title={`รายการชั่งอยู่ในสถานะ ${ticketData.status}`}
      extra={
        <Button type="primary" onClick={() => {
          // Try to close window automatically
          window.opener = null;
          window.open("", "_self");
          window.close();
        }}>
          Close
        </Button>
      }
      />
    )
  }

  return (
    <Layout>
      <HeaderMobile 
        showBackIcon={false}
        title={`${ticketData ? ticketData.code : "-"}`}/>
      
      <Spin tip="โหลดข้อมูล" spinning={loading}>
        <Content 
          style={{ 
            margin: '0px 24px 24px 24px', 
            textAlign: 'left',  
            minHeight: 280,
            background: colorBgContainer 
          }}>
          
          {errorMessages && 
            <Alert
              message={errorMessages}
              type="error"
              showIcon
              style={{marginTop: "16px", textAlign: "left"}}
            />
          }

          {/* Action Button */}
          {ticketData && (ticketData.status === TICKET_STATUS.PENDING) &&
            renderActionButtons()}
          
          {/* Tabs menu */}
          {ticketData && (ticketData.status === TICKET_STATUS.PENDING) &&
            renderTabMenus()
          }

          {ticketData && (ticketData.status !== TICKET_STATUS.PENDING) && 
            renderStatusMessage()
          }

          {/* Approve Modal */}
          <Modal
            title='Approve'
            showCloseButton={true}
            visible={openModalApprove}
            onClose={() => setOpenModalApprove(false)}
            content={
              <div style={{ textAlign: 'center' }}>
                ยืนยันอนุมัติหรือไม่
                <ColorButton 
                  loading={buttonLoading}
                  type='primary'
                  override={PRIMARY_COLOR}  
                  style={{ width: '100%', height: 40 ,marginTop:16}}
                  onClick={() => handleTicketState(TICKET_ACTION.APPROVE, {})}>
                  อนุมัติ
                </ColorButton>
              </div>
            }/>

          {/* Recheck Modal */}
          <Modal 
            title='Checking' 
            showCloseButton={true}
            visible={openModalCheck}
            onClose={() => {
              form.resetFields();
              setOpenModalCheck(false);
            }}
            content={
              <Form form={form} layout="vertical">
                <Form.Item
                  label="เหตุผล"
                  name={'note'}
                  rules={[{ required: true, message: 'กรุณากรอกเหตุผล' }]}>
                    <TextArea/>
                </Form.Item>

                <ColorButton 
                  type='primary'
                  override={ORANGE_COLOR}  
                  style={{ width: '100%', height: 40 }}
                  onClick={() => handleCheckingButtonClick()}>
                    ตรวจสอบอีกครั้ง
                  </ColorButton>
              </Form>
            }/>
        </Content>
      </Spin>
    </Layout>
  );
}
