import React from 'react'
import propTypes from 'prop-types'
import { Alert, Col, Form, Input, Modal, Row, Select, Spin, theme } from 'antd'
import CustomScaleCard from '../common/CustomScaleCard'
import ColorButton from '../common/ColorButton'
import _ from 'lodash'
import { GET, PATCH, POST } from '../../frameworks/HttpClient'
import { URL_PALLET, URL_PRODUCT } from '../../constants/urls'
import { PALLET_STATUS, WEIGHT_UNIT, PALLET_USAGE_ACTION } from '../../constants/strings'
import CancelPalletModal from '../pallet/CancelPalletModal'
import { currencyFormat } from '../../frameworks/Util'

export default function ImportPalletModal(props) {
  const { target, ticket } = props
  const [form] = Form.useForm()
  const scaleRef = React.useRef()
  const [loading, setLoading] = React.useState(false)
  const [fetching, setFetching] = React.useState(null)
  const [deleteTarget, setDeleteTarget] = React.useState(null)
  const [totalWeight, setTotalWeight] = React.useState(null)
  const [weightPallet, setWeightPallet] = React.useState(null)
  const [errorMessages, setErrorMessages] = React.useState(null)
  const [deleteSuccess, setDeleteSuccess] = React.useState(null)
  // Options
  const [products, setProducts] = React.useState([])
  const [productGrades, setProductGrades] = React.useState([])
  const [pallets, setPallets] = React.useState([])
  const [debouncedPallet, setDebouncedPallet] = React.useState('')
  const debouncePallet = React.useCallback(
    _.debounce((_searchVal) => {
      setDebouncedPallet(_searchVal)
    }, 800),
    []
  )

  const {
    token: { colorPrimary, colorWarning, colorError },
  } = theme.useToken()

  const handleImportPallet = async () => {
    try {
      let weightData = null
      // Prepare data
      let data = await form.validateFields()
      if (ticket.subticket) {
        data['subticket'] = ticket.subticket.id
      }

      if (scaleRef) {
        weightData = await scaleRef.current.getData()
        weightData['unit'] = WEIGHT_UNIT.KILOGRAM
      }
      // Validate weight scale
      if (!weightData || !weightData.weight) {
        setErrorMessages('กรุณาชั่งน้ำหนัก!')
        return
      }

      setErrorMessages(null)
      setLoading(true)
      if (props.target) {
        // Update palletUsage
        await PATCH(`${URL_PALLET.PALLET_USAGE}${target.pallet}/`, data)
        // Add weighting palletUsage
        if (weightData.hasEdit)
          await POST(URL_PALLET.PALLET_USAGE_WEIGHTING, { ...weightData, usage: target.pallet })
      } else {
        // Create palletUsage
        await POST(URL_PALLET.PALLET_USAGE, { ...data, weighting_ref: weightData })
      }
      props.onUpdate()
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setLoading(false)
    }
  }

  /** Fetch Options: grade of product */
  const fetchProductGrade = async (id) => {
    if (!id) {
      setErrorMessages('ไม่พบข้อมูลสินค้า!')
      return
    }

    setErrorMessages(null)
    try {
      const response = await GET(URL_PRODUCT.PRODUCT_GRADE, { product: id, page_size: 999 })
      setProductGrades(
        response.data.results.map((item) => {
          return { ...item, label: item.name, value: item.id }
        })
      )
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
  }

  /** Fetch Options: empty pallet */
  const fetchPallet = async () => {
    try {
      setFetching(true)
      const response = await GET(URL_PALLET.PALLET, {
        status: PALLET_STATUS.AVAILABLE,
        search: debouncedPallet,
      })
      setPallets(
        response.data.results.map((item) => {
          return { ...item, label: item.code, value: item.id }
        })
      )
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setFetching(false)
    }
  }

  /** Filter options */
  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  React.useEffect(() => {
    if (deleteSuccess) {
      props.onUpdate()
      setDeleteSuccess(null)
    }
  }, [deleteSuccess])

  React.useEffect(() => {
    if (debouncedPallet) fetchPallet()
  }, [debouncedPallet])

  React.useEffect(() => {
    if (props.open) {
      if (scaleRef) {
        scaleRef.current.resetFields()
      }

      // Initial value
      if (ticket) {
        form.setFieldsValue({
          product: ticket.product,
          subticket: ticket.subticket.id,
        })
        setProducts([{ value: ticket.product, label: ticket.product_name }])
        fetchProductGrade(ticket.product)
      } else {
        setErrorMessages('ไม่พบข้อมูลตั๋วชั่ง!')
        return
      }

      if (target) {
        form.setFieldsValue(target)
        setPallets([{ value: target.ref, label: target.pallet_code }])
        setTotalWeight(target.net_weight)
        setWeightPallet(target.pallet_weight)
      } else {
        fetchPallet()
      }
    } else {
      form.resetFields()
      setWeightPallet(null)
      setTotalWeight(null)
      setErrorMessages(null)
    }
  }, [props.open])

  React.useEffect(() => {
    if (totalWeight && weightPallet) {
      const product = totalWeight - parseFloat(weightPallet)
      form.setFieldValue('product_weight', currencyFormat(product))
    } else {
      form.setFieldValue('product_weight', null)
    }
  }, [totalWeight, weightPallet])

  return (
    <Modal
      title={'พาเลท'}
      open={props.open}
      width={800}
      onCancel={props.onClose}
      maskClosable={false}
      footer={
        !props.readOnly ? (
          <>
            {props.target && (
              <ColorButton
                loading={loading}
                type={'primary'}
                override={colorError}
                style={{ float: 'left', width: 'auto' }}
                onClick={() => setDeleteTarget(target)}
              >
                ลบพาเลท
              </ColorButton>
            )}

            <ColorButton
              loading={loading}
              style={{ width: 'auto' }}
              icon={null}
              onClick={props.onClose}
            >
              ยกเลิก
            </ColorButton>

            <ColorButton
              loading={loading}
              type={'primary'}
              override={props.target ? colorWarning : colorPrimary}
              style={{ width: 'auto' }}
              icon={null}
              onClick={() => handleImportPallet()}
            >
              {props.target ? 'แก้ไข' : 'เพิ่มพาเลท'}
            </ColorButton>
          </>
        ) : null
      }
    >
      {errorMessages && (
        <Alert
          message={errorMessages}
          type='error'
          showIcon
          style={{ marginBottom: 16, textAlign: 'left' }}
        />
      )}

      <Form form={form} layout='vertical' autoComplete='off'>
        <Row gutter={16}>
          <Col md={12} sm={24} xs={24}>
            <Form.Item name={'product'} label='สินค้า' rules={[{ required: true }]}>
              <Select disabled options={products} />
            </Form.Item>
          </Col>

          <Col md={12} sm={24} xs={24}>
            <Form.Item name={'product_grade'} label='คุณภาพ (Grade)' rules={[{ required: true }]}>
              <Select
                initialvalues={'-'}
                autoComplete='off'
                filterOption={filterOption}
                options={productGrades}
                disabled={props.readOnly}
                allowClear={'-'}
              />
            </Form.Item>
          </Col>

          <Col md={8} sm={24} xs={24}>
            <Form.Item name={'ref'} label='ชื่อพาเลท' rules={[{ required: true }]}>
              <Select
                showSearch
                autoComplete='off'
                filterOption={false}
                notFoundContent={fetching ? <Spin size='small' /> : '-'}
                onSearch={debouncePallet}
                onSelect={(_, data) => {
                  form.setFieldValue('pallet_weight', data.base_weight)
                  setWeightPallet(data.base_weight)
                }}
                options={pallets}
                disabled={props.target ? true : false}
              />
            </Form.Item>
          </Col>

          <Col md={8} sm={24} xs={24}>
            <Form.Item name={'pallet_weight'} label='น้ำหนักพาเลท'>
              <Input
                type={'number'}
                autoComplete='off'
                onChange={(e) => setWeightPallet(e.target.value)}
                disabled={props.readOnly}
              />
            </Form.Item>
          </Col>

          <Col md={8} sm={24} xs={24}>
            <Form.Item
              name={'product_weight'}
              label='น้ำหนักสินค้า'
              rules={[{ required: !props.target }]}
            >
              <Input autoComplete='off' disabled />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <CustomScaleCard
        ref={scaleRef}
        title={'ชั่งพาเลท'}
        initialData={target && target.latest_weighting_ref ? target.latest_weighting_ref : null}
        onClickScaleButton={(e) => setTotalWeight(e)}
        showImages={true}
        showExtraButton={!props.readOnly}
      />

      <CancelPalletModal
        open={deleteTarget}
        action={PALLET_USAGE_ACTION.CLOSE}
        onClose={() => setDeleteTarget(null)}
        target={deleteTarget}
        palletIdField='pallet'
        onUpdate={() => {
          setDeleteTarget(null)
          setDeleteSuccess(true)
        }}
      />
    </Modal>
  )
}

ImportPalletModal.defaultProps = {
  open: false,
  target: null, // data of pallet
  ticket: null, // data of ticket (required)
  readOnly: false,
  onClose: () => {},
  onUpdate: () => {},
}

ImportPalletModal.prototype = {
  open: propTypes.bool,
  target: propTypes.object,
  ticket: propTypes.object,
  readOnly: propTypes.bool,
  onClose: propTypes.func,
  onUpdate: propTypes.func,
}
