import React from 'react'
import _ from 'lodash'
import { Card, Form, Image, Input, List, Modal, theme } from 'antd'
import propTypes from 'prop-types'
import fallbackImage from '../../assets/fallbackImage.png'
import moment from 'moment'
import { createImage } from '../../frameworks/CropUtil'
import axios from 'axios'
import { THIN_CLIENT_BASE_URL, URL_THIN_CLIENT } from '../../constants/urls'

// TODO: remove `skipModal`, and `scalePallet` when not testing
const skipModal = false

const InputWeightModal = React.forwardRef((props, ref) => {
  const { open, onClose, onWeighting, scalePallet } = props
  const [loading, setLoading] = React.useState(false)
  const [images, setImages] = React.useState([])
  const [form] = Form.useForm()

  const weightInputStyle = {
    height: 80,
    fontSize: 80,
    fontFamily: 'Digital7',
    color: '#FDF5E6',
    textAlign: 'center',
    textShadow: '0 0 3px #FFFFE0, 0 0 5px #FFFFFF',
  }

  const {
    token: { colorInfo },
  } = theme.useToken()

  /** Call weight from thin client */
  // ยังไม่ได้เทสนะ
  const openWeight = async () => {
    await axios
      .get(`${THIN_CLIENT_BASE_URL}${URL_THIN_CLIENT.WEIGHT_MEASURE}`)
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        // Handle error
        console.log('......... error')
        console.log(err)
      })
  }

  const convertImage = async (url) => {
    const img = await createImage(url)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    canvas.width = img.width
    canvas.height = img.height
    ctx.drawImage(img, 0, 0)
    return { image: canvas.toDataURL('image/png') }
  }

  const randomImages = async (count) => {
    await _.range(count).forEach(async (i) => {
      const img = await convertImage(
        `https://picsum.photos/200?random=${new Date().getUTCMilliseconds() + i}`
      )
      setImages((oldImages) => [...oldImages, img])
    })
  }

  const getWeightingData = async () => {
    try {
      form.setFieldValue('weight', Math.floor(Math.random() * (scalePallet ? 100 : 10000)))
      // openWeight()
      await randomImages(3)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    if (open) {
      setLoading(true)
      if (skipModal) {
        form.setFieldValue('weight', Math.floor(Math.random() * (scalePallet ? 100 : 10000)))
        // openWeight()
        onWeighting(form.getFieldValue('weight'), moment().toISOString(), images)
        onClose()
      } else {
        getWeightingData()
      }
    } else {
      form.resetFields()
      setImages([])
    }
  }, [open])

  return (
    !skipModal && (
      <Modal
        title='ชั่งน้ำหนัก'
        width={600}
        open={open}
        cancelText={'ยกเลิก'}
        onCancel={() => {
          onClose()
        }}
        confirmLoading={loading}
        okText={'OK'}
        okButtonProps={{ style: { background: colorInfo } }}
        onOk={() => {
          onWeighting(form.getFieldValue('weight'), moment().toISOString(), images)
          onClose()
        }}
      >
        <Form form={form} layout='vertical' autoComplete='off'>
          <Card
            title={`น้ำหนักรวม`}
            style={{ textAlign: 'center' }}
            headStyle={{ background: '#E0E0E0', color: '#000000' }}
            bodyStyle={{ background: '#101010', padding: 16 }}
          >
            <Form.Item name={'weight'} style={{ marginBottom: 0 }}>
              <Input
                type='number'
                min={0}
                bordered={false}
                readOnly={false}
                onWheel={(e) => e.target.blur()}
                onFocus={(e) => e.target.select()}
                style={weightInputStyle}
              />
            </Form.Item>
          </Card>
          <List
            style={{ marginTop: 10 }}
            grid={{
              gutter: 10,
              column: 3,
            }}
            itemLayout='horizontal'
            dataSource={images}
            renderItem={(item, index) => (
              <List.Item key={index}>
                <Image
                  src={item.image}
                  height={'auto'}
                  width={'auto'}
                  fallback={fallbackImage}
                  placeholder={
                    <Image src={fallbackImage} preview={false} height={'auto'} width={'auto'} />
                  }
                />
              </List.Item>
            )}
          />
        </Form>
      </Modal>
    )
  )
})

InputWeightModal.defaultProps = {
  open: false,
  onClose: null,
}

InputWeightModal.propTypes = {
  open: propTypes.bool,
  onClose: propTypes.func,
}

export default InputWeightModal
