import React from 'react'
import propTypes from 'prop-types'
import { Col, Alert, Form, InputNumber, Modal, Row, Select, Spin, theme } from 'antd'
import CustomScaleCard from '../common/CustomScaleCard'
import ColorButton from '../common/ColorButton'
import _ from 'lodash'
import { GET, POST, PATCH } from '../../frameworks/HttpClient'
import { URL_PALLET, URL_PRODUCT } from '../../constants/urls'
import { PALLET_STATUS, PALLET_USAGE_ACTION } from '../../constants/strings'
import { currencyFormat } from '../../frameworks/Util'
import CancelPalletModal from '../pallet/CancelPalletModal'

export default function ImportInventoryModal(props) {
  const { target } = props
  const scaleRef = React.useRef()
  const [form] = Form.useForm()
  const [errorMessages, setErrorMessages] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [fetching, setFetching] = React.useState(null)
  const [deleteTarget, setDeleteTarget] = React.useState(null)
  const [products, setProducts] = React.useState([])
  const [causeTypes, setCauseTypes] = React.useState([])
  const [pallets, setPallets] = React.useState([])
  const [productGrades, setProductGrades] = React.useState([])

  /** Filter options */
  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  const [debouncedCauseType, setDebouncedCauseType] = React.useState('')
  const debounceCauseType = React.useCallback(
    _.debounce((_searchVal) => {
      setDebouncedCauseType(_searchVal)
    }, 800),
    []
  )

  const [debouncedPallet, setDebouncedPallet] = React.useState('')
  const debouncePallet = React.useCallback(
    _.debounce((_searchVal) => {
      setDebouncedPallet(_searchVal)
    }, 800),
    []
  )

  const {
    token: { colorWarning, colorError, colorPrimary },
  } = theme.useToken()

  const isCreate = target === 'add'
  const denyEditProduct = target && (target.subticket || target.shipping_instruction)
  
  const isJustTransfer = () => {
    return (
      target &&
      target.latest_transaction &&
      target.latest_transaction.action === PALLET_USAGE_ACTION.TRANSFER
    )
  }

  /* Import product */
  const handleImportPallet = async () => {
    setLoading(true);
    try {
      setErrorMessages(null)
      let data = await form.validateFields()
      data['weighting_ref'] = await scaleRef.current.getData()
      await POST(URL_PALLET.PALLET_USAGE, data)
      props.onUpdate()
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setLoading(false);
    }
  }

  /* Edit Pallet weight */
  const handleEditPallet = async () => {
    setLoading(true);
    try {
      setErrorMessages(null)
      let data = await form.validateFields()
      await PATCH(`${URL_PALLET.PALLET_USAGE}${target.id}/`, data)
      const weighting_ref = await scaleRef.current.getData()
      if (weighting_ref.hasEdit)
        await POST(URL_PALLET.PALLET_USAGE_WEIGHTING, {
          usage: target.id,
          ...weighting_ref,
        })
      props.onUpdate()
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setLoading(false);
    }
  }

  /* Fetch Product list */
  const fetchProduct = async () => {
    try {
      if (denyEditProduct) {
        setProducts([{ label: target.product_name, value: target.product }])
        return
      }
      const response = await GET(URL_PRODUCT.PRODUCT)
      setProducts(response.data.results.map((item) => ({ label: item.name, value: item.id })))
    } catch (error) {
      console.log(error.errorMessages)
    }
  }

  /* Fetch grade list of product */
  const fetchProductGrade = async (id) => {
    try {
      const response = await GET(`${URL_PRODUCT.PRODUCT_GRADE}`, { product: id})
      setProductGrades(
        response.data.results.map((item) => ({ ...item, label: item.name, value: item.id }))
      )
      console.log(response.data.results)
    } catch (error) {
      console.log(error.errorMessages)
    }
  }

  /* Fech pallet list (pallet available) */
  const fetchPallet = async () => {
    try {
      if (!isCreate) {
        setPallets([{ label: target.pallet_code, value: target.ref }])
        return
      }

      setFetching(true)
      const response = await GET(`${URL_PALLET.PALLET}`, {
        status: PALLET_STATUS.AVAILABLE,
        search: debouncedPallet,
      })
      setPallets(
        response.data.results.map((item) => ({
          ...item,
          label: item.code,
          value: item.id,
        }))
      )
    } catch (error) {
      console.error(error.errorMessages)
    } finally {
      setFetching(false)
    }
  }

  /* Fetch import cause */
  const fetchCauseType = async () => {
    try {
      if (!isCreate) {
        return
      }
      const response = await GET(`${URL_PALLET.CAUSE_TYPE}`, {
        action: 'import',
        search: debouncedCauseType,
      })
      setCauseTypes(
        response.data.results.map((item) => ({
          ...item,
          label: item.reason,
          value: item.id,
        }))
      )
    } catch (error) {
      console.log(error.errorMessages)
    }
  }

  const validatePositiveNumber = (_, value) => {
    if (value >= 0) {
      return Promise.resolve()
    }
    return Promise.reject(new Error('กรุณาใส่ตัวเลขให้ถูกต้อง'))
  }

  React.useEffect(() => {
    if (props.open) {
      form.resetFields()
      if (scaleRef) scaleRef.current.resetFields()
      if (!isCreate) form.setFieldsValue(target)
      // fetch data
      fetchPallet()
      fetchProduct()
      isCreate && fetchCauseType()
      !isCreate && target.product && fetchProductGrade(target.product)
    }
  }, [props.open])

  React.useEffect(() => {
    if (debouncedPallet) fetchPallet()
  }, [debouncedPallet])

  React.useEffect(() => {
    if (debouncedCauseType) fetchCauseType()
  }, [debouncedCauseType])

  return (
    <>
      <Modal
        title={isCreate ? "รับเข้าสินค้า" : "พาเลท"}
        width={800}
        open={props.open}
        onCancel={props.onClose}
        maskClosable={false}
        footer={!props.readOnly ? (
          <>
            {!isCreate && (
              <ColorButton
                loading={loading}
                type={'primary'}
                override={colorError}
                style={{ float: 'left', width: 'auto' }}
                onClick={() => setDeleteTarget(target)}>
                  รีเซ็ต
              </ColorButton>
            )}

            <ColorButton 
              loading={loading} 
              style={{ width: 'auto' }} 
              icon={null} 
              onClick={props.onClose}>
                ยกเลิก
            </ColorButton>

            <ColorButton
              loading={loading}
              type={'primary'}
              override={!isCreate ? colorWarning : colorPrimary}
              style={{ width: 'auto' }}
              icon={null}
              onClick={() => {
                isCreate ? handleImportPallet() : handleEditPallet()
              }}>
                {!isCreate ? 'แก้ไข' : 'รับเข้า'}
            </ColorButton>
          </>
        ) : null}>

        {errorMessages && 
          <Alert
            message={errorMessages}
            type="error"
            showIcon
            style={{marginBottom: 16, textAlign: "left"}}
          />
        }

        <Form form={form} layout='vertical' autoComplete='off'>
          <Row gutter={16}>
            <Col md={isCreate ? 12 : 24} sm={24} xs={24}>
              <Form.Item name={'ref'} label='ชื่อพาเลท' rules={[{ required: true }]}>
                <Select
                  showSearch
                  autoComplete='off'
                  filterOption={false}
                  notFoundContent={fetching ? <Spin size='small' /> : '-'}
                  onSearch={debouncePallet}
                  onChange={(_, option) =>
                    form.setFieldValue('pallet_weight', option && option.base_weight)
                  }
                  options={pallets}
                  allowClear={'-'}
                  disabled={!isCreate}
                />
              </Form.Item>
            </Col>

            {isCreate && (
              <Col md={12} sm={24} xs={24}>
                <Form.Item
                  name={'cause_type'}
                  label='ประเภทการรับเข้า'
                  rules={[{ required: true }]}>
                  <Select
                    showSearch
                    autoComplete='off'
                    filterOption={false}
                    notFoundContent={fetching ? <Spin size='small' /> : '-'}
                    onSearch={debounceCauseType}
                    options={causeTypes}
                    allowClear={'-'}
                  />
                </Form.Item>
              </Col>
            )}

            <Col md={8} sm={24} xs={24}>
              <Form.Item name={'product'} label='สินค้า' rules={[{ required: true }]}>
                <Select
                  showSearch
                  autoComplete='off'
                  filterOption={filterOption}
                  options={products}
                  disabled={denyEditProduct}
                  onChange={(value, option) => {
                    fetchProductGrade(value)
                    form.setFieldValue('product_grade', null)
                  }}
                />
              </Form.Item>
            </Col>

            <Col md={8} sm={24} xs={24}>
              <Form.Item name={'product_grade'} label='คุณภาพ (Grade)' rules={[{ required: true }]}>
                <Select
                  autoComplete='off'
                  showSearch
                  filterOption={filterOption}
                  options={productGrades}
                  disabled={!form.getFieldValue('product')}
                />
              </Form.Item>
            </Col>

            <Col md={8} sm={24} xs={24}>
              <Form.Item
                name={'pallet_weight'}
                label='น้ำหนักแผง'
                rules={[{ required: true, validator: validatePositiveNumber }]}>
                <InputNumber
                  autoComplete='off'
                  formatter={currencyFormat}
                  style={{ width: '100%' }}
                  disabled={!isCreate}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        {isJustTransfer() && (
          <Alert
            style={{ marginBottom: 10 }}
            closeIcon={false}
            message={
              'แบ่งพาเลท - ไปยัง ' +
              target.latest_transaction.transfer_ref.destination_code +
              ` (${currencyFormat(target.latest_transaction.transfer_ref.weight)} กก.)` +
              ` (คงเหลือน้ำหนัก ${currencyFormat(target.net_weight)} กก.)`
            }
            type='warning'
            showIcon
            closable/>
        )}

        <CustomScaleCard
          ref={scaleRef}
          title={'ชั่งพาเลท'}
          initialData={(target && target.latest_weighting_ref) 
            ? target.latest_weighting_ref : null}
          scalePallet={true}
          showImages={true}
          disabled={props.readOnly}
        />
      </Modal>

      <CancelPalletModal
        open={deleteTarget}
        action={PALLET_USAGE_ACTION.RESET}
        onClose={() => {
          setDeleteTarget(null)
        }}
        target={target}
        onUpdate={async () => {
          setDeleteTarget(null)
          props.onUpdate()
        }}/>
    </>
  )
}

ImportInventoryModal.defaultProps = {
  open: false,
  target: null,
  readOnly: false,
  onClose: () => {},
  onUpdate: () => {}
}

ImportInventoryModal.propTypes = {
  open: propTypes.bool,
  target: propTypes.oneOfType([
		propTypes.string,
		propTypes.object
	]),
  readOnly: propTypes.bool,
  onClose: propTypes.func,
  onUpdate: propTypes.func,
}
