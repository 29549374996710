export const URL_AUTH = {
  API_TOKEN_AUTH: '/api/users/api-token-auth/',
  SIGN_OUT: '/api/users/sign-out/',
  CHANGE_PASSWORD: '/api/users/change-password/',
}

export const URL_SUPPLIER = {
  SUPPLIER: '/api/supplier/supplier/',
  OWNER: '/api/supplier/owner/',
  SUPPLIER_TYPE: '/api/supplier/supplier-type/',
  SUPPLIER_DOCUMENT: '/api/supplier/supplier-document/',
}

export const URL_COMMON = {
  PROVINCE: '/api/common/province/',
  DISTRICT: '/api/common/district/',
  SUB_DISTRICT: '/api/common/sub-district/',
  LOCATION_SEARCH: '/api/common/location-search/',
}

export const URL_WEIGHT = {
  VEHICLE_TYPE: '/api/weight/vehicle-type/',
  VEHICLE: '/api/weight/vehicle/',
  WEIGHT_TICKET: '/api/weight/weightticket/',
  TICKET_WEIGHTING: '/api/weight/ticket-weighting/',
  TICKET_TRANSACTION: '/api/weight/ticket-transaction/',
  SHIPPING_INSTRUCTION: '/api/weight/shipping-instruction/',
  SI_TRANSACTION: '/api/weight/si-transaction/',
  SUBTICKET_SET_NOTE: '/api/weight/subticket-set-note/',
}

export const URL_PALLET = {
  PALLET: '/api/pallet/pallet/',
  PALLET_WEIGHTING: '/api/pallet/pallet-weighting/',
  PALLET_USAGE: '/api/pallet/pallet-usage/',
  CAUSE_TYPE: '/api/pallet/cause-type/',
  PALLET_USAGE_TRANSACTION: '/api/pallet/palletusage-transaction/',
  PALLET_USAGE_WEIGHTING: '/api/pallet/palletusage-weighting/',
  PALLET_USAGE_TRANSFER: '/api/pallet/palletusage-transfer/',
  PALLET_USAGE_SET_LOAD: '/api/pallet/palletusage-set-load/',
  PALLET_USAGE_SNAPSHOT: '/api/pallet/palletusage-snapshot/',
}

export const URL_PRODUCT = {
  PRODUCT: '/api/product/product/',
  PRODUCT_GRADE: '/api/product/product-grade/',
}

export const THIN_CLIENT_BASE_URL = 'http://localhost:9099'
export const URL_THIN_CLIENT = {
  WEIGHT_MEASURE: '/api/weight/measure/',
  PRINT: '/api/weight/print/',
}

export const URL_REPORT = {
  WEIGHT_TICKET_REPORT: '/api/report/weightticket-report/',
  SUBTICKET_REPORT: '/api/report/subticket-report/',
  SUBTICKET_DELIVERY_REPORT: "/api/report/subticket-delivery-report/",
  TICKET_COMPARE_REPORT: "/api/report/ticket-compare-report/",
  PALLETUSAGE_GETIN_REPORT: "/api/report/palletusage-getin-report/",
  RUBBERSHEET_REPORT: "/api/report/rubbersheet-report/",
}
