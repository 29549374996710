import React from "react";
import propTypes from "prop-types";
import dayjs from "dayjs";
import TSTable from "../components/common/TSTable";
import { theme, Space, Button, Row, Col } from 'antd'
import { Content } from "antd/es/layout/layout";
import { PlusCircleOutlined, FilePdfOutlined, FileExcelOutlined } from '@ant-design/icons';
import ColorButton from '../components/common/ColorButton';
import { SECONDARY_COLOR } from '../constants/color';
import { PATH } from "../routes/CustomRoutes";
import { useNavigate } from "react-router-dom";
import { URL_SUPPLIER } from "../constants/urls";
import { DATETIME_FORMAT, HUMAN_DATETIME_FORMAT } from "../constants/strings";

export default function SupplierListView(props) {
  const navigate = useNavigate();
  const tableRef = React.useRef(null);

  const {
		token: { colorPrimary },
	} = theme.useToken();

  const columns = [
    {
      title: "รหัสลูกค้า",
      dataIndex: "code",
      width: 200
    },
    {
      title: "ชื่อลูกค้า",
      dataIndex: "name",
      searcher: true,
      width: 300
    },
    {
      title: "ที่อยู่",
      dataIndex: "full_address",
    },
    {
      title: "ใบอนุญาตค้ายาง",
      dataIndex: "license_trade_number",
      width: 200
    }]

  return(
    <Content>
      {!props.readOnly && (
        <div style={{textAlign:'center'}}>
          <ColorButton 
            type={"primary"} 
            override={SECONDARY_COLOR}
            icon={<PlusCircleOutlined />} 
            style={{float: "right", background: colorPrimary, marginBottom: 16}}
            onClick={() => navigate({pathname: `${PATH.SUPPLIER_DETAIL}/add/`})}>
              ลูกค้า
          </ColorButton>
        </div>
      )}

      <Space style={{ float: 'left' }}>
        <Row gutter={8}>
          <Col>
            <Button
              icon={<FilePdfOutlined />}
              onClick={async () => {
                await tableRef.current.exportPDF({
                  title: "รายการลูกค้า",
                  subtitle: `ดึงข้อมูล ณ​ วันที่ ${dayjs().format(HUMAN_DATETIME_FORMAT)}`,
                  landscape: true
                })
              }}>
              PDF
            </Button>
          </Col>
          <Col>
            <Button
              icon={<FileExcelOutlined />}
              onClick={async () => {
                await tableRef.current.exportXlsx({
                  title: "รายการลูกค้า",
                  subtitle: `ดึงข้อมูล ณ​ วันที่ ${dayjs().format(HUMAN_DATETIME_FORMAT)}`,
                  filename: `รายการลูกค้า - ${dayjs().format(DATETIME_FORMAT)}.xlsx`
                })
              }}>
              Excel
            </Button>
          </Col>
        </Row>
      </Space>

      <TSTable 
        columns={columns}
        url={URL_SUPPLIER.SUPPLIER}
        ref={tableRef}
        rowKey={"id"}
        size={"small"}
        pageSize={10}
        onRow={(record, rowIndex) => ({
          onClick: _ => navigate(`${PATH.SUPPLIER_DETAIL}/${record.id}/`)
        })}/>

    </Content>
  )
}

SupplierListView.defaultProps = {
  readOnly: false,
}

SupplierListView.prototype = {
  readOnly: propTypes.bool,
}