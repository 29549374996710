import React from 'react'
import { Button, Card, Col, Form, Image, Input, List, Row, notification } from 'antd'
import propTypes from 'prop-types'
import InputWeightModal from './InputWeightModal'
import fallbackImage from '../../assets/fallbackImage.png'
import moment from 'moment'
import useThinClient from '../../hooks/useThinClient'
import { HUMAN_DATETIME_FORMAT } from '../../constants/strings'
import { currencyFormat } from '../../frameworks/Util'

const CustomScaleCard = React.forwardRef((props, ref) => {
  const [api, contextHolder] = notification.useNotification();
  const [open, setOpen] = React.useState(false)
  const [hasEdit, setHasEdit] = React.useState(false)
  const [showThinClientMeasureWeight, loading] = useThinClient()
  // Data
  const [scaleWeight, setScaleWeight] = React.useState(0)
  const [scaleDate, setScaleDate] = React.useState(null)
  const [scaleImages, setScaleImages] = React.useState([])

  const setData = (weightData, dateData, imagesData) => {
    setScaleWeight(weightData)
    setScaleDate(dateData ? dateData : null)
    setScaleImages(imagesData)
  }

  React.useImperativeHandle(ref, () => ({
    getData: async () => {
      return {
        hasEdit: hasEdit,
        datetime: scaleDate ? scaleDate : null,
        weight: scaleWeight ? parseFloat(scaleWeight.toString()) : 0.0,
        images: scaleImages,
      }
    },
    resetFields: async () => {
      initialScaleCard(props.initialData);
    },

    resetData: async () => {
      initialScaleCard(props.initialData);
    },
  }))
  
  const initialScaleCard = (data) => {
    if (data) {
      setData(data.weight, data.created, data.images)
    } else {
      setData(0.00, null, [])
    }
    setHasEdit(false);
  }

  const onWeightCompleted = (weight, date, images) => {
    setData(weight, date, images)
    setHasEdit(true)
    props.onClickScaleButton(weight)
  }

  const handleInputWeight = async () => {
    if (props.demoMode) {
      setOpen(true)
    }
    else {
      const weightData = await showThinClientMeasureWeight()
      if(weightData != null && weightData.status === 200) {
        onWeightCompleted(
          weightData.weight, 
          weightData.date, 
          weightData.images
          .filter(data => data[0] === true)   // isSuccess
          .map(data => {return {image:`data:image/jpeg;base64,${data[1]}`}})  // image
        )
      }
      else {
        api.error({
          message: 'ThinClient communication error',
          description: 'เกิดข้อผิดพลาดในการเชื่อมต่อกับ ThinClient',
          placement: "bottomRight"
        })
      }
    }
  }

  React.useEffect(() => {
    initialScaleCard(props.initialData);
  }, [props.initialData])

  return (
    <>
      {contextHolder}
      <Form layout='vertical' autoComplete='off'>
        <Card
          size='small'
          title={props.title}
          extra={
            props.showExtraButton && (
              <Button
                type='link'
                style={{ padding: 0 }}
                onClick={handleInputWeight}
                disabled={props.disabled}
              >
                รับน้ำหนัก
              </Button>
            )
          }
          style={{ width: 'auto', textAlign: 'left' }}
        >
          <Row gutter={16}>
            <Col md={12} sm={24} xs={24}>
              <Form.Item label='วันที่ - เวลา'>
                <Input
                  autoComplete='off'
                  disabled={true}
                  value={scaleDate ? moment(scaleDate).format(HUMAN_DATETIME_FORMAT) : ''}
                />
              </Form.Item>
            </Col>

            <Col md={12} sm={24} xs={24}>
              <Form.Item label='น้ำหนัก'>
                <Input autoComplete='off' disabled={true} value={currencyFormat(scaleWeight)} />
              </Form.Item>
            </Col>
          </Row>

          {props.showImages && (
            <Image.PreviewGroup>
              <List
                grid={{
                  gutter: 10,
                  column: 4,
                  md: 4,
                  xs: 2,
                  sm: 2,
                }}
                dataSource={scaleImages || []}
                renderItem={(item, index) => (
                  <List.Item key={index} style={{ textAlign: 'center' }}>
                    <Image
                      src={item.image}
                      height={'auto'}
                      width={'auto'}
                      fallback={fallbackImage}
                      placeholder={
                        <Image src={fallbackImage} preview={false} height={'auto'} width={'auto'} />
                      }
                    />
                  </List.Item>
                )}
              />
            </Image.PreviewGroup>
          )}
        </Card>
      </Form>

      {props.demoMode &&
        <InputWeightModal
          open={open}
          onClose={() => {
            setOpen(false)
          }}
          scalePallet={props.scalePallet}
          onWeighting={onWeightCompleted}
        />
      }
    </>
  )
})

CustomScaleCard.defaultProps = {
  title: '', // title card
  initialData: null, // for intial data
  showExtraButton: true, // show button input weight
  scalePallet: false,
  onClickScaleButton: () => {},
  showImages: false,
  disabled: false,
  demoMode: false,
}

CustomScaleCard.propTypes = {
  title: propTypes.string,
  initialData: propTypes.object,
  showExtraButton: propTypes.bool,
  scalePallet: propTypes.bool,
  onClickScaleButton: propTypes.func,
  showImages: propTypes.bool,
  disabled: propTypes.bool,
  demoMode: propTypes.bool,
}

export default CustomScaleCard
