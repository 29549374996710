export const DATE_FORMAT = 'YYYY-MM-DD'
export const HUMAN_DATE_FORMAT = 'DD/MM/YYYY'
export const TIME_FORMAT = 'HH:mm'
export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm'
export const HUMAN_DATETIME_FORMAT = 'DD/MM/YYYY HH:mm'
export const HUMAN_DATETIME_2_DIGIT_FORMAT = 'DD/MM/YY HH:mm'
export const MONTH_FORMAT = 'YYYY-MM'
export const TEST_TXT = "test"

export const USER_GROUP = {
  TRUCK_SCALE: 'truck_scale',
  PALLET_SCALE: 'pallet_scale',
  SUPPLIER_MANAGER: 'supplier_manager',
  SALE: 'sale',
  PRODUCT_LOADER: 'product_loader',
  REPORTER: "reporter",
}

export const TICKET_STATUS = {
  UN_FINISHED: 'unfinished',
  FINISHED: 'finished',
  PROGRESSING: 'progressing',
  CHECKING: 'checking',
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECTED: 'rejected',
}

export const TICKET_STATUS_NAME = {
  [TICKET_STATUS.UN_FINISHED]: 'อยู่ระหว่างดำเนินการ',
  [TICKET_STATUS.FINISHED]: 'ดำเนินการแล้ว',
  [TICKET_STATUS.PROGRESSING]: 'กำลังดำเนินการ',
  [TICKET_STATUS.CHECKING]: 'รอตรวจสอบ',
  [TICKET_STATUS.PENDING]: 'กำลังตรวจสอบ',
  [TICKET_STATUS.APPROVED]: 'อนุมัติ',
  [TICKET_STATUS.REJECTED]: 'ยกเลิก',
}

export const TICKET_STATUS_OPTIONS = [
  {
    key: TICKET_STATUS.UN_FINISHED,
    value: TICKET_STATUS.UN_FINISHED,
    label: TICKET_STATUS_NAME[TICKET_STATUS.UN_FINISHED],
  },
  {
    key: TICKET_STATUS.APPROVED,
    value: TICKET_STATUS.APPROVED,
    label: TICKET_STATUS_NAME[TICKET_STATUS.APPROVED],
  },
  {
    key: TICKET_STATUS.REJECTED,
    value: TICKET_STATUS.REJECTED,
    label: TICKET_STATUS_NAME[TICKET_STATUS.REJECTED],
  },
]

export const TICKET_ACTION = {
  APPROVE: 'approve', // Change pending -> approved
  REJECT: 'reject', // Change progressing -> rejected
  RECHECK: 'recheck', // Change pending -> checking
  SUBMIT: 'submit', // Change checking -> pending)
}

export const PALLET_STATUS = {
  AVAILABLE: 'available',
  IN_USE: 'in_use',
  CANCELED: 'canceled',
}

export const PALLET_STATUS_TRANSLATION = {
  [PALLET_STATUS.AVAILABLE]: 'ว่าง',
  [PALLET_STATUS.IN_USE]: 'มีสินค้า',
  [PALLET_STATUS.CANCELED]: 'ปิดการใช้งาน',
}

export const PALLET_USAGE_ACTION = {
  IMPORT: 'import',
  EXPORT: 'export',
  CLOSE: 'close',
  RESET: 'reset',
  TRANSFER: 'transfer',
  RECEIVE: 'receive',
  EDIT: 'edit',
}

export const SI_TRANSACTION_ACTION = {
  LOAD: 'load',
  READY: 'ready',
  UNREADY: 'unready',
  EXPORT: 'export',
  RECHECK: 'recheck',
  SUBMIT: 'submit'
}

export const EXPORT_STATUS = {
  UN_FINISHED: 'unfinished',
  UN_FINISHED_LOADER: 'unfinished_loader',
  PROGRESSING: 'progressing',
  LOADING: 'loading',
  LOADED: 'loaded',
  PENDING: 'pending',
  EXPORTED: 'exported',
  CHECKING: 'checking',
}

export const EXPORT_STATUS_TH = {
  unfinished: 'อยู่ระหว่างดำเนินการ',
  progressing: 'เตรียมสินค้า',
  loading: 'รอโหลดสินค้า',
  loaded: 'โหลดสินค้าแล้ว',
  pending: 'รอตรวจสอบ',
  exported: 'เสร็จสิ้น',
  checking: 'ตรวจสอบสินค้า',
}

export const EXPORT_STATUS_OPTIONS = [
  { key: 1, value: '', label: 'รายการทั้งหมด' },
  { key: 2, value: EXPORT_STATUS.UN_FINISHED, label: EXPORT_STATUS_TH.unfinished },
  { key: 3, value: EXPORT_STATUS.PROGRESSING, label: EXPORT_STATUS_TH.progressing },
  { key: 4, value: EXPORT_STATUS.LOADING, label: EXPORT_STATUS_TH.loading },
  { key: 5, value: EXPORT_STATUS.LOADED, label: EXPORT_STATUS_TH.loaded },
  { key: 6, value: EXPORT_STATUS.PENDING, label: EXPORT_STATUS_TH.pending },
  { key: 7, value: EXPORT_STATUS.EXPORTED, label: EXPORT_STATUS_TH.exported },
  { key: 8, value: EXPORT_STATUS.CHECKING, label: EXPORT_STATUS_TH.checking },
]

export const LOADER_STATUS_OPTIONS = [
  { key: 1, value: 'all', label: 'รายการทั้งหมด' },
  { key: 2, value: EXPORT_STATUS.UN_FINISHED_LOADER, label: EXPORT_STATUS_TH.unfinished },
  { key: 3, value: EXPORT_STATUS.LOADING, label: EXPORT_STATUS_TH.loading },
  { key: 4, value: EXPORT_STATUS.PENDING, label: EXPORT_STATUS_TH.pending },
  { key: 5, value: EXPORT_STATUS.LOADED, label: EXPORT_STATUS_TH.loaded },
  { key: 6, value: EXPORT_STATUS.CHECKING, label: EXPORT_STATUS_TH.checking },
]

export const VEHICLE_TYPE = {
  TRUCK: 'truck',
  PICKUP: 'pickup',
  SIDECAR: 'sidecar',
  MOTORCYCLE: 'motorcycle',
  OTHER: 'other',
}

export const VEHICLE_TYPE_TRANSLATION = {
  [VEHICLE_TYPE.TRUCK]: 'รถบรรทุก',
  [VEHICLE_TYPE.PICKUP]: 'รถกระบะ',
  [VEHICLE_TYPE.SIDECAR]: 'รถพ่วงข้าง',
  [VEHICLE_TYPE.MOTORCYCLE]: 'รถจักรยานยนต์',
  [VEHICLE_TYPE.OTHER]: 'อื่นๆ',
}

export const DOCUMENT_TYPE = {
  TRADE: 'trade',
  FACTORY: 'factory',
  ECO: 'eco',
  LICENSE: 'license',
  BOOK_BANK: 'book_bank',
}

export const WEIGHT_UNIT = {
  GRAM: 'gram',
  KILOGRAM: 'kilogram',
  TONNE: 'tonne',
  LITRE: 'litre',
}

export const TICKET_DIRECTION = {
  IMPORT: 'import',
  EXPORT: 'export',
  PALLET: 'pallet',
}

export const TICKET_DIRECTION_NAME = {
  [TICKET_DIRECTION.IMPORT]: 'ชั่งซื้อ',
  [TICKET_DIRECTION.EXPORT]: 'ชั่งขาย',
  [TICKET_DIRECTION.PALLET]: 'ชั่งพาเลท',
}

export const REPORT_FILTER = {
  DATE: "date",
  DATE_RANGE: "dateRange",
  PRODUCT: "product",
  PRODUCT_GRADE: "product_grade",
  CUSTOMER: "customer"
}
