import React from "react";
import { Alert, Col, Form, Input, InputNumber, Layout, message, Modal, Popover, Row, Space, Spin, theme, Tooltip, notification } from "antd";
import HeaderScreen from "../components/common/HeaderScreen";
import { useNavigate, useParams } from "react-router-dom";
import { 
  EditOutlined, 
  InfoCircleOutlined, 
  ExclamationCircleOutlined, 
  PlusCircleOutlined 
} from '@ant-design/icons';
import { PATH } from "../routes/CustomRoutes";
import { PRIMARY_COLOR, PRIMARY_LIGHT_COLOR } from "../constants/color";
import { Content } from "antd/es/layout/layout";
import TSTable from "../components/common/TSTable";
import moment from "moment";
import { HUMAN_DATETIME_FORMAT, TICKET_ACTION, TICKET_DIRECTION, WEIGHT_UNIT } from "../constants/strings";
import ColorButton from "../components/common/ColorButton";
import ImportPalletModal from "../components/menuImport/ImportPalletModal";
import {TICKET_STATUS} from '../constants/strings'
import { currencyFormat, formatComma } from "../frameworks/Util";
import { GET, PATCH, POST } from "../frameworks/HttpClient";
import { URL_PALLET, URL_REPORT, URL_WEIGHT } from "../constants/urls";
import RejectPurchaseModal from "../components/pallet/RejectPurchaseModal";
import useThinClient from "../hooks/useThinClient";
import TextArea from "antd/es/input/TextArea";

export default function ImportProductScreen() {
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const params = useParams();
  const inputRef = React.useRef();
  const textAreaRef = React.useRef();
  // For thin client
  const [showThinClientMeasureWeight, _] = useThinClient();
  const [api, contextHolder] = notification.useNotification();
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [editValue, setEditValue] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const tableRef = React.useRef(null);
  const [ticketData, setTicketData] = React.useState(null);
  const [target, setTarget] = React.useState(null);
  const [openPalletModal, setPalletModal] = React.useState(false);
  const [openRejectPurchaseModal, setOpenRejectPurchaseModal] = React.useState(false);
  const [disableScalePallet, setDisableScalePallet] = React.useState(false);
  const [disableWeight, setDisableWeight] = React.useState(false)
  const [openScale, setOpenScale] = React.useState(null);

  const {
		token: { colorBgContainer, colorInfo, colorError, colorWarning },
	} = theme.useToken();

  const fetchTicketData = async() => {
    setLoading(true)
    try {
      const response = await GET(`${URL_WEIGHT.WEIGHT_TICKET}${id}/`)
      setTicketData(response.data)
      form.setFieldsValue({...response.data, sum_product_weight : response.data.sum_product_weight})
      form.setFieldValue('total_pallet_weight',response.data.subticket.total_pallet_weight)
      form.setFieldValue("note_subticket", response.data.subticket.note);
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false)
    }
  }


  const columns = [
    {
      title: "วันที่ - เวลา",
      dataIndex: "pallet_created",
      render: (value) => moment(value).format(HUMAN_DATETIME_FORMAT),
      width: 200,
      sorter: true,
    },
    {
      title: "ชื่อพาเลท",
      dataIndex: "pallet_code",
      sorter: true,
    },
    {
      title: "สินค้า",
      dataIndex: "product_name",
    },
    {
      title: "คุณภาพ(Grade)",
      dataIndex: "product_grade_name",
    },
    {
      title: "น้ำหนักรวม",
      dataIndex: "net_weight",
      render : (value) => {
        return (currencyFormat(value))
      },
    },
    {
      title: "น้ำหนักพาเลท",
      dataIndex: "pallet_weight",
      render: (value, original) => 
      <div>
      {currencyFormat(value)}
      <a onClick={(e) => e.stopPropagation()}>
      {!disableWeight && 
        <Popover
          content={(
            <Space.Compact>
              <InputNumber formatter={(value) => formatComma(value)} value={editValue} onChange={(value) => setEditValue(value)} />
              <ColorButton
                type='primary'
                loading={loading}
                override={colorWarning}
                icon={<EditOutlined />}
                onClick={() => {
                  handleWeightUpdate(original)
                }}
              >
                แก้ไข
              </ColorButton>
            </Space.Compact>
          )}
          title='แก้ไขน้ำหนักพาเลท'
          placement='right'
          trigger='click'
        // open={editDRCOpen == original.id}
        // onOpenChange={(open) => handleOpenEditDetail(setEditDRCOpen, open ? original.id : null, _.get(value, 'drc', 0))}
        >
          {disableScalePallet === false && <ColorButton
            type='link'
            size='mediem'
            style={{ color: colorWarning }}
            icon={<EditOutlined />}
            onClick={(e) => e.stopPropagation()}
          // onClick={() => handleOpenEditDetail(setEditDRCOpen, original.id, _.get(value, 'drc', 0))} 
          />}
        </Popover>
      }
      </a>
    </div>
    },
    {
      title: "น้ำหนักสุทธิ",
      dataIndex: "product_weight",
      render : (value) => {
        return (currencyFormat(value))
      },
    }]

  const updateTotalPalletObj = (weight, images) => {
    form.setFieldValue('total_pallet_weight', weight);
    const data = {
      ticket : ticketData.id, 
      direction : TICKET_DIRECTION.PALLET, 
      created : new Date(), 
      weight : weight, 
      unit : WEIGHT_UNIT.KILOGRAM, 
      images : images
    }

    handleScalePallet(data)
  }

  const handleScalePallet = async(obj) => {
    let data = obj
    try {
      if (!data) {
        // case manual input
        let totalPalletWeight = await form.getFieldValue('total_pallet_weight');
        if (totalPalletWeight >= 0) {
          data = {
            ticket: ticketData.id, 
            direction: TICKET_DIRECTION.PALLET, 
            created: new Date(), 
            weight: totalPalletWeight, 
            unit: WEIGHT_UNIT.KILOGRAM, 
            images: [] 
          }
        } else {
          setErrorMessages('กรุณากรอกน้ำหนักพาเลทรวม!');
          return
        }
      }

      setErrorMessages(null);
        await POST(URL_WEIGHT.TICKET_WEIGHTING, data)
        inputRef.current.blur();
        message.success('บันทึกน้ำหนักสำเร็จ')
        fetchTicketData();
    } catch (error) {
      setErrorMessages(error.errorMessages);
    }
  }

  // Note for subticket
  const handleSubticketNote = async() => {
    if (!ticketData || !ticketData.subticket) {
      setErrorMessages("ไม่พบข้อมูลตั๋วชั่งเล็ก!");
      return;
    }

    let data = null
    setErrorMessages(null);
    try {
      const note = await form.getFieldValue('note_subticket');
      await PATCH(`${URL_WEIGHT.SUBTICKET_SET_NOTE}${ticketData.subticket.id}/`, {note: note})
      textAreaRef.current.blur();
      message.success('บันทึกหมายเหตุสำเร็จ')
      fetchTicketData();
    } catch (error) {
      setErrorMessages(error.errorMessages);
    }
  }

  const validatePositiveNumber = (_, value) => {
    if (value >= 0 ) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('กรุณาใส่ตัวเลขให้ถูกต้อง'));
  };

  const refreshData = () => {
    if (tableRef.current) {
      tableRef.current.fetch();
    }
  }

  /** Clear all pallet */
  const handleCancelAllPallet = async() => {
    if (!id) {
      setErrorMessages("ไม่พบข้อมูลตั๋วชั่ง!")
      return;
    }

    setErrorMessages(null);
    setLoading(true);
    try {
      await POST(`${URL_WEIGHT.WEIGHT_TICKET}${id}/clear_pallets/`)  
      refreshData()
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setLoading(false);
    }
  }

  const handleWeightUpdate = async(record) => {
    try {
      const data = {pallet_weight : editValue}
      await PATCH(`${URL_PALLET.PALLET_USAGE}${record.pallet}/`, data)
      refreshData()
      fetchTicketData()
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
  }

  /** For change status (checking -> pending) */
  const submitAction = async () => {
    if (!ticketData) {
      setErrorMessages("ไม่พบข้อมูลตั๋วชั่ง!");
      return;
    }
    
    setErrorMessages(null);
    setLoading(true);
    try {
      await POST(
        URL_WEIGHT.TICKET_TRANSACTION, 
        {ticket: ticketData.id, action: TICKET_ACTION.SUBMIT});
      navigate(-1);
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  const handlePrint = async() => {
    setLoading(true)
    try {
      // Fetch the PDF file
      const response = await GET(`${URL_REPORT.SUBTICKET_REPORT}${ticketData.id}/`, {}, false, true);      
      await window.open(URL.createObjectURL(response.data), '_blank');

    } catch (error) {
      console.error('Error fetching PDF:', error);
    } finally {
      setLoading(false)
    }
  };

  /** Handle Thin client model **/
  const showThinClient = async () => {
    const weightData = await showThinClientMeasureWeight();
    if(weightData != null && weightData.status === 200) {
      updateTotalPalletObj(
        weightData.weight, 
        weightData.images
        .filter(data => data[0] === true) // isSuccess
        .map(data => {return {image:`data:image/jpeg;base64,${data[1]}`}}) // image
      )
    }
    else {
      api.error({
        message: 'ThinClient communication error',
        description: 'เกิดข้อผิดพลาดในการเชื่อมต่อกับ ThinClient',
        placement: "bottomRight"
      })
    }
  }

  React.useEffect(() => {
    if (openScale) {
      showThinClient();
    }
  }, [openScale])

  React.useEffect(() => {
    fetchTicketData();
  },[])

  React.useEffect(() => {
    if(ticketData){
      setDisableScalePallet((ticketData.status === TICKET_STATUS.APPROVED || ticketData.status === TICKET_STATUS.REJECTED))
      setDisableWeight(ticketData.status === TICKET_STATUS.PENDING)
      if (ticketData.status === TICKET_STATUS.REJECTED) {
        setErrorMessages(`ปฎิเสธการรับสินค้า - ${ticketData.latest_transaction_note}`)
      }
    }
  },[ticketData])

  return(
    <Layout style={{ minHeight: "100vh", background: PRIMARY_LIGHT_COLOR }}>
    <HeaderScreen onClick={() => navigate(PATH.IMPORT_PRODUCTS)} title={ticketData ? `ชั่งซื้อ [${ ticketData.code ? ticketData.code : ticketData.customer_name}]` : ''} />
    <Spin spinning={loading}>
    <Content
      className="main-content"
      style={{ 
      margin: "0px 24px 24px 24px", 
      padding: "16px 24px",
      textAlign: "left",  
      minHeight: 600,
      background: colorBgContainer }}>
    {errorMessages && 
      <Alert
        message={errorMessages}
        type="error"
        showIcon
        style={{textAlign: "left"}}
      />
    }
    {ticketData && ticketData.status === TICKET_STATUS.CHECKING &&
      <Alert
        message="ตรวจสอบน้ำหนัก"
        icon={<InfoCircleOutlined />}
        description={ticketData.latest_transaction_note}
        type="warning"
        showIcon
      />}

    <Form
      form={form}
      layout="vertical"
      autoComplete="off">
      <Row gutter={16} style={{ marginTop : 16}}>
        <Col md={8} sm={24} xs={24}>
          <Form.Item  
            name={"product_name"}
            label="สินค้า">
              <Input autoComplete="off" disabled/>
          </Form.Item>
        </Col>

        <Col md={10} sm={24} xs={24}>
          <Form.Item  
            name={"customer_name"}
            label="ลูกค้า">
              <Input autoComplete="off" disabled/>
          </Form.Item>
        </Col>

        <Col md={6} sm={24} xs={24}>
          <Form.Item  
            name={"vehicle_license_plate"}
            label="ทะเบียนรถ">
              <Input autoComplete="off" disabled/>
          </Form.Item>
        </Col>

        <Col md={12} sm={24} xs={24}>
          <Form.Item  
            name={"note_subticket"}
            label="หมายเหตุ (ตาชั่งเล็ก)">
              <TextArea 
                ref={textAreaRef}
                autoComplete="off" 
                disabled={disableScalePallet}
                onPressEnter={() => handleSubticketNote()}/>
          </Form.Item>
        </Col>

        <Col md={12} sm={24} xs={24}>
          <Form.Item  
            name={"note"}
            label="หมายเหตุ (ตาชั่งใหญ่)">
              <TextArea autoComplete="off" disabled/>
          </Form.Item>
        </Col>

        <Col md={4} sm={24} xs={24}>
          <Form.Item label="น้ำหนักพาเลทรวม (Kgs)">
            <Space.Compact style={{ width: '100%' }}>
              <Form.Item  
                name={"total_pallet_weight"}
                rules={[{ required: true, validator: validatePositiveNumber }]}>
                <Input 
                  ref={inputRef}
                  status={ticketData && ticketData.pallet_diff == 0 ? '' : "warning"}
                  prefix={ticketData && ticketData.pallet_diff == 0 ? '' : 
                  <Tooltip title={"น้ำหนักพาเลทไม่ตรงกัน"}>
                    <ExclamationCircleOutlined />
                  </Tooltip>
                }
                  autoComplete="off" 
                  type={"number"}
                  onPressEnter={() => handleScalePallet()}
                  disabled={disableScalePallet}/>
              </Form.Item>
              <ColorButton 
                type="primary" 
                override={ticketData && ticketData.pallet_diff === 0 ? colorInfo : colorWarning} 
                onClick={() => setOpenScale(true)} disabled={disableScalePallet || disableWeight}>
                {(ticketData == null || ticketData.pallet_diff > 0) &&
                  <Tooltip title={"น้ำหนักพาเลทไม่ตรงกัน"}>
                    <ExclamationCircleOutlined />
                  </Tooltip>
                }
                  ชั่ง
                </ColorButton>
            </Space.Compact>
          </Form.Item>
        </Col>

        <Col md={4} sm={24} xs={24}>
          <Form.Item  
            name={"snapshot_sum_product_weight"}
            label="น้ำหนักสุทธิ(ตาชั่งเล็ก)">
              <InputNumber formatter={(value) => currencyFormat(value)} style={{width : '100%'}} disabled/>
          </Form.Item>
        </Col>

        <Col md={4} sm={24} xs={24}>
          <Form.Item  
            name={"snapshot_net_weight"}
            label="น้ำหนักสุทธิ(ตาชั่งใหญ่)">
              <InputNumber formatter={(value) => currencyFormat(value)} style={{width : '100%'}} disabled/>
          </Form.Item>
        </Col>

        <Col md={4} sm={24} xs={24}>
          <Form.Item  
            name={"snapshot_product_diff"}
            label="ส่วนต่าง(Kgs)">
              <InputNumber formatter={(value) => currencyFormat(value)} style={{width : '100%'}} disabled/>
          </Form.Item>
        </Col>

        {ticketData && (ticketData.status === TICKET_STATUS.PENDING || ticketData.status === TICKET_STATUS.PROGRESSING || ticketData.status === TICKET_STATUS.CHECKING) && 
        <Col md={8} sm={24} xs={24}>
          <div style={{ marginTop: 32, textAlign: "right"}}>
            <ColorButton
              type="default"
              disabled={disableWeight}
              onClick={() => {
                Modal.confirm({
                  title: 'ยืนยันการยกเลิกพาเลททั้งหมด',
                  content: 'คุณต้องการยกเลิกพาเลททั้งหมดใช่หรือไม่ ?',
                  okText: 'ยืนยัน',
                  okButtonProps: {style: { background : colorError}},
                  onOk: () => handleCancelAllPallet(),
                  cancelText: 'ยกเลิก',
                  maskClosable: true,
                });
              }}
              override={colorError}
              style={{marginRight: 16}}>
              ยกเลิกพาเลททั้งหมด
            </ColorButton>
            <ColorButton
              type="primary"
              disabled={disableWeight}
              icon={<PlusCircleOutlined />} 
              onClick={() => setPalletModal(true)}
              override={PRIMARY_COLOR}>
              เพิ่มพาเลท
            </ColorButton>
          </div>
        </Col>}
      </Row>

      {ticketData && <TSTable
        ref={tableRef}
        columns={columns}
        url={URL_PALLET.PALLET_USAGE_SNAPSHOT}
        params={{ ticket: id, subticket: ticketData.subticket.id, is_active: true}}
        rowKey={"id"}
        size={"small"}
        pageSize={10}
        onRow={(record, rowIndex) => ({
          onClick: _ => setTarget(record)
        })}
      />}
    </Form>

    <div className="button-bottom-page">
      <Row gutter={16} style= {{ height: '100%', marginButtom : 16}}>
        <Col>
          {ticketData && (ticketData.status === TICKET_STATUS.PENDING || ticketData.status === TICKET_STATUS.PROGRESSING) && <ColorButton
            disabled={disableWeight}
            loading={loading}           
            type={"primary"} 
            override={colorError}
            style={{ width: 'auto', marginLeft : 10}}
            icon={null}
            onClick={() => {
              setOpenRejectPurchaseModal(true)
            }}>
              ปฏิเสธการรับสินค้า
          </ColorButton>}
          {ticketData && ticketData.status === TICKET_STATUS.CHECKING && 
            <ColorButton
              loading={loading}           
              type={"primary"} 
              override={colorWarning}
              style={{ width: 'auto', marginLeft : 10}}
              onClick={submitAction}>
                ยืนยันการแก้ไขข้อมูล
            </ColorButton>}
          
            {ticketData && ticketData.status === TICKET_STATUS.APPROVED &&
              <ColorButton
                loading={loading}           
                type={"primary"} 
                override={colorInfo}
                style={{ width: '100px', marginLeft : 10}}
                icon={null}
                onClick={() => {
                  Modal.confirm({
                    title: 'พิมพ์ตั๋วชั่ง',
                    icon: <InfoCircleOutlined style={{ color : colorInfo}}/>,
                    content: `คุณต้องการพิมพ์ตั๋วชั่งใช่หรือไม่ ?`,
                    okText: 'ยืนยัน',
                    okButtonProps: {style: { background : colorInfo}},
                    maskClosable: true,
                    onOk: () => {
                      handlePrint()
                    },
                    cancelText: 'ยกเลิก',
                  });
                }}>
                  {"พิมพ์"}
              </ColorButton>
            }
        </Col>
      </Row>
    </div>
    </Content>

    <ImportPalletModal
      open={(openPalletModal || target) ? true : false}
      ticket={ticketData}
      target={target}
      readOnly={disableScalePallet}
      onClose={() => (target ? setTarget(null) : setPalletModal(false))}
      onUpdate={() => {
        target ? setTarget(null) : setPalletModal(false)
        // Refresh data
        refreshData();
        fetchTicketData();
      }}/>

      <RejectPurchaseModal 
        open={openRejectPurchaseModal}
        targetId={id}
        onClose={() => setOpenRejectPurchaseModal(false)}
        onRejected={() => navigate({ pathname: `${PATH.IMPORT_PRODUCTS}` })}
      />
    </Spin>
    </Layout>
  )
}