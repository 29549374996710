import React from "react"
import propTypes from "prop-types";
import { List } from 'antd-mobile';
import {Input, Form, Row, Col } from 'antd';
import { useNavigate } from "react-router-dom";
import { PATH } from "../routes/CustomRoutes";
import { GET } from "../frameworks/HttpClient";
import { URL_PALLET, URL_WEIGHT } from "../constants/urls";
import { currencyFormat } from "../frameworks/Util";

const NotifyDetailSIView = React.forwardRef((props, ref) => {
  const [form] = Form.useForm();
  const [trigger, setTrigger] = React.useState(false);

  /* Fetch si detail */
  const fetchSI = async (id) => {
    if (!id) {
      props.onError("ไม่พบหมายเลข SI!");
      return;
    }

    props.onError(null);
    try {
      const response = await GET (`${URL_WEIGHT.SHIPPING_INSTRUCTION}${id}/`)
      form.setFieldsValue(response.data);
      setTrigger(!trigger);
    } catch (error) {
      props.onError(error.errorMessages)
    }
  }

  React.useEffect(() => {
    if (props.data) {
      fetchSI(props.data.si_ref)
    } else {
      form.resetFields();
    }
  }, [props.data]);

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off">

      <Row gutter={24}>
        <Col md={8} sm={24} xs={24}>
          <Form.Item  
            name={"code"}
            label="หมายเลข SI">
            <Input autoComplete="off" disabled/>
          </Form.Item>
        </Col>
    
        <Col md={8} sm={24} xs={24}>
          <Form.Item  
            name={"customer_name"}
            label="ลูกค้า">
              <Input autoComplete="off" disabled />
          </Form.Item>
        </Col>

        <Col md={8} sm={24} xs={24}>
          <Form.Item  
            name={"product_name"}
            label="สินค้า">
              <Input autoComplete="off" disabled />
          </Form.Item>
        </Col>

        <Col md={8} sm={24} xs={24}>
          <Form.Item  
            name={"vehicle_license_plate"}
            label="ทะเบียนรถ">
              <Input autoComplete="off" disabled />
          </Form.Item>
        </Col>

        <Col md={8} sm={24} xs={24}>
          <Form.Item 
            label="น้ำหนักขาย">
              <Input 
                disabled
                autoComplete="off"  
                value={currencyFormat(form.getFieldValue("goal_weight"))}/>
          </Form.Item>
        </Col>
        <Col md={24} sm={24} xs={24}>
          <Form.Item 
            name={"note"}
            label="หมายเหตุ">
              <Input.TextArea 
                disabled
                autoComplete="off"  />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
});
export default React.memo(NotifyDetailSIView);

NotifyDetailSIView.defaultProps = {
  data: null,
  onError: (msg) => {},
}

NotifyDetailSIView.prototype = {
  data: propTypes.object,
  onError: propTypes.func,
}