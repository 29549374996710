import React from "react";
import { Alert, Col, Descriptions, Divider, Form, Input, List, Row, Space, Spin, theme } from "antd";
import { Checkbox, Button } from 'antd-mobile'
import HeaderScreen from "../components/common/HeaderScreen";
import { Content } from "antd/es/layout/layout";
import { useNavigate, useParams } from "react-router-dom";
import { EXPORT_STATUS } from "../constants/strings";
import { PATH } from "../routes/CustomRoutes";
import { GET, POST, PATCH } from "../frameworks/HttpClient";
import { URL_WEIGHT, URL_PALLET } from "../constants/urls";
import { SI_TRANSACTION_ACTION } from "../constants/strings";

export default function LoadProductSceen() {
  const navigate = useNavigate();
  const params = useParams();
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [loadData, setLoadData] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [palletData, setPalletData] = React.useState([])
  const [selectedPallet, setSelectedPallet] = React.useState([])

  const {
		token: { colorBgContainer },
	} = theme.useToken();


  const fetchLoadData = async() => {
    setLoading(true)
    try {
      const response = await GET(`${URL_WEIGHT.SHIPPING_INSTRUCTION}${params.id}`)
      if(response.data.latest_load_transaction){
        setSelectedPallet(response.data.latest_load_transaction.detail.pallets)
      }
      setLoadData(response.data);
      await fetchPallet();
      
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setLoading(false)
    }
  }

  const fetchPallet = async() => {
    try {
      const response = await GET(URL_PALLET.PALLET_USAGE, { shipping_instruction : params.id})
      setPalletData(response.data.results);
      setSelectedPallet(response.data.results.filter(usage => usage.is_loaded).map(usage => usage.id))
    } catch (error) {
      setErrorMessages(error.errorMessages);
    }
  }

  const handleSubmit = async() => {
    try {
      const data = { shipping_instruction : params.id, action : SI_TRANSACTION_ACTION.LOAD, pallets : selectedPallet, note : ''}
      await POST(URL_WEIGHT.SI_TRANSACTION, data  )
      navigate(PATH.LOAD_PRODUCT)
    } catch (error) {
      setErrorMessages(error.errorMessages);
    }
  }

  const handleSelectPallet = async (isSelected, val) => {
    try {
      setLoading(true)
      if(isSelected === true){
        await PATCH(`${URL_PALLET.PALLET_USAGE_SET_LOAD}${val}/`, {
          is_loaded: true
        })
        setSelectedPallet([...selectedPallet, val])
      } else {
        await PATCH(`${URL_PALLET.PALLET_USAGE_SET_LOAD}${val}/`, {
          is_loaded: false
        })
        setSelectedPallet(selectedPallet.filter(item => item !== val))
      }
    } catch(error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false)
    }
  }

  const checkedSelected = (item) => {
    return(selectedPallet.includes(item.id))
  }

  const isEnable = () => loadData && [EXPORT_STATUS.LOADING, EXPORT_STATUS.CHECKING].includes(loadData.status)

  React.useEffect(() => {
    fetchLoadData();
  },[])
  
    return(
    <>
    <HeaderScreen onClick={() => navigate(PATH.LOAD_PRODUCT)} title={`SI : ${loadData ? loadData.code : ''}`} />
    <Spin spinning={loading}>
      <Content
        className="main-content"
        style={{ 
        margin: "16px 24px 24px 24px", 
        minHeight: 280,
        background: colorBgContainer }}>
      
        {errorMessages && 
          <Alert
            message={errorMessages}
            type="error"
            showIcon
            style={{marginBottom: "12px", textAlign: "left"}}
          />
        }
        <Descriptions>
          <Descriptions.Item label="สินค้า">{loadData && loadData.product_name}</Descriptions.Item>
          <Descriptions.Item label="คุณภาพ (Grade)">{loadData && loadData.product_grade_name}</Descriptions.Item>
          <Descriptions.Item label="ลูกค้า">{loadData && loadData.customer_name}</Descriptions.Item>
          <Descriptions.Item label="ทะเบียนรถ">{loadData && loadData.vehicle_license_plate}</Descriptions.Item>
          <Descriptions.Item label="หมายเหตุ">{loadData && loadData.note}</Descriptions.Item>
        </Descriptions>

      <Divider style={{marginBottom: 0}}>พาเลท</Divider>
      <List
        style={{marginBottom: 16}}
        dataSource={palletData}
        renderItem={(item) => {
          return (
            <List.Item key={item.id}>
              <Checkbox 
                disabled={!isEnable()}
                style={{margin: 4}}
                value={item.id} 
                onChange={(e, val) => handleSelectPallet(e, item.id)} 
                defaultChecked={checkedSelected(item)}>{`${item.pallet_code}`}
              </Checkbox>
            </List.Item>
          )
        }}/>
        
        { loadData && EXPORT_STATUS.LOADING === loadData.status &&
          < Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>
            <Button block shape='rounded' color='primary'
              disabled={palletData.length !== selectedPallet.length}
              style={{ width: 300 }}
              onClick={() => handleSubmit()}>
              โหลดสินค้าเสร็จสิ้น
            </Button>
          </Space>
        }
      </Content>
      </Spin>
    </>
    )
}