import React from "react";
import dayjs from "dayjs";
import TSTable from "../components/common/TSTable";
import { theme, Space, Button, Row, Col } from 'antd'
import { PlusCircleOutlined, FilePdfOutlined, FileExcelOutlined } from '@ant-design/icons';
import ColorButton from '../components/common/ColorButton';
import { SECONDARY_COLOR } from '../constants/color';
import VehicleModal from "../components/vehicle/VehicleModal";
import { URL_WEIGHT } from "../constants/urls";
import { Content } from "antd/es/layout/layout";
import { DATETIME_FORMAT, HUMAN_DATETIME_FORMAT } from "../constants/strings";

export default function VehicleView() {
  const [target, setTarget] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false)
  const tableRef = React.useRef(null);

  const {
    token: { colorPrimary }
  } = theme.useToken();

  const columns = [
    {
      title: "ประเภทยานพาหนะ",
      dataIndex: "vehicle_type_name",
      sorter: true,
    },
    {
      title: "ทะเบียนรถ",
      dataIndex: "license_plate_number",
      searcher: true,
    },
    {
      title: "ทะเบียนจังหวัด",
      dataIndex: "license_plate_province_name",
    },
  ]

  const refreshData = async() => {
    if (tableRef && tableRef.current) {
      tableRef.current.fetch();
    }
  }

  const handleCloseModal = () => {
    if (target)
      setTarget(null)

    if (openModal)
      setOpenModal(false);
  }

  return(
    <Content>
      <div>
        <ColorButton 
          type={"primary"} 
          override={SECONDARY_COLOR}
          icon={<PlusCircleOutlined />} 
          style={{float: "right", background: colorPrimary, marginBottom: 16}}
          onClick={() => setOpenModal(true)}>
            ยานพาหนะ
        </ColorButton>
      </div>

      <Space style={{ float: 'left' }}>
        <Row gutter={8}>
          <Col>
            <Button
              icon={<FilePdfOutlined />}
              onClick={async () => {
                await tableRef.current.exportPDF({
                  title: "รายการยานพาหนะ",
                  subtitle: `ดึงข้อมูล ณ​ วันที่ ${dayjs().format(HUMAN_DATETIME_FORMAT)}`,
                  landscape: true
                })
              }}>
              PDF
            </Button>
          </Col>
          <Col>
            <Button
              icon={<FileExcelOutlined />}
              onClick={async () => {
                await tableRef.current.exportXlsx({
                  title: "รายการยานพาหนะ",
                  subtitle: `ดึงข้อมูล ณ​ วันที่ ${dayjs().format(HUMAN_DATETIME_FORMAT)}`,
                  filename: `รายการยานพาหนะ - ${dayjs().format(DATETIME_FORMAT)}.xlsx`
                })
              }}>
              Excel
            </Button>
          </Col>
        </Row>
      </Space>

      <TSTable 
        ref={tableRef}
        columns={columns}
        url={URL_WEIGHT.VEHICLE}
        rowKey={"id"}
        size={"small"}
        pageSize={10}
        onRow={(record, rowIndex) => ({
          onClick: _ => setTarget(record)
        })}/>
      
      <VehicleModal
        open={openModal || (target ? true : false)}
        target={target}
        onClose={handleCloseModal}
        onUpdate={() => {
            handleCloseModal();
            refreshData();
        }}
      />

    </Content>
  )
}


