import React from "react";
import { Layout, theme, Form, Row, Col, Spin, Modal, Alert, Descriptions } from "antd";
import HeaderScreen from "../components/common/HeaderScreen";
import { useUserContext } from '../hooks/UserSettingProvider';
import { useNavigate, useParams } from "react-router-dom";
import { SI_TRANSACTION_ACTION } from "../constants/strings";
import { PRIMARY_COLOR, PRIMARY_LIGHT_COLOR } from "../constants/color";
import { PlusCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { PATH } from "../routes/CustomRoutes";
import { Content } from "antd/es/layout/layout";
import ColorButton from "../components/common/ColorButton";
import TSTable from "../components/common/TSTable";
import { EXPORT_STATUS, HUMAN_DATETIME_FORMAT, TICKET_ACTION, USER_GROUP } from "../constants/strings";
import moment from "moment";
import SIDetailModal from "../components/menuExport/SIDetailModal";
import ExportPalletModal from "../components/menuExport/ExportPalletModal";
import { currencyFormat } from "../frameworks/Util";
import { GET, POST } from "../frameworks/HttpClient";
import { URL_PALLET, URL_REPORT, URL_WEIGHT } from "../constants/urls";

export default function ExportProductScreen() {
  const navigate = useNavigate();
  const params = useParams();
  const { user } = useUserContext();
  const [form] = Form.useForm();
  const [target, setTarget] = React.useState(null);
  const [loading, setLoading] = React.useState(null);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [editSITarget, setEditSITarget] = React.useState(null);
  const [siData, setSiData] = React.useState(null);
  const tableRef = React.useRef(null);

  const goal_weight = Form.useWatch('goal_weight', form)
  const sum_product_weight = Form.useWatch('sum_product_weight', form)

  const {
		token: { colorBgContainer, colorInfo, colorWarning },
	} = theme.useToken();

  const hasPermission = (target) => user.groups.find(group => group === target) ? true : false

  const fetchSIData = async() => {
    setLoading(true)
    try {
      const response  = await GET(`${URL_WEIGHT.SHIPPING_INSTRUCTION}${params.id}`)
      form.resetFields()
      form.setFieldsValue(response.data)
      setSiData(response.data)
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setLoading(false)
    }
  }

  const refreshData = () => {
    if (tableRef.current) {
      tableRef.current.fetch();
    }
  }

  const columns = [
    {
      title: "วันที่ - เวลา",
      dataIndex: "pallet_created",
      render: (value) => moment(value).format(HUMAN_DATETIME_FORMAT),
      width: 200,
      sorter: true,
    },
    {
      title: "ชื่อพาเลท",
      dataIndex: "pallet_code",
      sorter: true,
    },
    {
      title: "สินค้า",
      dataIndex: "product_name",
    },
    {
      title: "คุณภาพ(Grade)",
      dataIndex: "product_grade_name",
    },
    {
      title: "น้ำหนักรวม",
      dataIndex: "net_weight",
      render : (value) => {
        return (currencyFormat(value))
      },
    },
    {
      title: "น้ำหนักพาเลท",
      dataIndex: "pallet_weight",
      render : (value) => {
        return (currencyFormat(value))
      },
    },
    {
      title: "น้ำหนักสุทธิ",
      dataIndex: "product_weight",
      render : (value) => {
        return (currencyFormat(value))
      },
    }]

    const handleReadyTransection = async() => {
      try {
        setLoading(true)
        const action = siData.status === EXPORT_STATUS.CHECKING ? SI_TRANSACTION_ACTION.SUBMIT : SI_TRANSACTION_ACTION.READY
        await POST(URL_WEIGHT.SI_TRANSACTION, { shipping_instruction: params.id, action: action, note: ''})
        navigate(PATH.EXPORT_PRODUCTS)
      } catch (error) {
        setErrorMessages(error.errorMessages);
      } finally {
        setLoading(false)
      }
    }

    const submitAction = async () => {
      if (!siData) {
        setErrorMessages("ไม่พบข้อมูลตั๋วชั่ง!");
        return;
      }
      
      setErrorMessages(null);
      setLoading(true);
      try {
        await POST(
          URL_WEIGHT.TICKET_TRANSACTION, 
          {ticket: siData.ticket, action: TICKET_ACTION.SUBMIT});
        navigate(-1);
      } catch (error) {
        setErrorMessages(error.errorMessages);
      } finally {
        setLoading(false);
      }
    }

    // To print
    const handlePrint = async () => {
      setLoading(true)
    try {
      // Fetch the PDF file
      const response = await GET(`${URL_REPORT.SUBTICKET_DELIVERY_REPORT}${siData.id}/`, {}, false, true);      
      await window.open(URL.createObjectURL(response.data), '_blank');

    } catch (error) {
      console.error('Error fetching PDF:', error);
    } finally {
      setLoading(false)
    }
    }

    React.useEffect(() => {
      fetchSIData();
    },[])

  return(
    <Layout style={{ minHeight: "100vh", background: PRIMARY_LIGHT_COLOR }}>
      <HeaderScreen onClick={() => navigate(PATH.EXPORT_PRODUCTS)} title={`SI - ${siData && siData.code}`}/>

      <Spin spinning={loading}>
        <Content
          className="main-content"
          style={{ 
          margin: "0px 24px 24px 24px", 
          padding: "16px 24px",
          textAlign: "left",  
          minHeight: 280,
          background: colorBgContainer }}>
          <Descriptions bordered size='small' title="รายละเอียดใบส่งสินค้า" extra={
            hasPermission(USER_GROUP.SALE) && siData &&
              (siData.status === EXPORT_STATUS.PROGRESSING || siData.status === EXPORT_STATUS.CHECKING) &&
              <ColorButton
                type="primary"
                style={{ float: "right", width: "100%" }}
                onClick={() => setEditSITarget(siData)}
                override={colorWarning}>
                แก้ไข SI
              </ColorButton>
          }>
            <Descriptions.Item label="สินค้า">{siData && siData.product_name}</Descriptions.Item>
            <Descriptions.Item label="ลูกค้า">{siData && siData.customer_name}</Descriptions.Item>
            <Descriptions.Item label="ทะเบียนรถ">{siData && siData.vehicle_license_plate}</Descriptions.Item>
            <Descriptions.Item label="น้ำหนักขายโดยประมาณ">{siData && currencyFormat(siData.goal_weight)}</Descriptions.Item>
            <Descriptions.Item label="น้ำหนักปัจจุบัน">{siData && currencyFormat(siData.snapshot_sum_product_weight)}</Descriptions.Item>
            <Descriptions.Item label="หมายเหตุ">{siData && siData.note}</Descriptions.Item>
          </Descriptions>

          {errorMessages &&
            <Alert
              message={errorMessages}
              type="error"
              showIcon
              style={{ textAlign: "left" }}
            />
          }

          <Col md={24} sm={24} xs={24} style={{ marginBottom: 8}}>
            <Row gutter={16} style={{float : "right", marginTop: '10px'}}>
          {hasPermission(USER_GROUP.PALLET_SCALE) && siData && 
          (siData.status === EXPORT_STATUS.PROGRESSING || siData.status === EXPORT_STATUS.CHECKING) && 
          (siData.number_of_pallet > 0) &&
              <Col md={12} sm={24} xs={24}>
                <ColorButton
                  type="primary"
                  style={{ float: "right", width: 180 }}
                  override={siData.status === EXPORT_STATUS.CHECKING ? colorWarning : colorInfo}
                  onClick={() => {
                    Modal.confirm({
                      title: 'เตรียมสินค้าเสร็จสิ้น',
                      content: `ยืนยันเตรียมสินค้าเสร็จสิ้นใช่หรือไม่ ?`,
                      okText: 'ยืนยัน',
                      okButtonProps: {style: { background : colorInfo}},
                      maskClosable: true,
                      onOk: () => {
                        handleReadyTransection().then(() => {
                          if(siData.status === EXPORT_STATUS.CHECKING){
                            submitAction();
                          }
                        })
                      },
                      cancelText: 'ยกเลิก',
                    });
                  }}>
                  {siData.status === EXPORT_STATUS.CHECKING ? "แก้ไขข้อมูลเสร็จสิ้น" : "เตรียมสินค้าเสร็จสิ้น" }
                </ColorButton>
              </Col>
          }

            {hasPermission(USER_GROUP.PALLET_SCALE) && siData && 
            (siData.status === EXPORT_STATUS.PROGRESSING || siData.status === EXPORT_STATUS.CHECKING) && 
              <Col md={siData.number_of_pallet > 0 ? 12 : 24} sm={24} xs={24}>
                <ColorButton
                  type="primary"
                  style={{ float: "right", width: 180}}
                  onClick={() => setTarget('add')}
                  icon={<PlusCircleOutlined />} 
                  override={(PRIMARY_COLOR)}>
                  เพิ่มพาเลท
                </ColorButton>
              </Col>
            }
            </Row>
          </Col>

          {siData && (siData.status === EXPORT_STATUS.PROGRESSING || siData.status === EXPORT_STATUS.CHECKING) &&
            siData.goal_weight > 0 && siData.snapshot_sum_product_weight > 0 && (siData.goal_weight != siData.snapshot_sum_product_weight) &&
            <Row>
              <Col md={24} sm={24} xs={24} style={{ marginBottom: 8 }}>
                <Alert showIcon type="warning" 
                  message={`น้ำหนักโดยประมาณและน้ำหนักสุทธิไม่เท่ากัน (${currencyFormat(siData.goal_weight)} และ ${currencyFormat(siData.snapshot_sum_product_weight)}) นน.ต่างกัน ${currencyFormat(siData.goal_weight - siData.snapshot_sum_product_weight)} กก.`} />
              </Col>
            </Row>
          }

          <TSTable
            ref={tableRef}
            columns={columns}
            rowKey={"id"}
            url={URL_PALLET.PALLET_USAGE_SNAPSHOT}
            params={{ shipping_instruction: params.id }}
            size={"small"}
            pageSize={10}
            onRow={(record, rowIndex) => ({
              onClick: _ => setTarget(record)
            })}/>

          <div className="button-bottom-page">
            <Row gutter={16} style= {{ height: '100%', marginButtom : 16}}>
              {siData && siData.status === EXPORT_STATUS.EXPORTED &&
                <ColorButton
                  loading={loading}           
                  type={"primary"} 
                  override={colorInfo}
                  style={{ width: '100px', marginLeft : 10}}
                  icon={null}
                  onClick={() => {
                    Modal.confirm({
                      title: 'พิมพ์ตั๋วชั่ง',
                      icon: <InfoCircleOutlined style={{ color : colorInfo}}/>,
                      content: `คุณต้องการพิมพ์ตั๋วชั่งใช่หรือไม่ ?`,
                      okText: 'ยืนยัน',
                      okButtonProps: {style: { background : colorInfo}},
                      maskClosable: true,
                      onOk: () => {
                        handlePrint()
                      },
                      cancelText: 'ยกเลิก',
                    });
                  }}>
                    {"พิมพ์"}
                </ColorButton>
              }
            </Row>
          </div>
        </Content>

        <SIDetailModal
          open={editSITarget ? true : false}
          target={editSITarget}
          onClose={() => setEditSITarget(null)}
          onUpdate={() => {
            setEditSITarget(null);
            fetchSIData();
          }}
          onDeleted={() => {
            setEditSITarget(null);
            navigate(-1);
          }}/>

        <ExportPalletModal
          open={target ? true : false }
          onClose={() => setTarget(null)}
          si={params.id}
          status={siData && siData.status}
          target={target}
          product={siData && siData.product}
          disabled={!hasPermission(USER_GROUP.PALLET_SCALE)}
          onUpdate={() => {
            setTarget(null)
            refreshData();
            fetchSIData();
          }}/>    
      </Spin>
    </Layout>
  )
}
