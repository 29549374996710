import React from 'react'
import propTypes from "prop-types";
import { Modal } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { PATH } from '../../routes/CustomRoutes';
import { URL_AUTH } from '../../constants/urls';
import { GET } from '../../frameworks/HttpClient';
import { useUserContext } from '../../hooks/UserSettingProvider';
import Cookies from 'js-cookie';

export default function LogoutModal(props) {
	const navigate = useNavigate();
	const user = useUserContext();

	const handleLogout = async () => {
		try {
			await GET(URL_AUTH.SIGN_OUT)
		} catch (error) {}
		finally {
			user.onLogout();
			navigate(PATH.LOGIN);
			props.onClose();
			Cookies.remove('id');
		}
	}

	return (
		<Modal 
			title={"ออกจากระบบ"}
			okText={"ตกลง"}
			cancelText={"ยกเลิก"}
			centered={false}
			icon={<LogoutOutlined />}
			open={props.open}
			onOk={handleLogout}
			onCancel={props.onClose}>
				
				<p>{"คุณต้องการที่จะออกจากระบบใช่หรือไม่ ?"}</p>
		</Modal>
	)
}

LogoutModal.defaultProps = {
	open: false,
	onClose: () => {},
	isLiff: false,
}

LogoutModal.propTypes = {
	open: propTypes.bool,
	onClose: propTypes.func,
	isLiff: propTypes.bool,
}