import React from "react";
import { Tabs } from 'antd';
import PalletManegerView from "../components/pallet/PalletManegerView";
import PalletRemovedView from "../components/pallet/PalletRemovedView";

export default function PalletListView() {

  const items = [
    {
      key: '1',
      label: 'พาเลทที่ใช้งาน',
      children: <PalletManegerView/>,
    },
    {
      key: '2',
      label: 'พาเลทที่ยกเลิก',
      children: <PalletRemovedView/>,
    },
  ];
    
  return(
    <Tabs defaultActiveKey="1" items={items}/>
  )
}