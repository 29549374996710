import React from "react"
import propTypes from "prop-types";
import { List } from 'antd-mobile';
import {Input, Form, Row, Col } from 'antd';
import { useNavigate } from "react-router-dom";
import { PATH } from "../routes/CustomRoutes";
import { GET } from "../frameworks/HttpClient";
import { URL_PALLET } from "../constants/urls";
import { TICKET_DIRECTION } from "../constants/strings";
import { currencyFormat } from "../frameworks/Util";
import TextArea from "antd/es/input/TextArea";

const NotifyDetailPalletScaleView = React.forwardRef((props, ref) => {
  const navigate = useNavigate  ();
  const [form] = Form.useForm();
  const [pallets, setPallets] = React.useState([]);

  const fetchPalletList = async (ticket, direction) => {
    if (!ticket) {
      props.onError("ไม่พบข้อมูลตั๋วชั่ง!");
      return;
    }

    // Prepare data
    let params = {page_size: 999}
    if (direction === TICKET_DIRECTION.IMPORT) 
      params["ticket"] = ticket.id
    
    if (direction === TICKET_DIRECTION.EXPORT)
      params["shipping_instruction"] = ticket.si_ref

    try {
      const response = await GET(URL_PALLET.PALLET_USAGE, params);
      setPallets(response.data.results)
    } catch (error) {
      props.onError(error.errorMessages)
    }
  }

  React.useEffect(() => {
    if (props.data) {
      fetchPalletList(props.data, props.direction)
      form.setFieldsValue(props.data)
      form.setFieldValue(
        "total_pallet_weight", 
        (props.data.subticket && props.data.subticket.total_pallet_weight) ? props.data.subticket.total_pallet_weight : 0)
    } else {
      form.resetFields();
      setPallets([]);
    }
  }, [props.data]);

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off">

      <Row gutter={24}>
        <Col md={8} sm={24} xs={24}>
          <Form.Item  
            name={"product_name"}
            label="สินค้า">
            <Input autoComplete="off" disabled/>
          </Form.Item>
        </Col>

        {(props.direction === TICKET_DIRECTION.IMPORT) && (
          <Col md={8} sm={24} xs={24}>
            <Form.Item  
              name={"total_pallet_weight"}
              label="น้ำหนักพาเลทรวม (Kgs)">
                <Input autoComplete="off" disabled />
            </Form.Item>
          </Col>
        )}
    
        <Col md={8} sm={24} xs={24}>
          <Form.Item 
            label="น้ำหนักสุทธิ (Kgs)">
              <Input 
                disabled
                autoComplete="off" 
                value={currencyFormat(props.data ? props.data.sum_product_weight : 0)} />
          </Form.Item>
        </Col>

        <Col md={24} sm={24} xs={24}>
          <Form.Item
            label="หมายเหตุ">
            <TextArea 
              disabled
              autoComplete="off" 
              value={props.data.subticket ? props.data.subticket.note : "-"} />
          </Form.Item>
        </Col>

        <Col md={24} sm={24} xs={24}>
          <List header='พาเลท'>
            {pallets.map((item) => (
              <List.Item
                key={item.id}
                description={`นน.แผงปกติ: ${item.base_pallet_weight}`}
                extra={`นน.สุทธิ ${currencyFormat(item.product_weight)}`}
                onClick={() => navigate(`${PATH.NOTIFY_DETAIL_PALLERT}/${item.id}`)}>
                  {item.pallet_code}
              </List.Item>
            ))}
          </List>
        </Col>
      </Row>
    </Form>
  )
});

NotifyDetailPalletScaleView.defaultProps = {
  data: null,
  direction: "",
  onError: (msg) => {},
}

NotifyDetailPalletScaleView.prototype = {
  data: propTypes.object,
  direction: propTypes.string,
  onError: propTypes.func,
}

export default React.memo(NotifyDetailPalletScaleView);