import React from "react";
import propTypes from "prop-types";
import { Input, Form, Row, Col } from 'antd';
import CustomScaleCard from '../components/common/CustomScaleCard';
import { currencyFormat } from "../frameworks/Util";
import TextArea from "antd/es/input/TextArea";

const NotifyDetailTruckScaleView = React.forwardRef((props, ref) => {
  const [form] = Form.useForm();

  React.useEffect(() => {
    props.data ? form.setFieldsValue(props.data) : form.resetFields()
  }, [props.data]);

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off">
      <Row gutter={24}>
        <Col md={8} sm={24} xs={24}>
          <Form.Item
            name={"product_name"}
            label="สินค้า">
            <Input autoComplete="off" disabled />
          </Form.Item>
        </Col>
        <Col md={8} sm={24} xs={24}>
          <Form.Item
            name={"vehicle_license_plate"}
            label="ทะเบียนรถ">
              <Input autoComplete="off" disabled />
          </Form.Item>
        </Col>
        <Col md={8} sm={24} xs={24}>
          <Form.Item
            label="น้ำหนักสุทธิ">
            <Input 
              disabled
              autoComplete="off" 
              value={currencyFormat(props.data ? props.data.net_weight : 0)} />
          </Form.Item>
        </Col>
        <Col md={24} sm={24} xs={24}>
          <Form.Item
            label="หมายเหตุ">
            <TextArea 
              disabled
              autoComplete="off" 
              value={props.data.note} />
          </Form.Item>
        </Col>
        <Col md={12} sm={24} xs={24}>
          <Form.Item>
            <CustomScaleCard
              title="ชั่งเข้า"
              showExtraButton={false}
              showImages={true}
              initialData={(props.data && props.data.latest_in_weighting_ref) 
                ? props.data.latest_in_weighting_ref : null}/>
          </Form.Item>
        </Col>
        <Col md={12} sm={24} xs={24}>
          <Form.Item>
            <CustomScaleCard
              title="ชั่งออก"
              showExtraButton={false}
              showImages={true}
              initialData={(props.data && props.data.latest_out_weighting_ref) 
                ? props.data.latest_out_weighting_ref : null}/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
});

NotifyDetailTruckScaleView.defaultProps = {
  data: null,
}

NotifyDetailTruckScaleView.prototype = {
  data: propTypes.object,
}

export default React.memo(NotifyDetailTruckScaleView);
