import React from "react";
import Cookies from 'js-cookie'
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { PATH } from "./CustomRoutes";
import { COOKIE_AUTH } from "../constants/cookies";

export default function PrivateOutlet() {
  const prevLocation = useLocation();

  return Cookies.get(COOKIE_AUTH.TOKEN) != null ? 
  <Outlet /> : 
  <Navigate 
    state={{ from: prevLocation }}
    to={PATH.LOGIN} />;
}